import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

interface EventData {
  date_start: string;
  date_end: string;
  title: string;
  desc: string;
}

const initialData: EventData = {
  date_start: "2024-10-20",
  date_end: "2024-10-22",
  title: "Kuliah Praktikum",
  desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
};

interface EditCalenderProps {
  onClose: () => void;
}

const EditDataCalendar: React.FC<EditCalenderProps> = ({ onClose }) => {
  const [eventData, setEventData] = useState<EventData>(initialData);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  // Konversi string tanggal ke objek Date
  const parseDate = (dateStr: string) => {
    return new Date(dateStr);
  };

  const handleDateRangeSelect = (
    start: Date | undefined,
    end: Date | undefined
  ) => {
    const newStartDate = start
      ? start.toISOString().split("T")[0]
      : eventData.date_start;
    const newEndDate = end
      ? end.toISOString().split("T")[0]
      : eventData.date_end;

    setEventData({
      ...eventData,
      date_start: newStartDate,
      date_end: newEndDate,
    });
  };

  const handleSave = () => {
    console.log("Data disimpan:", eventData);
    alert("Data berhasil disimpan!");
  };

  const handleCancel = () => {
    setIsCalendarOpen(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  return (
    <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Tambah Data Kalender</h2>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Judul Acara:
        </label>
        <input
          type="text"
          name="title"
          value={eventData.title}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded-md text-sm focus:outline-none focus:border-orange-500"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Deskripsi Acara:
        </label>
        <textarea
          name="desc"
          value={eventData.desc}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border rounded-md text-sm focus:outline-none focus:border-orange-500"
          rows={4}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Tanggal Mulai dan Akhir:
        </label>
        <input
          type="text"
          value={`${eventData.date_start} - ${eventData.date_end}`}
          readOnly
          className="w-full px-3 py-2 border rounded-md text-sm focus:outline-none focus:border-orange-500 cursor-pointer"
          onClick={() => setIsCalendarOpen(true)}
        />
      </div>

      <div className="flex justify-between mt-6" onClick={onClose}>
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded-md"
          onClick={handleCancel}
        >
          Batal
        </button>
        <button
          className="px-4 py-2 bg-orange-500 text-white rounded-md"
          onClick={handleSave}
        >
          Simpan
        </button>
      </div>
    </div>
  );
};

export default EditDataCalendar;
