import React from "react";
import { IconClassroom, IconStudent } from "../../../../../Assets/Icons";
import DiagramKinerjaSiswa from "./diagramKinerjaSiswa";
import DiagramAbsensiTeacher from "./diagramAbsensiTeacher";
import GrafictStudent from "./grafictStudent";

const DetailKinerjaTeacher = () => {
  return (
    <section className="p-4 font-inter">
      <div className="flex gap-4">
        <div className="w-[150px] h-[150px]">
          <img
            src="https://picsum.photos/200/300?random=4"
            alt=""
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-bold">Prof. Dr. Ida Sari</h2>
          <p className="text-gray-600">Guru Mata Pelajaran</p>
          <p className="text-gray-800">SMA 1 Pedes Karawang</p>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mt-[30px] justify-center">
        <div className="shadow-md py-2 rounded-lg gap-4 border border-slate-300">
          <p className="text-[40px] font-roboto font-extrabold text-center">
            34
          </p>
          <div className="flex gap-2 justify-center items-center">
            {IconStudent("24", "24", "gray")}
            <p className="text-[20px] text-gray-500 font-extrabold">Siswa</p>
          </div>
        </div>{" "}
        <div className="shadow-md py-2 rounded-lg gap-4 border border-slate-300">
          <p className="text-[40px] font-roboto font-extrabold text-center">
            34
          </p>
          <div className="flex gap-2 justify-center items-center">
            {IconClassroom("24", "24", "gray")}
            <p className="text-[20px] text-gray-500 font-extrabold">Kelas</p>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
        <div>
          <GrafictStudent />
        </div>
        <div>
          <DiagramKinerjaSiswa />
        </div>
      </div>
      <div className="mt-4">
        <DiagramAbsensiTeacher />
      </div>
    </section>
  );
};

export default DetailKinerjaTeacher;
