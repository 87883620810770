import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconAdd } from "../../../../Assets/Icons";

// Define types for the schedule data
type Pelajaran = {
  id: number;
  hari: string;
  nama: string;
  jam: string;
  pengajar: string;
};

type Jadwal = {
  [key: string]: Pelajaran[];
};

type KelasData = {
  grade: string;
  nama: string[];
  jadwal: Jadwal;
};

type JurusanData = {
  jurusan: string;
  kelas: KelasData[];
};

// Sample data for subjects and schedules
const mataPelajaranData: JurusanData[] = [
  {
    jurusan: "MIPA",
    kelas: [
      {
        grade: "10",
        nama: ["Kelas 10 MIPA 1", "Kelas 10 MIPA 2"],
        jadwal: {
          "Kelas 10 MIPA 1": [
            {
              id: 1,
              hari: "senin",
              nama: "Matematika",
              jam: "08:00 - 09:30",
              pengajar: "Budi Santoso",
            },
            {
              id: 2,
              hari: "senin",
              nama: "Fisika",
              jam: "09:30 - 11:00",
              pengajar: "Ali Rahman",
            },
            {
              id: 3,
              hari: "selasa",
              nama: "Kimia",
              jam: "08:00 - 09:30",
              pengajar: "Nina Ratnasari",
            },
            {
              id: 3,
              hari: "rabu",
              nama: "Kimia",
              jam: "08:00 - 09:30",
              pengajar: "Nina Ratnasari",
            },
            {
              id: 3,
              hari: "rabu",
              nama: "Kimia",
              jam: "08:00 - 09:30",
              pengajar: "Nina Ratnasari",
            },
          ],
          "Kelas 10 MIPA 2": [
            {
              id: 4,
              hari: "senin",
              nama: "Bahasa Indonesia",
              jam: "09:30 - 11:00",
              pengajar: "Siti Aisyah",
            },
            {
              id: 5,
              hari: "senin",
              nama: "Biologi",
              jam: "08:00 - 09:30",
              pengajar: "Nur Rahman",
            },
          ],
        },
      },
      {
        grade: "11",
        nama: ["Kelas 11 MIPA 1", "Kelas 11 MIPA 2"],
        jadwal: {
          "Kelas 11 MIPA 1": [
            {
              id: 1,
              hari: "senin",
              nama: "Matematika",
              jam: "08:00 - 09:30",
              pengajar: "Budi Santoso",
            },
            {
              id: 2,
              hari: "senin",
              nama: "Fisika",
              jam: "09:30 - 11:00",
              pengajar: "Ali Rahman",
            },
            {
              id: 3,
              hari: "selasa",
              nama: "Kimia",
              jam: "08:00 - 09:30",
              pengajar: "Nina Ratnasari",
            },
          ],
          "Kelas 11 MIPA 2": [
            {
              id: 4,
              hari: "senin",
              nama: "Bahasa Indonesia",
              jam: "09:30 - 11:00",
              pengajar: "Siti Aisyah",
            },
            {
              id: 5,
              hari: "senin",
              nama: "Biologi",
              jam: "08:00 - 09:30",
              pengajar: "Nur Rahman",
            },
          ],
        },
      },
      {
        grade: "12",
        nama: ["Kelas 12 MIPA 1", "Kelas 12 MIPA 2"],
        jadwal: {
          "Kelas 12 MIPA 1": [
            {
              id: 1,
              hari: "senin",
              nama: "Matematika",
              jam: "08:00 - 09:30",
              pengajar: "Budi Santoso",
            },
            {
              id: 2,
              hari: "senin",
              nama: "Fisika",
              jam: "09:30 - 11:00",
              pengajar: "Ali Rahman",
            },
            {
              id: 3,
              hari: "selasa",
              nama: "Kimia",
              jam: "08:00 - 09:30",
              pengajar: "Nina Ratnasari",
            },
          ],
          "Kelas 12 MIPA 2": [
            {
              id: 4,
              hari: "senin",
              nama: "Bahasa Indonesia",
              jam: "09:30 - 11:00",
              pengajar: "Siti Aisyah",
            },
            {
              id: 5,
              hari: "senin",
              nama: "Biologi",
              jam: "08:00 - 09:30",
              pengajar: "Nur Rahman",
            },
          ],
        },
      },
    ],
  },
  {
    jurusan: "IPS",
    kelas: [
      {
        grade: "10",
        nama: ["Kelas 10 IPS 1", "Kelas 10 IPS 2"],
        jadwal: {
          "Kelas 10 IPS 1": [
            {
              id: 6,
              hari: "senin",
              nama: "Geografi",
              jam: "08:00 - 09:30",
              pengajar: "Dina Susanti",
            },
            {
              id: 7,
              hari: "selasa",
              nama: "Ekonomi",
              jam: "09:30 - 11:00",
              pengajar: "Ali Rahman",
            },
          ],
          "Kelas 10 IPS 2": [
            {
              id: 8,
              hari: "senin",
              nama: "Sosiologi",
              jam: "08:00 - 09:30",
              pengajar: "Nina Ratnasari",
            },
            {
              id: 9,
              hari: "selasa",
              nama: "Bahasa Indonesia",
              jam: "09:30 - 11:00",
              pengajar: "Siti Aisyah",
            },
          ],
        },
      },
    ],
  },
];

const AkademikPelajaran: React.FC = () => {
  const [selectedJurusan, setSelectedJurusan] = useState<string>("MIPA");
  const [selectedGrade, setSelectedGrade] = useState<string>("10");
  const [selectedNamaKelas, setSelectedNamaKelas] =
    useState<string>("Kelas 10 MIPA 1");

  const handleJurusanChange = (jurusan: string) => {
    setSelectedJurusan(jurusan);
    const firstGrade = mataPelajaranData.find(
      (data) => data.jurusan === jurusan
    )?.kelas[0].grade;
    setSelectedGrade(firstGrade || "");
    const firstNamaKelas = mataPelajaranData.find(
      (data) => data.jurusan === jurusan
    )?.kelas[0].nama[0];
    setSelectedNamaKelas(firstNamaKelas || "");
  };

  const handleGradeChange = (grade: string) => {
    setSelectedGrade(grade);
    const firstNamaKelas = mataPelajaranData
      .find((data) => data.jurusan === selectedJurusan)
      ?.kelas.find((kelas) => kelas.grade === grade)?.nama[0];
    setSelectedNamaKelas(firstNamaKelas || "");
  };

  const handleNamaKelasChange = (namaKelas: string) => {
    setSelectedNamaKelas(namaKelas);
  };

  const filteredKelas =
    mataPelajaranData.find((data) => data.jurusan === selectedJurusan)?.kelas ||
    [];
  const selectedKelasData = filteredKelas.find(
    (kelas) => kelas.grade === selectedGrade
  );
  const selectedJadwalData = selectedKelasData?.jadwal[selectedNamaKelas];

  // Mengelompokkan jadwal berdasarkan hari
  const jadwalByHari: { [key: string]: Pelajaran[] } = {};
  selectedJadwalData?.forEach((pelajaran) => {
    if (!jadwalByHari[pelajaran.hari]) {
      jadwalByHari[pelajaran.hari] = [];
    }
    jadwalByHari[pelajaran.hari].push(pelajaran);
  });

  return (
    <div className="w-full max-w-7xl mx-auto p-6 bg-gray-50 rounded-lg shadow-md">
      <h1 className="text-4xl font-bold text-center text-blue-600 mb-10">
        Jadwal Pelajaran
      </h1>

      <div className="flex justify-between">
        {/* Jurusan Selection */}
        <div className="flex lg:gap-4  mb-6">
          {mataPelajaranData.map((data) => (
            <button
              key={data.jurusan}
              className={`mx-2 py-1 lg:w-[200px] w-[80px] text-center rounded-md text-[16px] font-semibold ${
                selectedJurusan === data.jurusan
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800"
              } hover:bg-blue-500 hover:text-white transition duration-300`}
              onClick={() => handleJurusanChange(data.jurusan)}
            >
              {data.jurusan}
            </button>
          ))}
        </div>
        <Link to={"tambah"}>
          <button className="bg-blue-600 flex gap-2 text-center items-center rounded-lg lg:rounded-full text-[16px] text-white lg:py-1 p-2 lg:p-0 lg:w-[150px] ">
            {IconAdd("24", "24", "white")}
            <p className="hidden lg:block">Tambah</p>
          </button>
        </Link>
      </div>

      {/* Grade Selection */}
      {filteredKelas.length > 0 && (
        <div className="flex gap-4 mb-6 w-auto">
          {filteredKelas.map((kelas, index) => (
            <button
              key={index}
              className={`mx-2 py-1 w-[100px] text-center rounded-md text-[16px] font-semibold ${
                selectedGrade === kelas.grade
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800"
              } hover:bg-blue-500 hover:text-white transition duration-300`}
              onClick={() => handleGradeChange(kelas.grade)}
            >
              {kelas.grade}
            </button>
          ))}
        </div>
      )}

      {/* Nama Kelas Selection */}
      {selectedKelasData && (
        <div className="overflow-x-auto">
          <div className="flex gap-4 mb-8 w-[700px]">
            {selectedKelasData.nama.map((namaKelas, index) => (
              <button
                key={index}
                className={`mx-2 py-1 px-6 rounded-md text-[16px] font-semibold ${
                  selectedNamaKelas === namaKelas
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-800"
                } hover:bg-blue-500 hover:text-white transition duration-300`}
                onClick={() => handleNamaKelasChange(namaKelas)}
              >
                {namaKelas}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Jadwal Pelajaran */}
      {Object.keys(jadwalByHari).length > 0 && (
        <div className="mt-10">
          <h2 className="text-3xl font-semibold text-center text-gray-700 mb-8">
            Jadwal {selectedNamaKelas}
          </h2>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 overflow-x-auto">
            {Object.keys(jadwalByHari).map((hari, index) => (
              <div key={index} className="mb-4 lg:w-full w-[500px]">
                <h3 className="text-2xl font-bold text-blue-600 mb-2 capitalize ">
                  {hari}
                </h3>
                <table className="min-w-full bg-white border border-gray-300 rounded-lg h-auto">
                  <thead>
                    <tr>
                      <th className="py-3 px-4 bg-blue-600 text-white text-left">
                        No
                      </th>
                      <th className="py-3 px-4 bg-blue-600 text-white text-left">
                        Nama Pelajaran
                      </th>
                      <th className="py-3 px-4 bg-blue-600 text-white text-left">
                        Jam
                      </th>
                      <th className="py-3 px-4 bg-blue-600 text-white text-left">
                        Pengajar
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jadwalByHari[hari].map((pelajaran, index) => (
                      <tr key={index} className="border-t">
                        <td className="py-3 px-4">{pelajaran.id}</td>
                        <td className="py-3 px-4">{pelajaran.nama}</td>
                        <td className="py-3 px-4">{pelajaran.jam}</td>
                        <td className="py-3 px-4">{pelajaran.pengajar}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AkademikPelajaran;
