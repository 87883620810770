import React from "react";
import { Link } from "react-router-dom";

// Interface for Teacher
interface Teacher {
  id: number;
  name: string;
  subject: string;
  score: number;
  image: string;
}

// Static data for Teachers
const teachers: Teacher[] = [
  {
    id: 1,
    name: "Ahmad Syafi'i",
    subject: "Mathematics",
    score: 95,
    image: "https://picsum.photos/200/300?random=6",
  },
  {
    id: 2,
    name: "Bella Sari",
    subject: "English",
    score: 90,
    image: "https://picsum.photos/200/300?random=7",
  },
  {
    id: 3,
    name: "Charlie Anggara",
    subject: "Physics",
    score: 92,
    image: "https://picsum.photos/200/300?random=8",
  },
  {
    id: 4,
    name: "Diana Putri",
    subject: "Chemistry",
    score: 88,
    image: "https://picsum.photos/200/300?random=9",
  },
  {
    id: 5,
    name: "Eko Supriyadi",
    subject: "Biology",
    score: 87,
    image: "https://picsum.photos/200/300?random=10",
  },
];

const TeacherSmart: React.FC = () => {
  // Sort teachers based on their score in descending order
  const sortedTeachers = teachers.sort((a, b) => b.score - a.score);

  return (
    <div className="h-auto p-2 shadow-lg">
      <h1 className="text-[18px] font-bold mb-1">Guru Berprestasi</h1>
      <div className="grid grid-cols-1 gap-2 overflow-y-auto h-[300px]">
        {sortedTeachers.map((teacher, index) => (
          <Link
            to={"/sekolah/kinerja/guru/detail"}
            key={index}
            className="bg-white flex justify-between items-center shadow-lg rounded-lg p-2"
          >
            <div className="flex gap-2 items-center">
              <p className="text-[14px] font-semibold">{index + 1}.</p>
              <div className="w-[50px] h-[50px]">
                <img
                  src={teacher.image}
                  alt={teacher.name}
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
              <div>
                <h2 className="text-[14px] font-semibold">{teacher.name}</h2>
                <p className="text-[12px] text-gray-600">{teacher.subject}</p>
              </div>
            </div>

            <p className="text-center text-[20px] font-extrabold text-blue-600">
              {teacher.score}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TeacherSmart;
