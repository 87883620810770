import React from "react";
import { IconDelete, IconEdit } from "../../../Assets/Icons";
import { Link } from "react-router-dom";

const initialExams = [
  {
    code: "E001",
    subject: "Matematika",
    className: "10A",
    date: new Date("2024-09-20"),
    startTime: "09:00",
    endTime: "11:00",
  },
];

const EssaySemester: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-center">
        Daftar Ujian
      </h1>
      <div className="flex justify-end mb-2">
        <Link to={"tambah"}>
          <button className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            Tambah Ujian
          </button>
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full lg:w-full w-[700px] bg-white border border-gray-200 rounded-lg shadow-md">
          <thead className="bg-gray-100 text-center text-gray-600 border-b border-gray-200">
            <tr>
              <th className="py-3 px-4 text-sm sm:text-base">Kode Ujian</th>
              <th className="py-3 px-4 text-sm sm:text-base">Nama Mapel</th>
              <th className="py-3 px-4 text-sm sm:text-base">Kelas</th>
              <th className="py-3 px-4 text-sm sm:text-base">Tanggal</th>
              <th className="py-3 px-4 text-sm sm:text-base">Jam Mulai</th>
              <th className="py-3 px-4 text-sm sm:text-base">Aksi</th>
            </tr>
          </thead>
          <tbody>
            {initialExams.map((exam, index) => (
              <tr key={index} className="border-b text-center border-gray-200">
                <td className="py-3 px-4 text-sm">{exam.code}</td>
                <td className="py-3 px-4 text-sm">{exam.subject}</td>
                <td className="py-3 px-4 text-sm">{exam.className}</td>
                <td className="py-3 px-4 text-sm">
                  {exam.date ? exam.date.toLocaleDateString("id-ID") : ""}
                </td>
                <td className="py-3 px-4 text-sm">
                  {exam.startTime} s/d {exam.endTime}
                </td>
                <td className="py-3 px-4 flex justify-center flex-row gap-2 sm:gap-4">
                  <button>{IconEdit("20", "20", "blue")}</button>
                  <button>{IconDelete("20", "20", "blue")}</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EssaySemester;
