import React, { useState } from "react";
import { IconExport, IconRefress, IconSearch } from "../../../../Assets/Icons";
import ExportData from "../../../../Components/exportData";
import { Link } from "react-router-dom";

// Data Guru
const teachersData = [
  {
    no: 1,
    nip: "123456789",
    nama: "Ahmad Fauzi",
    jumlahKelas: 3,
    jumlahSiswa: 120,
    waliKelas: "X IPA 1",
    pembinaEkstrakulikuler: "Basket",
    staffPerpustakaan: "-",
    guruMapel: "Matematika",
  },
  {
    no: 2,
    nip: "987654321",
    nama: "Siti Aisyah",
    jumlahKelas: 2,
    jumlahSiswa: 80,
    waliKelas: "-",
    pembinaEkstrakulikuler: "-",
    staffPerpustakaan: "Staff Utama",
    guruMapel: "Bahasa Indonesia",
  },
  {
    no: 3,
    nip: "543216789",
    nama: "Budi Santoso",
    jumlahKelas: 4,
    jumlahSiswa: 150,
    waliKelas: "XI IPS 2",
    pembinaEkstrakulikuler: "-",
    staffPerpustakaan: "-",
    guruMapel: "Sejarah",
  },
  // Tambahkan data guru lainnya di sini
];

const KinerjaTeacher: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTeachers, setFilteredTeachers] = useState(teachersData);
  const [showExport, setShowExport] = useState(false);

  const handleExport = () => {
    setShowExport(!showExport);
  };

  const handleCloseExport = () => {
    setShowExport(false);
  };
  // Function to handle search
  const handleSearch = () => {
    if (searchTerm) {
      const results = teachersData.filter((teacher) => {
        return (
          teacher.nama.toLowerCase().includes(searchTerm.toLowerCase()) ||
          teacher.nip.includes(searchTerm) ||
          teacher.guruMapel.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredTeachers(results);
    } else {
      setFilteredTeachers(teachersData); // Reset to original data if input is empty
    }
  };

  // Function to refresh data to initial state
  const handleRefresh = () => {
    setSearchTerm("");
    setFilteredTeachers(teachersData);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-2 ">Kinerja Guru</h1>
      <div className="flex  gap-2 justify-end mb-2 items-center">
        <input
          type="text"
          className="lg:w-[300px] w-full py-1 px-4 border border-slate-300 outline-none rounded-full"
          placeholder="Cari Guru..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch} disabled={!searchTerm}>
          <IconSearch />
        </button>
        <button
          onClick={handleRefresh}
          className="bg-red-500 flex gap-2 text-white lg:py-2 py-1 lg:px-4 px-1 lg:rounded-full rounded-md"
        >
          {IconRefress("24", "24", "white")}
          <p className="hidden lg:block">Refresh</p>
        </button>

        <button
          onClick={handleExport}
          className="bg-blue-700 lg:rounded-full rounded-md lg:py-2 py-1 lg:px-4 px-1 text-white flex gap-2"
        >
          {IconExport("24", "24", "white")}{" "}
          <p className="hidden lg:block">Export</p>
        </button>
      </div>
      <div></div>
      {showExport && (
        <div className="absolute right-4">
          <ExportData onClose={handleCloseExport} />
        </div>
      )}
      <div className="overflow-x-auto ">
        <div className="grid grid-cols-7 lg:w-full w-[900px] items-center rounded-lg bg-gray-800 text-white text-center mb-4">
          <div className="flex ">
            <div className="p-2 w-12">No</div>
            <div className="p-2 w-full">NIP</div>
          </div>
          <div className="p-2">Nama</div>
          <div className="p-2">Guru Mapel</div>
          <div>
            <p>Jumlah</p>
            <div className="grid grid-cols-2">
              <div className="p-2">Kelas</div>
              <div className="p-2"> Siswa</div>
            </div>
          </div>
          <div className="p-2">Wali Kelas</div>
          <div className="p-2">Pembina Ekstrakulikuler</div>
          <div className="p-2">Staff Perpustakaan</div>
        </div>
        {filteredTeachers.map((teacher, index) => (
          <Link
            to={"detail"}
            key={index}
            className={`grid grid-cols-7 items-center lg:w-full w-[900px] border-b hover:bg-gray-100 text-center ${
              index % 2 === 0 ? "bg-white" : "bg-gray-50"
            }`}
          >
            <div className="flex">
              <div className="p-2 w-12">{teacher.no}</div>
              <div className="p-2 w-full">{teacher.nip}</div>
            </div>
            <div className="p-2">{teacher.nama}</div>
            <div className="p-2">{teacher.guruMapel}</div>
            <div className="grid grid-cols-2">
              <div className="p-2">{teacher.jumlahKelas}</div>
              <div className="p-2">{teacher.jumlahSiswa}</div>
            </div>
            <div className="p-2">{teacher.waliKelas || "-"}</div>
            <div className="p-2">{teacher.pembinaEkstrakulikuler || "-"}</div>
            <div className="p-2">{teacher.staffPerpustakaan || "-"}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default KinerjaTeacher;
