import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Dataset {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  fill: boolean;
}

const DiagramKinerjaGuru: React.FC = () => {
  const data = {
    labels: ["Kelas 1", "Kelas 2", "Kelas 3", "Kelas 4", "Kelas 5"],
    datasets: [
      {
        label: "Nilai Rata-Rata Per Kelas",
        data: [80, 75, 90, 85, 95],
        borderColor: "rgba(16, 185, 129, 1)",
        backgroundColor: "rgba(16, 185, 129, 0.3)",
        fill: true,
      },
    ] as Dataset[],
  };

  // Opsi untuk diagram dengan Chart.js
  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top", // Posisi legend di atas
      },
      title: {
        display: true,
        text: "Nilai Rata-Rata Per Kelas",
        font: {
          size: 22,
          weight: "bold",
        },
        color: "#333", // Warna teks judul
      },
    },
    animation: {
      duration: 1000,
      easing: "easeInOutBounce",
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100, // Batas maksimum nilai 100
        ticks: {
          stepSize: 10, // Langkah nilai di sumbu y
          color: "#555", // Warna teks skala
        },
        grid: {
          color: "#e5e5e5", // Warna grid
        },
      },
      x: {
        ticks: {
          color: "#555", // Warna teks skala
        },
        grid: {
          color: "#e5e5e5", // Warna grid
        },
      },
    },
  };

  return (
    <div className="overflow-x-auto">
      <div className="lg:w-full w-[700px] p-4  border border-slate-300 mx-auto rounded-lg shadow-md">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default DiagramKinerjaGuru;
