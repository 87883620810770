import React from "react";
import ArtikelMore from "./artikelMore";

const artikelData = {
  image: "https://picsum.photos/200/300?random=6", // Ganti dengan URL gambar artikel asli
  title: "Manfaat Olahraga Pagi untuk Kesehatan",
  releaseDate: "September 7, 2024",
  description: [
    {
      paragraf:
        "Olahraga pagi adalah salah satu kebiasaan sehat yang memberikan banyak manfaat untuk tubuh, termasuk meningkatkan metabolisme, menjaga kesehatan jantung, dan mengurangi stres. Dengan rutin melakukan olahraga di pagi hari, Anda bisa mendapatkan energi yang lebih tinggi untuk menjalani aktivitas harian. Tidak hanya itu, olahraga pagi juga dapat meningkatkan mood dan konsentrasi Anda.",
    },
    {
      paragraf:
        "Olahraga pagi adalah salah satu kebiasaan sehat yang memberikan banyak manfaat untuk tubuh, termasuk meningkatkan metabolisme, menjaga kesehatan jantung, dan mengurangi stres. Dengan rutin melakukan olahraga di pagi hari, Anda bisa mendapatkan energi yang lebih tinggi untuk menjalani aktivitas harian. Tidak hanya itu, olahraga pagi juga dapat meningkatkan mood dan konsentrasi Anda.",
    },
  ],
};

const DetailArtikel = () => {
  return (
    <section className="lg:flex lg:absolute lg:w-[1000px]">
      <div className="max-w-4xl font-inter mx-auto p-6 bg-white shadow-lg rounded-lg">
        <div className="w-full lg:h-[300px] h-[200px]">
          <img
            src={artikelData.image}
            alt="Artikel"
            className="w-full h-full rounded-t-lg object-cover"
          />
        </div>
        <div className="mt-4">
          <p className="text-gray-600 text-sm">{artikelData.releaseDate}</p>
          <h1 className="lg:text-2xl text-[18px] font-semibold mt-2 mb-4">
            {artikelData.title}
          </h1>
          {artikelData.description.map((item, index) => (
            <p
              key={index}
              className="text-gray-700 text-[12px] leading-relaxed indent-4 lg:indent-8 mb-2"
            >
              {item.paragraf}
            </p>
          ))}
        </div>
      </div>
      <div>
        <ArtikelMore />
      </div>
    </section>
  );
};

export default DetailArtikel;
