import React, { useState } from "react";
import { IconImportData, IconInput } from "../../../../Assets/Icons";
import AddTeacherExcel from "./AddTeacherExcel";

interface Student {
  id: number;
  nis: string;
  fullName: string;
  class: string;
  teacher: string;
  parent: string;
  address: string;
}

const AddTeacher: React.FC = () => {
  const [newStudent, setNewStudent] = useState<Student>({
    id: Math.floor(Math.random() * 1000), // Generate ID secara acak
    nis: "",
    fullName: "",
    class: "",
    teacher: "",
    parent: "",
    address: "",
  });

  const [isManualInput, setIsManualInput] = useState(true); // State untuk mengatur input manual atau import excel

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  const toggleInputMethod = (method: "manual" | "excel") => {
    setIsManualInput(method === "manual");
  };

  return (
    <div className="max-w-[1440px] font-inter mx-auto p-6 bg-white rounded-xl shadow-xl">
      {/* perbaiki lebar nya agar tidak melebihi batas page */}

      <div className="flex lg:flex-row flex-col bg-white border border-slate-300 rounded-lg py-4 shadow-md px-6 justify-between max-w-full">
        <h2 className="text-[24px] lg:text-3xl font-extrabold text-center text-blue-600">
          Tambah Data Guru
        </h2>
        <div className="flex gap-4 mt-3 lg:mt-0">
          <button
            onClick={() => toggleInputMethod("manual")}
            className={`${
              isManualInput
                ? "bg-blue-500 text-white"
                : "border border-blue-500 text-gray-500"
            } flex gap-2 rounded-lg font-roboto py-2 px-4`}
          >
            {IconInput("24", "24", isManualInput ? "white" : "gray")}{" "}
            <p>Manual</p>
          </button>
          <button
            onClick={() => toggleInputMethod("excel")}
            className={`${
              !isManualInput
                ? "bg-blue-500 text-white"
                : "border border-blue-500 text-gray-500"
            } flex gap-2 rounded-lg font-roboto py-2 px-4`}
          >
            {IconImportData("24", "24", !isManualInput ? "white" : "gray")}{" "}
            <p>Import Excel</p>
          </button>
        </div>
      </div>

      <div className="overflow-y-auto h-[400px] mt-2 p-6">
        {isManualInput ? (
          <form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-gray-700 mb-2">NIP/NIK</label>
                <input
                  type="text"
                  name="nis"
                  value={newStudent.nis}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan NIS"
                />
              </div>

              <div>
                <label className="block text-gray-700 mb-2">Nama Lengkap</label>
                <input
                  type="text"
                  name="fullName"
                  value={newStudent.fullName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Nama Lengkap"
                />
              </div>

              <div>
                <label className="block text-gray-700 mb-2">No Telp</label>
                <input
                  type="text"
                  name="class"
                  value={newStudent.class}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Kelas"
                />
              </div>

              <div>
                <label className="block text-gray-700 mb-2">
                  Mata Pelajaran
                </label>
                <input
                  type="text"
                  name="teacher"
                  value={newStudent.teacher}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Nama Wali Kelas"
                />
              </div>

              <div>
                <label className="block text-gray-700 mb-2">Wali Kelas</label>
                <input
                  type="text"
                  name="parent"
                  value={newStudent.parent}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Nama Wali Murid"
                />
              </div>

              <div className="md:col-span-2">
                <label className="block text-gray-700 mb-2">Alamat</label>
                <textarea
                  name="address"
                  value={newStudent.address}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Alamat"
                  rows={4}
                />
              </div>

              <div className="md:col-span-2 flex justify-end gap-4 items-center mt-6">
                <button
                  type="submit"
                  className="flex items-center gap-2 bg-blue-500 text-white px-8 py-3 rounded-md hover:bg-blue-600 focus:bg-blue-700 transition-all transform hover:scale-105 focus:outline-none"
                >
                  Simpan
                </button>
                <button
                  type="button"
                  className="flex items-center gap-2 bg-red-500 text-white px-8 py-3 rounded-md hover:bg-red-600 focus:bg-red-700 transition-all transform hover:scale-105 focus:outline-none"
                >
                  Batal
                </button>
              </div>
            </div>
          </form>
        ) : (
          <AddTeacherExcel />
        )}
      </div>
    </div>
  );
};

export default AddTeacher;
