import React, { useState } from "react";
import DiagramAbsensi from "./diagramAbsensi";
import DiagramMapel from "./diagramMape";

const DetailKinerjaStudent: React.FC = () => {
  // Data statis
  const studentData = {
    name: "Andi Surya",
    class: "10 IPA 1",
    semester1: {
      attendance: {
        total: 30,
        present: 28,
        absent: 2,
      },
      dailyScores: [85, 90, 78, 92, 88],
      midSemesterScore: 87,
      finalScore: 90,
      caseNotes: "Perlu perhatian khusus dalam pelajaran matematika.",
    },
    semester2: {
      attendance: {
        total: 25,
        present: 22,
        absent: 3,
      },
      dailyScores: [88, 85, 91, 86, 84],
      midSemesterScore: 89,
      finalScore: 92,
      caseNotes: "Meningkat dalam pelajaran matematika.",
    },
    homeroomTeacher: "Bapak Joko",
  };

  // State untuk menyimpan semester aktif
  const [activeSemester, setActiveSemester] = useState(1);

  // Mengambil data semester berdasarkan state aktif
  const semesterData =
    activeSemester === 1 ? studentData.semester1 : studentData.semester2;

  return (
    <div className="w-full">
      <div className="lg:mx-6 flex flex-col gap-4 p-4 shadow-lg rounded-lg border border-gray-200">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Detail Kinerja Siswa
        </h1>

        <div className="bg-white p-5 rounded-lg shadow-md mb-6 flex flex-col gap-4">
          <h2 className="text-2xl font-semibold mb-2">Informasi Siswa</h2>
          <div className="flex lg:flex-row flex-col lg:items-center gap-6">
            <div className="w-[150px] h-[150px]">
              <img
                src="https://picsum.photos/200/300?random=5"
                alt=""
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="flex gap-2 font-roboto">
              <div className="font-semibold w-[100px]">
                <p>Nama</p>
                <p>NIS</p>
                <p>Kelas</p>
                <p>Jurusan</p>
              </div>
              <div>
                <p>: {studentData.name}</p>
                <p>: 22323232232</p>
                <p>: {studentData.class}</p>
                <p>: Matematika Ilmu Pengetahuan Alam</p>
              </div>
            </div>
          </div>

          {/* Tombol untuk memilih semester */}
          <div className="overflow-x-auto">
            <div className="flex  gap-4 lg:w-full w-[650px] ">
              {[1, 2, 3, 4, 5, 6].map((semester) => (
                <button
                  key={semester}
                  className={`px-4 py-2 rounded-lg text-[12px]  font-semibold ${
                    activeSemester === semester
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-gray-800"
                  }`}
                  onClick={() => setActiveSemester(semester)}
                >
                  Semester {semester}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Data persemester */}
        <div>
          <div>
            <DiagramMapel />
          </div>
          <div>
            <DiagramAbsensi />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="bg-white p-5 rounded-lg shadow-md text-center">
              <p className="text-[40px] font-bold ">
                {semesterData.midSemesterScore}
              </p>
              <h2 className="text-xl font-semibold mb-2">
                Nilai Tengah Semester
              </h2>
            </div>

            <div className="bg-white p-5 rounded-lg shadow-md text-center">
              <p className="text-[40px] font-roboto font-bold ">
                {semesterData.finalScore}
              </p>
              <h2 className="text-xl font-semibold mb-2">
                Nilai Akhir Semester
              </h2>
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-8 grid-cols-1 my-4">
            <div className="bg-white p-5 rounded-lg shadow-md">
              <h1 className="text-[20px] font-semibold">Prestasi Siswa</h1>
              {[1, 2, 3, 4].map((i) => (
                <div
                  key={i}
                  className="flex text-[16px] font-inter gap-2 w-full"
                >
                  <p>{i + 0}.</p>
                  <p>Juara 2 olimviade Matmatika</p>
                </div>
              ))}
            </div>
            <div className="bg-white p-5 rounded-lg shadow-md">
              <h1 className="text-[20px] font-semibold">Extrakulikuler</h1>
              {[1, 2, 3, 4].map((i) => (
                <div
                  key={i}
                  className="flex text-[16px] font-inter gap-2 w-full"
                >
                  <p>{i + 0}.</p>
                  <p>Juara 2 olimviade Matmatika</p>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white p-5 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-2">Wali Kelas</h2>
            <p>{studentData.homeroomTeacher}</p>
            <div className="bg-white p-5 rounded-lg shadow-md mb-6">
              <h2 className="text-xl font-semibold mb-2">Catatan Kasus</h2>
              <p>{semesterData.caseNotes}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailKinerjaStudent;
