import React from "react";

// Data nilai rata-rata
const dataMapel = [
  { nama: "Matematika", rataRata: 85 },
  { nama: "Bahasa Indonesia", rataRata: 78 },
  { nama: "Bahasa Inggris", rataRata: 82 },
  { nama: "IPA", rataRata: 90 },
  { nama: "IPS", rataRata: 75 },
  { nama: "Penjaskes", rataRata: 88 },
];

const DiagramMapel = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold lg:text-center mb-6">
        Rata-rata Nilai Kelas
      </h1>

      <div className="flex flex-col space-y-4">
        {dataMapel.map((mapel, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4"
          >
            {/* Nama Mapel */}
            <div className="w-full md:w-1/4 text-gray-700 font-semibold text-left">
              {mapel.nama}
            </div>

            {/* Bar Diagram */}
            <div className="w-full md:w-3/4 bg-gray-200 rounded-lg overflow-hidden">
              <div
                className="bg-blue-500 lg:h-8 h-4 rounded-lg text-white flex items-center justify-end pr-2"
                style={{ width: `${mapel.rataRata}%` }}
              >
                {mapel.rataRata}%
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiagramMapel;
