import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Registrasi komponen-komponen ChartJS yang diperlukan
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GrafictPerpustakaan: React.FC = () => {
  // Data peminjaman buku untuk diagram bar
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ], // Label bulan
    datasets: [
      {
        label: "Peminjaman Buku",
        data: [20, 35, 40, 50, 60, 80, 90, 100, 120, 130, 140, 150], // Data peminjaman buku setiap bulan
        backgroundColor: "rgba(75, 192, 192, 0.8)", // Warna balok
        borderColor: "rgba(75, 192, 192, 1)", // Warna border balok
        borderWidth: 2,
        borderRadius: 5, // Membuat balok lebih smooth
      },
    ],
  };

  // Opsi konfigurasi chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const, // Posisi legend di atas chart
        labels: {
          font: {
            size: 14, // Ukuran font legend
          },
          color: "#333", // Warna legend
        },
      },
      title: {
        display: true,
        text: "Peningkatan Peminjaman Buku dalam Satu Tahun", // Judul diagram
        font: {
          size: 20, // Ukuran font judul
        },
        color: "#333", // Warna judul
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Memastikan skala y mulai dari 0
        title: {
          display: true,
          text: "Jumlah Peminjaman", // Label untuk sumbu Y
          font: {
            size: 16, // Ukuran font label Y
          },
          color: "#666", // Warna label Y
        },
        ticks: {
          color: "#666", // Warna angka di sumbu Y
          font: {
            size: 14, // Ukuran font angka Y
          },
        },
      },
      x: {
        title: {
          display: true,
          text: "Bulan", // Label untuk sumbu X
          font: {
            size: 16, // Ukuran font label X
          },
          color: "#666", // Warna label X
        },
        ticks: {
          color: "#666", // Warna angka di sumbu X
          font: {
            size: 14, // Ukuran font angka X
          },
        },
      },
    },
  };

  return (
    <div className=" bg-white lg:p-4 p-2 rounded-lg shadow-lg mt-10">
      <h2 className="text-[20px] lg:text-2xl font-semibold  text-gray-700 mb-4">
        Grafik Peminjaman Buku
      </h2>
      <div className="overflow-x-auto">
        <div className="lg:p-4 lg:w-full w-[600px] bg-gray-100 rounded-lg shadow-md">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default GrafictPerpustakaan;
