import React from "react";
import DiagramMapel from "./diagramMapel";

const DashboardWaliKelas = () => {
  return (
    <section>
      <div>
        <DiagramMapel />
      </div>
    </section>
  );
};

export default DashboardWaliKelas;
