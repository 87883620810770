import { id } from "date-fns/locale";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconDelete } from "../../../../Assets/Icons";
import "./index.css";

interface Exam {
  code: string;
  subject: string;
  className: string;
  date: Date | null;
  startTime: string;
  endTime: string;
  file: File | null;
}

const initialExam: Exam = {
  code: "",
  subject: "",
  className: "",
  date: null,
  startTime: "",
  endTime: "",
  file: null,
};

const AddEssay = () => {
  const [exams, setExams] = useState<Exam[]>([initialExam]);

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const updatedExams = [...exams];
    updatedExams[index] = {
      ...updatedExams[index],
      [name]: value,
    };
    setExams(updatedExams);
  };

  const handleDateChange = (index: number, date: Date | null) => {
    const updatedExams = [...exams];
    updatedExams[index] = {
      ...updatedExams[index],
      date,
    };
    setExams(updatedExams);
  };

  const handleFileChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files ? e.target.files[0] : null;
    const updatedExams = [...exams];
    updatedExams[index] = {
      ...updatedExams[index],
      file,
    };
    setExams(updatedExams);
  };

  // Fungsi untuk menambah input baru
  const handleAddNewInput = () => {
    setExams([...exams, initialExam]);
  };

  // Fungsi untuk menghapus input berdasarkan indeks
  const handleRemoveInput = (index: number) => {
    if (exams.length > 1) {
      const updatedExams = exams.filter((_, i) => i !== index); // Hapus data berdasarkan indeks
      setExams(updatedExams);
    }
  };

  return (
    <div className="w-full font-inter flex justify-center items-center  ">
      <div className="w-full p-4 lg:p-8 bg-white shadow-lg rounded-lg mx-6">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
          Tambah Ujian Baru
        </h2>

        {exams.map((exam, index) => (
          <div key={index} className="mb-6 space-y-6 border-b pb-6 relative">
            <div className="bg-blue-700 shadow-sm  px-4 py-2 rounded-lg flex justify-between items-center">
              <h3 className="text-lg font-semibold text-white">
                Soal Ujian {index + 1}
              </h3>
              {/* Tombol Hapus muncul jika jumlah ujian lebih dari satu */}
              {exams.length > 1 && (
                <button
                  className=" flex gap-1 items-center text-[14px] font-bold bg-white text-red-600 px-6 py-1 rounded-full text-xs hover:bg-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                  onClick={() => handleRemoveInput(index)}
                >
                  {IconDelete("24", "24", "red")} Hapus
                </button>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Kode Ujian
                </label>
                <input
                  type="text"
                  className="form-input w-full outline-none px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  name="code"
                  value={exam.code}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder="Masukkan kode ujian"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Nama Mapel
                </label>
                <input
                  type="text"
                  className="form-input outline-none w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  name="subject"
                  value={exam.subject}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder="Masukkan nama mapel"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Kelas
                </label>
                <input
                  type="text"
                  className="form-input  outline-none w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  name="className"
                  value={exam.className}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder="Masukkan kelas"
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Tanggal
                </label>
                <DatePicker
                  selected={exam.date}
                  onChange={(date) => handleDateChange(index, date)}
                  className="form-input  outline-none w-full px-4 py-2  border border-gray-500 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  dateFormat="dd MMMM yyyy"
                  placeholderText="Pilih tanggal"
                  locale={id}
                />
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  Jam
                </label>
                <div className="flex gap-2">
                  <input
                    type="time"
                    className="form-input w-full outline-none px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    name="startTime"
                    value={exam.startTime}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <p className="self-center text-gray-500">s/d</p>
                  <input
                    type="time"
                    className="form-input outline-none w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    name="endTime"
                    value={exam.endTime}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 mb-2">
                  File Ujian
                </label>
                <input
                  type="file"
                  className="form-input w-full px-4 outline-none py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  onChange={(e) => handleFileChange(index, e)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-between mt-6 text-[12px] lg:[14px]">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-green-600 "
            onClick={handleAddNewInput}
          >
            Tambah Data
          </button>
          <div className="flex lg:gap-4 gap-2">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600 ">
              Kembali
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEssay;
