import React, { useState } from "react";

interface Attendance {
  no: number;
  nis: string;
  name: string;
  status: "Hadir" | "Tidak Hadir";
  summary: string;
  date: string;
}

interface Student {
  name: string;
  hasAttended: boolean;
}

const initialAttendanceHistory: Attendance[] = [
  {
    no: 1,
    nis: "123456",
    name: "Ali",
    status: "Hadir",
    summary: "Mempelajari konsep dasar aljabar dan persamaan linear.",
    date: "2024-08-28",
  },
  {
    no: 2,
    nis: "123457",
    name: "Budi",
    status: "Tidak Hadir",
    summary: "Tidak hadir karena sakit. Materi: Tenses dalam Bahasa Inggris.",
    date: "2024-08-27",
  },
];

const students: Student[] = [
  { name: "Ali", hasAttended: false },
  { name: "Budi", hasAttended: true },
  { name: "Cici", hasAttended: false },
];

const AbsensiTeacher: React.FC = () => {
  const [attendanceHistory, setAttendanceHistory] = useState<Attendance[]>(
    initialAttendanceHistory
  );
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<"Hadir" | "Tidak Hadir">(
    "Hadir"
  );
  const [summary, setSummary] = useState<string>("");

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(event.target.value as "Hadir" | "Tidak Hadir");
  };

  const handleSummaryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSummary(event.target.value);
  };

  const handleEditClick = (index: number) => {
    const entry = attendanceHistory[index];
    setEditingIndex(index);
    setSelectedStatus(entry.status);
    setSummary(entry.summary);
  };

  const handleSaveClick = () => {
    if (editingIndex !== null) {
      const updatedAttendanceHistory = [...attendanceHistory];
      updatedAttendanceHistory[editingIndex] = {
        ...updatedAttendanceHistory[editingIndex],
        status: selectedStatus,
        summary,
      };
      setAttendanceHistory(updatedAttendanceHistory);
      setEditingIndex(null);
      setSelectedStatus("Hadir");
      setSummary("");
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const absentStudents = students.filter((student) => !student.hasAttended);

  return (
    <div className="min-h-screen absolute font-inter bg-gray-100 p-4">
      <div className="max-w-6xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <h1 className="text-4xl font-bold text-gray-800 mb-8 ">Absensi Guru</h1>

        {/* Daftar Siswa Tidak Hadir */}
        <div className="mb-8 p-6 bg-yellow-50 border border-yellow-200 rounded-lg shadow">
          <h2 className="text-2xl  font-semibold text-yellow-600 mb-4">
            Daftar Siswa Tidak Hadir
          </h2>
          {absentStudents.length > 0 ? (
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {absentStudents.map((student, index) => (
                <li
                  key={index}
                  className="py-3 px-4 rounded-lg bg-red-50 text-red-600 shadow"
                >
                  {student.name}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-700">Semua siswa hadir hari ini.</p>
          )}
        </div>

        {/* Form Absensi */}
        <div className="mb-8 p-6  bg-blue-50 border border-blue-200 rounded-lg shadow">
          <h2 className="text-2xl  font-semibold text-blue-600 mb-4">
            Absensi Hari Ini
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="flex items-center space-x-4 mb-4">
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="attendance"
                  value="Hadir"
                  checked={selectedStatus === "Hadir"}
                  onChange={handleStatusChange}
                  className="form-radio text-blue-600"
                />
                <span className="text-gray-700">Hadir</span>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="attendance"
                  value="Tidak Hadir"
                  checked={selectedStatus === "Tidak Hadir"}
                  onChange={handleStatusChange}
                  className="form-radio text-red-600"
                />
                <span className="text-gray-700">Tidak Hadir</span>
              </label>
            </div>
            <div className="mb-4">
              <textarea
                rows={4}
                value={summary}
                onChange={handleSummaryChange}
                placeholder="Keterangan rangkuman pembelajaran"
                className="w-full p-3 border border-gray-300 rounded-lg resize-none focus:border-blue-500 focus:ring focus:ring-blue-200"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full md:w-auto px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
            >
              Kirim Absensi
            </button>
          </form>
        </div>

        {/* Riwayat Kehadiran */}
        <div className="overflow-x-auto">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Riwayat Kehadiran
          </h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  No
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  NIS
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nama Lengkap
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Keterangan
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Kehadiran
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {attendanceHistory.map((entry, index) => (
                <tr key={entry.no}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {entry.no}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {entry.nis}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {entry.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {entry.summary}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`inline-flex px-2 text-xs font-semibold rounded-full ${
                        entry.status === "Hadir"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {entry.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => handleEditClick(index)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Edit Form */}
        {editingIndex !== null && (
          <div className="mt-8 p-6 bg-green-50 border border-green-200 rounded-lg shadow">
            <h2 className="text-2xl font-semibold text-green-600 mb-4">
              Edit Kehadiran
            </h2>
            <div className="mb-4">
              <p className="text-lg font-medium text-gray-800 mb-2">
                {attendanceHistory[editingIndex].name}
              </p>
              <form>
                <div className="flex items-center space-x-4 mb-4">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="edit-attendance"
                      value="Hadir"
                      checked={selectedStatus === "Hadir"}
                      onChange={handleStatusChange}
                      className="form-radio text-green-600"
                    />
                    <span className="text-gray-700">Hadir</span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="edit-attendance"
                      value="Tidak Hadir"
                      checked={selectedStatus === "Tidak Hadir"}
                      onChange={handleStatusChange}
                      className="form-radio text-red-600"
                    />
                    <span className="text-gray-700">Tidak Hadir</span>
                  </label>
                </div>
                <div className="mb-4">
                  <textarea
                    rows={4}
                    value={summary}
                    onChange={handleSummaryChange}
                    placeholder="Keterangan rangkuman pembelajaran"
                    className="w-full p-3 border border-gray-300 rounded-lg resize-none focus:border-green-500 focus:ring focus:ring-green-200"
                  ></textarea>
                </div>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  className="px-6 py-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition-colors duration-300"
                >
                  Simpan Perubahan
                </button>
                <button
                  type="button"
                  onClick={() => setEditingIndex(null)}
                  className="px-6 py-3 bg-gray-600 text-white rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 transition-colors duration-300 ml-4"
                >
                  Batal
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AbsensiTeacher;
