import React, { useState } from "react";
import { IconAdd, IconSearch } from "../../../../Assets/Icons";
import { IconSearch2 } from "../../../ListBooks/iconBook";
import { Link } from "react-router-dom";

interface Book {
  id: number;
  title: string;
  author: string;
  year: number;
  genre: string;
  image: string; // Menambahkan properti image
}

const books: Book[] = [
  {
    id: 1,
    title: "Buku Sains Terapan",
    author: "Dr. Ahmad",
    year: 2022,
    genre: "Sains",
    image: "https://picsum.photos/200/300?random=1",
  },
  {
    id: 2,
    title: "Sejarah Dunia",
    author: "Ibu Budi",
    year: 2021,
    genre: "Sejarah",
    image: "https://picsum.photos/200/300?random=2",
  },
  {
    id: 3,
    title: "Fiksi Fantasi: Petualangan",
    author: "Siti Aisyah",
    year: 2023,
    genre: "Fiksi",
    image: "https://picsum.photos/200/300?random=3",
  },
  {
    id: 4,
    title: "Kepemimpinan Modern",
    author: "John Doe",
    year: 2020,
    genre: "Non-Fiksi",
    image: "https://picsum.photos/200/300?random=4",
  },
  {
    id: 5,
    title: "Biografi Sang Legenda",
    author: "Jane Doe",
    year: 2019,
    genre: "Biografi",
    image: "https://picsum.photos/200/300?random=5",
  },
  {
    id: 6,
    title: "Matematika Dasar",
    author: "Dr. Lisa",
    year: 2020,
    genre: "Pendidikan",
    image: "https://picsum.photos/200/300?random=6",
  },
  {
    id: 7,
    title: "Kecerdasan Buatan",
    author: "Dr. Samuel",
    year: 2023,
    genre: "Teknologi",
    image: "https://picsum.photos/200/300?random=7",
  },
  {
    id: 8,
    title: "Novel Romantis",
    author: "Rina",
    year: 2021,
    genre: "Fiksi",
    image: "https://picsum.photos/200/300?random=8",
  },
  {
    id: 9,
    title: "Makanan Sehat",
    author: "Chef Anya",
    year: 2022,
    genre: "Kuliner",
    image: "https://picsum.photos/200/300?random=9",
  },
  {
    id: 10,
    title: "Investasi untuk Pemula",
    author: "Ali Rahman",
    year: 2023,
    genre: "Ekonomi",
    image: "https://picsum.photos/200/300?random=10",
  },
];

const DaftarBuku: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState(""); // State untuk menyimpan nilai input pencarian

  // Fungsi untuk memfilter buku berdasarkan input pencarian
  const filteredBooks = books.filter(
    (book) =>
      book.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      book.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
      book.year.toString().includes(searchTerm) ||
      book.genre.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold text-center mb-6">Daftar Buku</h1>
        <div className="flex gap-4 items-center">
          <div className="flex  items-center relative">
            <input
              type="text"
              className="border border-slate-300 pr-[50px] rounded-full py-2 px-4 outline-none w-[300px]"
              placeholder="Cari data buku..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update state pencarian
            />
            <button className=" absolute right-1 py-2 px-4">
              {IconSearch2("24", "24", "gray")}
            </button>
          </div>
          <Link to={"tambah"}>
            <button className="flex gap-2 py-2 rounded-full font-roboto px-4 bg-blue-700 text-white">
              {IconAdd("24", "24", "white")}
              <p>Tambah Buku</p>
            </button>
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredBooks.length > 0 ? (
          filteredBooks.map((book) => (
            <div
              key={book.id}
              className="bg-white shadow-md rounded-lg p-4 border border-gray-200 hover:shadow-lg transition-shadow"
            >
              <img
                src={book.image}
                alt={book.title}
                className="w-full h-48 object-cover rounded-md mb-4"
              />
              <h2 className="text-lg font-semibold text-gray-800">
                {book.title}
              </h2>
              <p className="text-gray-600">Penulis: {book.author}</p>
              <p className="text-gray-600">Tahun: {book.year}</p>
              <p className="text-gray-600">Genre: {book.genre}</p>
            </div>
          ))
        ) : (
          <p className="text-center col-span-3 text-gray-600">
            Buku tidak ditemukan.
          </p>
        )}
      </div>
    </div>
  );
};

export default DaftarBuku;
