import React, { useState } from "react";
import KinerjaStudent from "../../School/dataKinerja/kinerjaStudent";
import DashboardWaliKelas from "./dashboardWaliKelas";

const WaliKelas: React.FC = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "dashboard":
        return (
          <div>
            <DashboardWaliKelas />
          </div>
        );
      case "data-siswa":
        return (
          <div>
            <KinerjaStudent />
          </div>
        );
      default:
        return <div>NotFaound</div>;
    }
  };
  return (
    <section className="w-full p-4 font-inter">
      <div className="flex gap-4 border border-slate-300 p-4 rounded-full items-center">
        <div className="w-[80px] h-[80px]">
          <img
            src="https://picsum.photos/200/300?random=5"
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div>
          <h1 className="text-[18px] font-semibold">Abdul Majid</h1>
          <p>NIP : 23232323243434</p>
          <p>Kelas : 10 Mipa 6</p>
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <button
          className={`w-[200px] py-1 rounded-full ${
            activeTab === "dashboard"
              ? "bg-slate-500 text-white"
              : "border border-slate-500 text-gray-500"
          }`}
          onClick={() => setActiveTab("dashboard")}
        >
          Dashboard
        </button>
        <button
          className={`w-[200px] rounded-full py-1 ${
            activeTab === "data-siswa"
              ? "bg-slate-500 text-white"
              : "border border-slate-500 text-gray-500"
          }`}
          onClick={() => setActiveTab("data-siswa")}
        >
          Data Siswa
        </button>
      </div>
      <div className="mt-4">{renderActiveComponent()}</div>
    </section>
  );
};

export default WaliKelas;
