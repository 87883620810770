import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlidePost: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0); // State for current slide index

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    beforeChange: (current: number, next: number) => setCurrentSlide(next), // Update current slide
  };

  const slideData = [
    {
      id: 1,
      image: "https://picsum.photos/600/400?random=1",
    },
    {
      id: 2,
      image: "https://picsum.photos/600/400?random=2",
    },
    {
      id: 3,
      image: "https://picsum.photos/600/400?random=3",
    },
  ];

  return (
    <div className="w-full max-w-[600px] mx-auto">
      {slideData.length === 1 ? (
        <div className="h-[300px] w-full rounded-lg overflow-hidden">
          <img
            src={slideData[0].image}
            alt={`Slide 1`}
            className="w-full h-full object-cover"
          />
        </div>
      ) : (
        <div>
          <Slider {...settings}>
            {slideData.map((item, index) => (
              <div
                key={index}
                className="h-[300px] w-full outline-none rounded-lg overflow-hidden"
              >
                <img
                  src={item.image}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </Slider>

          {/* Pagination Buttons */}
          <div className="flex gap-2 justify-center ">
            {slideData.map((_, index) => (
              <button
                key={index}
                className={`rounded-full h-[8px] ${
                  currentSlide === index
                    ? "w-[30px] bg-slate-400" // Active button
                    : "w-[8px] border border-slate-400" // Inactive button
                }`}
                onClick={() => setCurrentSlide(index)} // Update slide on button click
                aria-label={`Go to slide ${index + 1}`} // Accessibility
              ></button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SlidePost;
