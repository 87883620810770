import React from "react";
import { Link } from "react-router-dom";

interface Student {
  id: number;
  name: string;
  class: string;
  score: number;
  image: string;
}

const students: Student[] = [
  {
    id: 1,
    name: "Andik Firmansyah saputra",
    class: "10 MIPA 1",
    score: 85,
    image: "https://picsum.photos/200/300?random=1",
  },
  {
    id: 2,
    name: "Budi",
    class: "10 MIPA 2",
    score: 90,
    image: "https://picsum.photos/200/300?random=2",
  },
  {
    id: 3,
    name: "Citra",
    class: "11 MIPA 1",
    score: 88,
    image: "https://picsum.photos/200/300?random=3",
  },
  {
    id: 4,
    name: "Dewi",
    class: "12 IPS 1",
    score: 92,
    image: "https://picsum.photos/200/300?random=4",
  },
  {
    id: 5,
    name: "Eko",
    class: "11 IPS 2",
    score: 78,
    image: "https://picsum.photos/200/300?random=5",
  },
];

const StudentSmart: React.FC = () => {
  // Mengurutkan students berdasarkan score dari nilai tertinggi ke terendah
  const sortedStudents = students.sort((a, b) => b.score - a.score);

  return (
    <div className="h-auto p-2 shadow-lg">
      <h1 className="text-[18px] font-bold mb-1">Siswa Berprestasi</h1>
      <div className="grid grid-cols-1 gap-2 overflow-y-auto h-[300px]">
        {sortedStudents.map((student, index) => (
          <Link
            to={"/sekolah/kinerja/siswa/detail"}
            key={index}
            className="bg-white flex justify-between items-center shadow-lg rounded-lg p-2"
          >
            <div className="flex gap-2 items-center">
              <p className="text-[14px] font-semibold">{index + 1}.</p>
              <div className="w-[50px] h-[50px]">
                <img
                  src={student.image}
                  alt={student.name}
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
              <div>
                <h2 className="text-[14px] font-semibold">{student.name}</h2>
                <p className="text-[12px] text-gray-600">{student.class}</p>
              </div>
            </div>

            <p className="text-center text-[20px] font-extrabold text-green-600">
              {student.score}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default StudentSmart;
