import React from "react";

const Loading: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-blue-50">
      <div className="text-center">
        {/* Animasi buku berputar */}
        <div className="relative inline-block">
          <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
          <div className="absolute top-0 left-0 w-16 h-16 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-10 h-10 text-blue-500"
            >
              <path d="M12 2a1 1 0 01.993.883L13 3v7.585l1.292-1.293a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-3 3a1 1 0 01-1.32.083l-.094-.083-3-3a1 1 0 011.32-1.497l.094.083L11 10.585V3a1 1 0 011-1zm0 20a1 1 0 01-.993-.883L11 21v-7.585l-1.292 1.293a1 1 0 01-1.32.083l-.094-.083a1 1 0 01-.083-1.32l.083-.094 3-3a1 1 0 011.32-.083l.094.083 3 3a1 1 0 01-1.32 1.497l-.094-.083L13 13.415V21a1 1 0 01-1 1z" />
            </svg>
          </div>
        </div>

        {/* Pesan loading */}
        <h1 className="mt-6 text-2xl font-bold text-blue-600">Loading...</h1>
        <p className="mt-2 text-blue-500">
          Bersiap untuk belajar hal baru, harap tunggu sebentar!
        </p>

        {/* Animasi progress bar */}
        <div className="w-full mt-4 bg-gray-200 rounded-full h-2.5">
          <div className="bg-blue-500 h-2.5 rounded-full animate-pulse w-1/2"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
