import React, { useState } from "react";
import AddBukuExcel from "./AddBukuExcel";
import {
  IconClose,
  IconImportData,
  IconInput,
  IconUpload,
} from "../../../../../Assets/Icons";

interface Book {
  id: number;
  title: string;
  author: string;
  year: number;
  genre: string;
  image: string;
}

const AddBuku: React.FC = () => {
  const [newBook, setNewBook] = useState<Book>({
    id: Math.floor(Math.random() * 1000), // Generate ID secara acak
    title: "",
    author: "",
    year: new Date().getFullYear(), // Tahun default adalah tahun sekarang
    genre: "",
    image: "",
  });

  const [isManualInput, setIsManualInput] = useState(true); // State untuk input manual atau Excel

  // Fungsi untuk menangani upload gambar
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewBook((prevBook) => ({
          ...prevBook,
          image: reader.result as string, // Simpan URL gambar sebagai string base64
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Fungsi untuk menangani klik pada gambar untuk membuka file explorer
  const handleImageClick = () => {
    const fileInput = document.getElementById("imageInput") as HTMLInputElement;
    fileInput?.click(); // Simulasikan klik pada input file
  };

  // Fungsi untuk menghapus gambar
  const handleImageRemove = () => {
    setNewBook((prevBook) => ({
      ...prevBook,
      image: "", // Reset URL gambar ke string kosong
    }));
  };

  // Fungsi untuk menangani input perubahan
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewBook((prevBook) => ({
      ...prevBook,
      [name]: value,
    }));
  };

  // Fungsi untuk toggle metode input
  const toggleInputMethod = (method: "manual" | "excel") => {
    setIsManualInput(method === "manual");
  };

  return (
    <div className="max-w-[1440px] font-inter mx-auto p-6 bg-white rounded-xl shadow-xl">
      {/* Kontainer utama */}
      <div className="flex lg:flex-row flex-col bg-white border border-slate-300 rounded-lg py-4 shadow-md px-6 justify-between max-w-full">
        <h2 className="text-[24px] lg:text-3xl font-extrabold text-center text-blue-600">
          Tambah Data Buku
        </h2>
        <div className="flex gap-4 mt-3 lg:mt-0">
          <button
            onClick={() => toggleInputMethod("manual")}
            className={`${
              isManualInput
                ? "bg-blue-500 text-white"
                : "border border-blue-500 text-gray-500"
            } flex gap-2 rounded-lg font-roboto py-2 px-4`}
          >
            {IconInput("24", "24", isManualInput ? "white" : "gray")}{" "}
            <p>Manual</p>
          </button>
          <button
            onClick={() => toggleInputMethod("excel")}
            className={`${
              !isManualInput
                ? "bg-blue-500 text-white"
                : "border border-blue-500 text-gray-500"
            } flex gap-2 rounded-lg font-roboto py-2 px-4`}
          >
            {IconImportData("24", "24", !isManualInput ? "white" : "gray")}{" "}
            <p>Import Excel</p>
          </button>
        </div>
      </div>

      <div className="overflow-y-auto h-[400px] mt-2 p-6">
        {isManualInput ? (
          <form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Input Judul Buku */}
              <div>
                <label className="block text-gray-700 mb-2">Judul Buku</label>
                <input
                  type="text"
                  name="title"
                  value={newBook.title}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Judul Buku"
                />
              </div>

              {/* Input Penulis */}
              <div>
                <label className="block text-gray-700 mb-2">Penulis</label>
                <input
                  type="text"
                  name="author"
                  value={newBook.author}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Nama Penulis"
                />
              </div>

              {/* Input Tahun Terbit */}
              <div>
                <label className="block text-gray-700 mb-2">Tahun Terbit</label>
                <input
                  type="number"
                  name="year"
                  value={newBook.year}
                  onChange={(e) =>
                    setNewBook({ ...newBook, year: parseInt(e.target.value) })
                  }
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Tahun Terbit"
                />
              </div>

              {/* Input Genre */}
              <div>
                <label className="block text-gray-700 mb-2">Genre</label>
                <input
                  type="text"
                  name="genre"
                  value={newBook.genre}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                  placeholder="Masukkan Genre Buku"
                />
              </div>

              {/* Upload Gambar */}
              <div className="grid grid-cols-2">
                <div className="md:col-span-2 ">
                  <label className="block text-gray-700 mb-2">Gambar</label>
                  <div className="cursor-pointer">
                    {newBook.image ? (
                      <div className="relative">
                        <img
                          src={newBook.image}
                          alt="Gambar Buku"
                          className="w-full h-48 object-contain border p-2 border-gray-300 rounded-md"
                        />
                        {/* Tombol untuk menghapus gambar */}
                        <button
                          onClick={handleImageRemove}
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                        >
                          {IconClose("24", "24", "white")}
                        </button>
                      </div>
                    ) : (
                      <div className="border items-center flex justify-center border-slate-300 rounded-lg  p-6 ">
                        <button
                          type="button"
                          onClick={handleImageClick}
                          className="bg-blue-500 flex gap-2 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-all transform hover:scale-105 focus:outline-none "
                        >
                          {IconUpload("24", "24", "white")} Pilih File
                        </button>
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageUpload}
                  />
                </div>
              </div>

              {/* Tombol Simpan dan Batal */}
              <div className="md:col-span-2 flex justify-end gap-4 items-center mt-6">
                <button
                  type="submit"
                  className="flex items-center gap-2 bg-blue-500 text-white px-8 py-3 rounded-md hover:bg-blue-600 focus:bg-blue-700 transition-all transform hover:scale-105 focus:outline-none"
                >
                  Simpan
                </button>
                <button
                  type="button"
                  className="flex items-center gap-2 bg-red-500 text-white px-8 py-3 rounded-md hover:bg-red-600 focus:bg-red-700 transition-all transform hover:scale-105 focus:outline-none"
                >
                  Batal
                </button>
              </div>
            </div>
          </form>
        ) : (
          <AddBukuExcel />
        )}
      </div>
    </div>
  );
};

export default AddBuku;
