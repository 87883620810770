import React from "react";

const LoadingUjian: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        {/* Animasi Pembuka Data Siswa */}
        <div className="flex justify-center mb-4">
          <svg
            className="animate-bounce w-16 h-16 text-blue-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="6" x2="12" y2="12" />
            <line x1="12" y1="12" x2="16" y2="12" />
          </svg>
        </div>

        {/* Pesan Loading */}
        <p className="mt-4 text-xl font-semibold text-gray-800">
          Memuat Data Siswa...
        </p>
        <p className="mt-2 text-md text-gray-600">
          Mohon tunggu sebentar, kami sedang menyiapkan informasi.
        </p>
      </div>
    </div>
  );
};

export default LoadingUjian;
