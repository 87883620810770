import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

// Daftarkan elemen yang diperlukan untuk Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const DiagramAbsensiTeacher = () => {
  // Data statis
  const studentData = {
    name: "Andi Surya",
    class: "10 IPA 1",
    attendance: {
      total: 30,
      present: 24,
      absent: 2,
      izin: 4, // Tambahkan data izin
    },
  };

  // Data untuk diagram donut
  const attendanceData = {
    labels: ["Hadir", "Tidak Hadir", "Izin"],
    datasets: [
      {
        data: [
          studentData.attendance.present,
          studentData.attendance.absent,
          studentData.attendance.izin,
        ],
        backgroundColor: ["#4CAF50", "#F44336", "#FF9800"], // Kombinasi warna yang lebih menarik
        hoverOffset: 4,
        borderWidth: 2,
        borderColor: "#ffffff",
      },
    ],
  };

  // Opsi untuk diagram
  const options = {
    cutout: "60%",
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
      },
    },
  };

  return (
    <div className="bg-white lg:p-5 font-inter  rounded-lg shadow-lg mb-6">
      <h2 className="text-xl font-semibold mb-2">Data Absensi</h2>
      <div className="flex lg:flex-row flex-col gap-4">
        <div>
          <div className="flex w-[300px] h-[300px] justify-center items-center">
            <Doughnut data={attendanceData} options={options} />
          </div>
          <p className="absolute font-bold text-[25px] text-gray-500 mt-[-180px] ml-[120px] ">
            100 %
          </p>
        </div>
        <div className="p-4 border border-slate-400 rounded-lg shadow-lg bg-white">
          <div className="flex lg:flex-row flex-col justify-between mb-2">
            <p className="font-bold text-[14px] mb-2">Keterangan :</p>
            <div className="flex gap-2 text-[12px] text-white">
              <button className="bg-[#4CAF50] px-4 py-1 rounded-lg">
                3 Hadir
              </button>
              <button className="bg-[#F44336] px-4 py-1 rounded-lg">
                3 Ijin
              </button>
              <button className="bg-[#FF9800]  px-4 py-1 rounded-lg">
                3 Tidak Hadir
              </button>
            </div>
          </div>

          <div className="flex gap-2 text-[16px] text-center border-b border-slate-300 pb-2 mb-2">
            <p className="w-[50px] font-semibold">No</p>
            <p className="w-[100px] font-semibold">Tanggal</p>{" "}
            <p className="w-[100px] font-semibold">Kehadiran</p>
            <p className="w-[300px] font-semibold">Keterangan</p>
          </div>
          <div className="overflow-y-auto h-[240px]">
            {[1, 2, 3, 4].map((i) => (
              <div
                key={i}
                className="flex gap-2 text-[12px] border-b border-slate-200 py-2 hover:bg-slate-50"
              >
                <p className="w-[50px] text-center">{i}</p>
                <p className="w-[100px] text-center text-green-600">
                  23/03/2024
                </p>
                <p className="w-[100px] text-center text-green-600">Ijin</p>
                <p className="w-[300px]">
                  Latihan Upacara Bendera hari senin. Latihan Upacara Bendera
                  hari senin.
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagramAbsensiTeacher;
