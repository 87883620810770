import React, { useState } from "react";
import { ImageExcel } from "../../../../../../Assets/Images";
import { IconClose, IconUpload } from "../../../../../../Assets/Icons";

const AddBukuExcel: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  // Fungsi untuk menangani perubahan file yang dipilih
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  // Fungsi untuk menangani klik tombol "Pilih File"
  const handleUploadClick = () => {
    // Membuka dialog untuk memilih file
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Fungsi untuk menangani pengiriman file
  const handleFileSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedFile) {
      // Lakukan sesuatu dengan file, seperti mengirim ke server
      console.log("File yang dipilih: ", selectedFile);
      // Kirim file ke server atau lakukan parsing di sini
    }
  };

  // Fungsi untuk menghapus file yang dipilih
  const handleRemoveFile = () => {
    setSelectedFile(null); // Menghapus file yang dipilih
  };

  return (
    <div className="w-full mx-auto p-4 bg-gray-50 rounded-xl shadow mt-6">
      <form onSubmit={handleFileSubmit}>
        <div className="flex flex-col items-center">
          <label className="text-lg font-semibold text-gray-700 mb-4">
            Import Data Buku dari Excel
          </label>

          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />

          {/* Jika file sudah dipilih, tampilkan nama dan opsi untuk menghapus */}
          {selectedFile && (
            <div className="mb-4 text-gray-500">
              <div className="flex gap-2">
                <div className="w-[150px] h-[150px]">
                  <img
                    src={ImageExcel}
                    alt="Excel Icon"
                    className="w-full h-full object-contain"
                  />
                </div>
                {/* Tombol untuk menghapus file yang dipilih */}
                <button
                  type="button"
                  onClick={handleRemoveFile}
                  className="bg-gray-700 p-[3px] h-[30px] rounded-lg"
                >
                  {IconClose("24", "24", "white")}
                </button>
              </div>
              <p>{selectedFile.name}</p>
            </div>
          )}

          {/* Jika data sudah tampil, jangan tampilkan tombol ini */}
          {!selectedFile && (
            <div className="border items-center flex border-slate-300 rounded-lg  p-6 ">
              <button
                type="button"
                onClick={handleUploadClick}
                className="bg-blue-500 flex gap-2 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition-all transform hover:scale-105 focus:outline-none "
              >
                {IconUpload("24", "24", "white")} Pilih File
              </button>
            </div>
          )}

          <div className="flex gap-4">
            <button
              className="border-2 border-green-500 mt-[50px] flex gap-2 text-gray-700 px-6 py-2 rounded-md transition-all transform hover:scale-105 focus:outline-none
                
                  hover:bg-green-600 hover:text-white
              "
            >
              Kembali
            </button>
            <button
              type="submit"
              className={`bg-blue-700 mt-[50px] flex gap-2 text-white px-6 py-2 rounded-md transition-all transform hover:scale-105 focus:outline-none ${
                !selectedFile
                  ? "opacity-80 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
              disabled={!selectedFile}
            >
              Simpan
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBukuExcel;
