import React from "react";
import {
  IconChat,
  IconClose,
  IconLike,
  IconNotif,
} from "../../../Assets/Icons"; // Assuming these icons are correctly imported
import SlidePost from "../slidePost"; // Assuming this component is correctly imported
import CommentPost from "../CommentPost";

// Define types for Post and User
interface Post {
  id: number;
  content: string;
  timeAgo: string;
  comment: number;
  likes: number;
}

interface User {
  name: string;
  school: string;
  profilePicture: string;
  visibility: string;
}

interface DetailPostProps {
  onClose: () => void;
}
const DetailPost: React.FC<DetailPostProps> = ({ onClose }) => {
  // Static data
  const post: Post = {
    id: 1,
    content:
      "Ini adalah contoh konten postingan yang lebih panjang dari 120 karakter untuk menunjukkan bagaimana cara menampilkan teks dengan pemotongan. Semoga contoh ini bermanfaat dan memberikan gambaran yang jelas tentang cara kerja komponen ini.",
    timeAgo: "2 jam yang lalu",
    comment: 5,
    likes: 10,
  };

  const user: User = {
    name: "Abdul Majid",
    school: "SMAN 1 Pedes Karawang",
    profilePicture: "https://picsum.photos/200/300?random=8",
    visibility: "Publik",
  };

  // Static data for showing full text
  const showFullText = false; // Change this to true to simulate full text shown

  // Static data for liked posts
  const likedPosts = [1]; // This post is liked

  // Function stubs for demonstration
  const toggleFullText = (id: number) => {
    console.log("Toggled full text for post:", id);
  };

  const handleLikeClick = (id: number) => {
    console.log("Liked post:", id);
  };

  return (
    <div className=" relative  overflow-y-auto h-[530px] mt-[30px] w-[700px] bg-white shadow-lg border rounded-lg">
      <div className="bg-white border-b border-slate-300 shadow-b-sm p-3 lg:p-2  lg:w-[680px] w-full z-40 items-center flex justify-between fixed">
        <p className="text-[18px]  font-bold font-roboto">Postingan</p>
        <button
          onClick={onClose}
          className="border  border-slate-300 p-[3px] rounded-lg"
        >
          {IconClose("30", "30", "gray")}
        </button>
      </div>
      <div className="py-[10px] px-[15px] lg:mt-[50px] mt-[60px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div className="lg:w-[60px] lg:h-[60px] w-[40px] h-[40px]">
              <img
                src={user.profilePicture}
                alt=""
                className="w-full h-full rounded-full"
              />
            </div>

            <div className="flex flex-col">
              <p className="text-[12px] lg:text-[14px] font-bold font-inter">
                {user.school}
              </p>
              <div className="flex gap-5 items-center">
                <p className="text-[12px] font-semibold text-gray-800 font-roboto">
                  {user.name}
                </p>
                <p className="text-[10px] text-slate-500">{user.visibility}</p>
              </div>
            </div>
          </div>

          <div className="flex lg:gap-4 items-center">
            <p className="lg:text-[14px] text-[10px] text-slate-500">
              {post.timeAgo}
            </p>
            <button>{IconNotif("30", "30", "blue")}</button>
          </div>
        </div>
        <div className="h-auto">
          <div className="flex justify-center my-2 relative">
            <SlidePost />{" "}
            {/* Assuming SlidePost is a static or mocked component */}
          </div>
          <div>
            <p className="text-[12px] lg:text-[14px] font-inter lg:px-[40px] leading-[17px]">
              <span className="font-roboto font-semibold">@{user.name} ~</span>
              {showFullText || post.content.length <= 120
                ? post.content
                : `${post.content.slice(0, 120)}... `}
              {post.content.length > 120 && (
                <span
                  className="text-blue-600 pl-2 cursor-pointer"
                  onClick={() => toggleFullText(post.id)}
                >
                  {showFullText ? "Sembunyikan" : "Selengkapnya"}
                </span>
              )}
            </p>
          </div>
          <div className="flex lg:justify-end mt-[10px]">
            <div className="grid grid-cols-2 w-full gap-5 lg:w-auto">
              <div className="flex gap-1 border border-slate-300 shadow-md py-2 w-full lg:w-[120px] justify-center rounded-full">
                {IconChat("20", "20", "gray")}{" "}
                <p className="mt-[-2px]">{post.comment}</p>
              </div>
              <div
                className="flex gap-1 border border-slate-300 shadow-md py-2 w-full lg:w-[120px] justify-center rounded-full"
                onClick={() => handleLikeClick(post.id)}
              >
                {IconLike(
                  "20",
                  "20",
                  likedPosts.includes(post.id) ? "blue" : "gray"
                )}
                <p className="mt-[-2px]">{post.likes}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-slate-300 shadow-lg rounded-lg mx-[20px] p-4 mt-2">
        <p className="text-[14px] font-semibold text-gray-600">
          Komentar dan Balasan
        </p>
        <CommentPost />
      </div>
    </div>
  );
};

export default DetailPost;
