import React, { useState } from "react";
import DataKinerjaPerpus from "../../School/dataKinerja/dataKinerjaPerpus";
import DaftarBuku from "./daftarBuku";
import Peminjaman from "./peminjaman";

const PerpustakaanTeacher: React.FC = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "dashboard":
        return (
          <div>
            {/* Dashboard */}
            <DataKinerjaPerpus />
          </div>
        );
      case "koleksi":
        return (
          <div>
            {/* Koleksi */}
            <DaftarBuku />
          </div>
        );
      case "peminjaman":
        return (
          <div>
            {/* Peminjaman */}
            <Peminjaman />
          </div>
        );
      default:
        return <div>Dashboard Perpustakaan</div>;
    }
  };

  return (
    <section className="lg:p-6 p-2">
      <div className="flex gap-4 border border-slate-500 rounded-lg p-4">
        <div className="w-[80px] h-[80px]">
          <img
            src="https://picsum.photos/200/300?random=5"
            alt=""
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold">Perpustakaan</h2>
          <p className="text-[14px]">
            Daftar koleksi perpustakaan yang tersedia di perpustakaan.
          </p>
        </div>
      </div>
      <div className="mt-4 flex gap-4">
        <button
          className={`w-[150px] py-1 rounded-full ${
            activeTab === "dashboard"
              ? "bg-slate-500 text-white"
              : "border border-slate-500 text-gray-500"
          } `}
          onClick={() => setActiveTab("dashboard")}
        >
          Dashboard
        </button>
        <button
          className={`w-[150px] py-1 rounded-full ${
            activeTab === "koleksi"
              ? "bg-slate-500 text-white"
              : "border border-slate-500 text-gray-500"
          } `}
          onClick={() => setActiveTab("koleksi")}
        >
          Koleksi Buku
        </button>
        <button
          className={`w-[150px] py-1 rounded-full ${
            activeTab === "peminjaman"
              ? "bg-slate-500 text-white"
              : "border border-slate-500 text-gray-500"
          } `}
          onClick={() => setActiveTab("peminjaman")}
        >
          Peminjaman
        </button>
      </div>
      <div>
        <div className="border border-slate-500 rounded-lg  mt-4">
          {renderActiveComponent()}
        </div>
      </div>
    </section>
  );
};

export default PerpustakaanTeacher;
