import React, { useState, useEffect } from "react";
import { IconSearch } from "../../../../Components/Navbar/Icons";
import { Link } from "react-router-dom";

// Updated TypeScript interface for borrow status
interface BorrowStatus {
  id: number;
  name: string; // Student's name directly integrated
  nis: string; // Student's NIS (ID)
  kelas: string; // Student's class
  jumlah: number; // Number of books borrowed
  durasi: string; // Duration of the borrowing
  status: "pengajuan" | "dibatalkan" | "dipinjam" | "dikembalikan" | "dipesan"; // Status of borrowing
}

// Dummy data for book borrowing status directly including student names and additional details
const borrowData: BorrowStatus[] = [
  {
    id: 1,
    name: "Siswa A",
    nis: "123456789",
    kelas: "12 Mipa 1",
    jumlah: 2,
    durasi: "Seminggu",
    status: "pengajuan",
  },
  {
    id: 2,
    name: "Siswa A",
    nis: "123456789",
    kelas: "12 Mipa 1",
    jumlah: 1,
    durasi: "Seminggu",
    status: "dipinjam",
  },
  {
    id: 3,
    name: "Siswa B",
    nis: "987654321",
    kelas: "11 IPS 3",
    jumlah: 3,
    durasi: "Dua minggu",
    status: "dikembalikan",
  },
  {
    id: 4,
    name: "Siswa B",
    nis: "987654321",
    kelas: "11 IPS 3",
    jumlah: 1,
    durasi: "Seminggu",
    status: "pengajuan",
  },
  {
    id: 5,
    name: "Siswa C",
    nis: "123789456",
    kelas: "10 IPA 4",
    jumlah: 2,
    durasi: "Seminggu",
    status: "pengajuan",
  },
];

const Peminjaman: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<
    "dipesan" | "dibatalkan" | "pengajuan" | "dipinjam" | "dikembalikan"
  >("pengajuan");
  const [filteredData, setFilteredData] = useState<BorrowStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set());

  // Filter books based on selected category and search query
  useEffect(() => {
    const filteredBooks = borrowData.filter(
      (item) =>
        item.status === selectedCategory &&
        (item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.nis.includes(searchQuery))
    );
    setFilteredData(filteredBooks);
  }, [selectedCategory, searchQuery]);

  // Handle category change
  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category as any);
  };

  // Handle checkbox change
  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prev) => {
      const newCheckedItems = new Set(prev);
      newCheckedItems.has(id)
        ? newCheckedItems.delete(id)
        : newCheckedItems.add(id);
      return newCheckedItems;
    });
  };

  // Count checked items
  const countCheckedItems = () => {
    return Array.from(checkedItems).filter((id) =>
      filteredData.some((item) => item.id === id)
    ).length;
  };

  return (
    <div className="p-4 font-inter min-h-screen">
      <h1 className="text-2xl font-bold mb-4 text-center">Peminjaman Buku</h1>

      {/* Status Pinjaman Buku */}
      <div className="flex overflow-x-auto space-x-4 mb-4">
        {["dipesan", "pengajuan", "dipinjam", "dikembalikan", "dibatalkan"].map(
          (category) => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className={`px-4 py-2 rounded-md transition-colors duration-200 whitespace-nowrap ${
                selectedCategory === category
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              }`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          )
        )}
      </div>

      {/* Search Bar */}
      <div className="flex justify-between flex-wrap mb-4 gap-4">
        <div className="flex-1">
          <div className="border border-slate-300 flex rounded-full relative items-center">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-[300px] md:w-full py-1 px-2 lg:px-4 outline-none"
              placeholder="Cari berdasarkan nama atau NIS..."
            />
            <div className="p-2">{IconSearch("24", "24", "gray")}</div>
          </div>
        </div>

        {/* Button for Approve/Delete */}
        <button className="flex gap-2 bg-gray-500 py-1 px-2 lg:py-2 lg:text-[16px] lg:px-4 text-[12px] rounded-full text-white">
          <p className="pr-2">
            {countCheckedItems() > 0 && countCheckedItems()}
          </p>
          {selectedCategory === "dipesan"
            ? ""
            : selectedCategory === "pengajuan" ||
              selectedCategory === "dipinjam"
            ? "Disetujui"
            : selectedCategory === "dikembalikan" ||
              selectedCategory === "dibatalkan"
            ? "Dihapus"
            : ""}
        </button>
      </div>

      {/* Borrow List */}
      <div className="space-y-2">
        {filteredData.length > 0 ? (
          filteredData.map(
            ({ id, name, nis, kelas, jumlah, durasi, status }) => (
              <div
                key={id}
                className="border p-3 rounded-md shadow-lg bg-white"
              >
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2">
                  <div className="flex flex-col md:flex-row items-start md:items-center gap-4 w-full">
                    <input
                      type="checkbox"
                      checked={checkedItems.has(id)}
                      onChange={() => handleCheckboxChange(id)}
                      className="h-[20px] w-[20px] rounded"
                    />
                    <div className="w-[100px] h-[80px]">
                      <img
                        src="https://picsum.photos/200/300?random=5"
                        alt=""
                        className="w-full h-full object-cover rounded-md"
                      />
                    </div>
                    <Link to={"detail-pinjam"} className="flex-1">
                      <div>
                        <h4 className="text-md font-semibold">{name}</h4>
                        <p className="text-sm text-gray-600">{nis}</p>
                        <p className="text-sm text-gray-600">{kelas}</p>
                        <p className="md:hidden text-sm text-gray-600">
                          Jumlah: {jumlah} buku
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="hidden md:block text-right">
                    <h4 className="text-md font-semibold">Durasi: {durasi}</h4>
                    <p className="text-sm text-gray-600">
                      Jumlah: {jumlah} buku
                    </p>
                    <h4 className="text-md font-semibold">
                      Status: {status.charAt(0).toUpperCase() + status.slice(1)}
                    </h4>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <p className="text-gray-500">Tidak ada buku dengan status ini.</p>
        )}
      </div>
    </div>
  );
};

export default Peminjaman;
