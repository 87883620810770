import React, { useState } from "react";
import { IconAdd, IconDelete } from "../../../../../Assets/Icons";

// Define a type for MataPelajaran
interface MataPelajaran {
  nama: string;
  jam: string;
  pengajar: string;
}

const AddMataPelajaran: React.FC = () => {
  const [mataPelajaran, setMataPelajaran] = useState<MataPelajaran[]>([
    { nama: "", jam: "", pengajar: "" }, // Initialize with one mata pelajaran
  ]);
  const [jurusan, setJurusan] = useState<string>("");
  const [kelas, setKelas] = useState<string>("");
  const [namaKelas, setNamaKelas] = useState<string>("");

  // Function to add a new mata pelajaran
  const handleAddMataPelajaran = () => {
    setMataPelajaran([...mataPelajaran, { nama: "", jam: "", pengajar: "" }]);
  };

  // Function to handle input changes in mata pelajaran
  const handleMataPelajaranChange = (
    index: number,
    field: keyof MataPelajaran,
    value: string
  ) => {
    const updatedMataPelajaran = [...mataPelajaran];
    updatedMataPelajaran[index][field] = value;
    setMataPelajaran(updatedMataPelajaran);
  };

  // Function to remove a mata pelajaran
  const handleRemoveMataPelajaran = (index: number) => {
    const updatedMataPelajaran = mataPelajaran.filter((_, i) => i !== index);
    setMataPelajaran(updatedMataPelajaran);
  };

  // Function to save the data
  const handleSave = () => {
    const data = {
      jurusan,
      kelas,
      namaKelas,
      mataPelajaran,
    };
    console.log("Saved data:", data);
    // Here you can implement the saving functionality, like sending data to an API
  };

  return (
    <div className="w-full mx-auto p-6 border font-inter rounded-lg shadow-md bg-white">
      <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">
        Tambah Mata Pelajaran
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1 text-gray-600">
            Jurusan
          </label>
          <input
            type="text"
            value={jurusan}
            onChange={(e) => setJurusan(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Masukkan jurusan"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1 text-gray-600">
            Kelas
          </label>
          <input
            type="text"
            value={kelas}
            onChange={(e) => setKelas(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Masukkan kelas"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium mb-1 text-gray-600">
            Nama Kelas
          </label>
          <input
            type="text"
            value={namaKelas}
            onChange={(e) => setNamaKelas(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            placeholder="Masukkan nama kelas"
          />
        </div>
      </div>

      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold mb-2 text-gray-800">
          Mata Pelajaran
        </h3>
        <div className="mb-4">
          <button
            onClick={handleAddMataPelajaran}
            className="w-full p-3 bg-blue-500 text-white flex gap-2 font-semibold text-[16px] rounded-md hover:bg-blue-600 transition duration-200"
          >
            {IconAdd("24", "24", "white")}
            <p className="hidden lg:block">Tambah Mata Pelajaran</p>
          </button>
        </div>
      </div>
      {mataPelajaran.map((item, index) => (
        <div
          key={index}
          className="mb-2 relative grid grid-cols-1 lg:grid-cols-4 gap-4 p-4 border rounded-md bg-gray-100 shadow-sm"
        >
          <div className="mb-2">
            <label className="block text-sm font-medium mb-1 text-gray-600">
              Mata Pelajaran
            </label>
            <input
              type="text"
              value={item.nama}
              onChange={(e) =>
                handleMataPelajaranChange(index, "nama", e.target.value)
              }
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              placeholder="Masukkan nama mata pelajaran"
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium mb-1 text-gray-600">
              Jam Pelajaran
            </label>
            <input
              type="text"
              value={item.jam}
              onChange={(e) =>
                handleMataPelajaranChange(index, "jam", e.target.value)
              }
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              placeholder="Masukkan jam pelajaran"
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium mb-1 text-gray-600">
              Hari
            </label>
            <input
              type="text"
              value={item.jam}
              onChange={(e) =>
                handleMataPelajaranChange(index, "jam", e.target.value)
              }
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              placeholder="Masukkan jam pelajaran"
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium mb-1 text-gray-600">
              Pengajar
            </label>
            <input
              type="text"
              value={item.pengajar}
              onChange={(e) =>
                handleMataPelajaranChange(index, "pengajar", e.target.value)
              }
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              placeholder="Masukkan nama pengajar"
            />
          </div>
          {mataPelajaran.length > 1 && ( // Only show remove button if there's more than one mata pelajaran
            <button
              onClick={() => handleRemoveMataPelajaran(index)}
              className="mt-2 absolute right-3 text-red-600 hover:underline focus:outline-none"
            >
              {IconDelete("24", "24", "red")}
            </button>
          )}
        </div>
      ))}

      <div className="flex justify-end gap-4 mt-4">
        <button className="w-[300px] ml-2 p-3 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-600 transition duration-200">
          Kembali
        </button>
        <button
          onClick={handleSave}
          className="w-[300px] mr-2 p-3 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 transition duration-200"
        >
          Simpan
        </button>
      </div>
    </div>
  );
};

export default AddMataPelajaran;
