import React from "react";
import { IconClassroom, IconStudent, IconTeacher } from "../../../Assets/Icons";
import GrafictStudent from "./grafictStudent";
import AbsenSiswa from "./AbsenSiswa";
import JadwalKelas from "./JadwalKelas";

const DashboardTeacher = () => {
  return (
    <main className="font-inter lg:p-6 pt-8 bg-gray-50 min-h-screen">
      <div className="relative mt-[-40px] ">
        <div className="fixed p-4 w-[1100px] border rounded-b-lg bg-white  border-slate-300 shadow-sm">
          <h1 className="text-[24px] font-bold ">Dashboar Guru</h1>
        </div>
      </div>
      <section className="max-w-6xl mx-auto  pt-[80px] p-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Card Wali Kelas */}
          <div className="bg-white text-center shadow-lg rounded-xl border border-slate-200 p-4 ">
            <h1 className="text-[48px] font-extrabold text-gray-800">27</h1>
            <div className="flex justify-center gap-4 items-center mb-2">
              {IconTeacher("30", "30", "#4F46E5")} {/* Warna icon biru */}
              <p className="text-[22px] font-semibold text-gray-600">
                Wali Kelas
              </p>
            </div>
          </div>
          <div className="bg-white text-center shadow-lg rounded-xl border border-slate-200 p-4 ">
            <h1 className="text-[48px] font-extrabold text-gray-800">27</h1>
            <div className="flex justify-center gap-4 items-center mb-2">
              {IconClassroom("30", "30", "#4F46E5")} {/* Warna icon biru */}
              <p className="text-[22px] font-semibold text-gray-600">Kelas</p>
            </div>
          </div>
          <div className="bg-white text-center shadow-lg rounded-xl border border-slate-200 p-4 ">
            <h1 className="text-[48px] font-extrabold text-gray-800">27</h1>
            <div className="flex justify-center gap-4 items-center mb-2">
              {IconStudent("30", "30", "#4F46E5")} {/* Warna icon biru */}
              <p className="text-[22px] font-semibold text-gray-600">Siswa</p>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col-reverse gap-4 mt-4 relative">
          <GrafictStudent />
          <JadwalKelas />
        </div>
      </section>
    </main>
  );
};

export default DashboardTeacher;
