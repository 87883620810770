import { Suspense, lazy } from "react"; // Pastikan lazy digunakan
import LoadingUjian from "../../Components/loading/LoadingUjian";
import ErrorPage from "../../Components/pageError";
import ProfilePage from "../../Pages/ProfilePage";
import DataStudent from "../../Pages/School/DataStudent";
import AddStudent from "../../Pages/School/DataStudent/AddStudent";
import DataTeacher from "../../Pages/School/DataTeacher";
import AddTeacher from "../../Pages/School/DataTeacher/AddTeacher";
import DataWAliMurid from "../../Pages/School/DataWaliMurid";
import AddWaliMurid from "../../Pages/School/DataWaliMurid/AddWaliMurid";
import KinerjaStudent from "../../Pages/School/dataKinerja/kinerjaStudent";
import DetailKinerjaStudent from "../../Pages/School/dataKinerja/kinerjaStudent/detailKinerjaStudent";
import DataPerpus from "../../Pages/School/dataKinerja/dataKinerjaPerpus";
import AcademicCalendar from "../../Pages/academicCalendar";
import DetailDataCalender from "../../Pages/academicCalendar/CalendarSchool/detailDataCalender";
import AddDataCalendar from "../../Pages/academicCalendar/CalendarSchool/detailDataCalender/AddDataCalender";
import AkademikPelajaran from "../../Pages/School/dataAkademik/akademikPelajaran";
import AddMataPelajaran from "../../Pages/School/dataAkademik/akademikPelajaran/addMataPelajaran";
import EssaySemster from "../../Pages/School/essaySemester";
import AddEssaySemester from "../../Pages/School/essaySemester/AddEssaySemester";
import DashboardSchool from "../../Pages/School/dashboardSchool";
import KinerjaTeacher from "../../Pages/School/dataKinerja/KinerjaTeacher";
import DetailKinerjaTeacher from "../../Pages/School/dataKinerja/KinerjaTeacher/detailKinerjaTeacher";

const SchoolRoute = [
  {
    path: "/sekolah",
    errorElement: (
      <Suspense fallback={<LoadingUjian />}>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        path: "profil",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <ProfilePage />
          </Suspense>
        ),
      },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DashboardSchool />
          </Suspense>
        ),
      },
      {
        path: "demografi/siswa",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DataStudent />
          </Suspense>
        ),
      },
      {
        path: "demografi/tambah-siswa",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddStudent />
          </Suspense>
        ),
      },
      {
        path: "demografi/guru",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DataTeacher />
          </Suspense>
        ),
      },
      {
        path: "demografi/tambah-guru",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddTeacher />
          </Suspense>
        ),
      },
      {
        path: "demografi/wali-murid",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DataWAliMurid />
          </Suspense>
        ),
      },
      {
        path: "demografi/tambah-wali-murid",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddWaliMurid />
          </Suspense>
        ),
      },

      // data Kinerja
      {
        path: "kinerja/siswa",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <KinerjaStudent />
          </Suspense>
        ),
      },
      {
        path: "kinerja/siswa/detail",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailKinerjaStudent />
          </Suspense>
        ),
      },
      {
        path: "kinerja/guru",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <KinerjaTeacher />
          </Suspense>
        ),
      },
      {
        path: "kinerja/guru/detail",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailKinerjaTeacher />
          </Suspense>
        ),
      },
      {
        path: "kinerja/perpustakaan",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DataPerpus />
          </Suspense>
        ),
      },

      // data Akademik
      {
        path: "akademik/kalender",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AcademicCalendar />
          </Suspense>
        ),
      },

      {
        path: "akademik/kalender/detail",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailDataCalender />
          </Suspense>
        ),
      },
      {
        path: "akademik/kalender/tambah",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddDataCalendar />
          </Suspense>
        ),
      },
      {
        path: "akademik/mata-pelajaran",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AkademikPelajaran />
          </Suspense>
        ),
      },
      {
        path: "akademik/mata-pelajaran/tambah",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddMataPelajaran />
          </Suspense>
        ),
      },
      {
        path: "ujian-semester",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <EssaySemster />
          </Suspense>
        ),
      },
      {
        path: "ujian-semester/tambah",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddEssaySemester />
          </Suspense>
        ),
      },
    ],
  },
];

export default SchoolRoute;
