import React, { Suspense, lazy } from "react";
import ErrorPage from "../Components/pageError";
import AcademicCalendar from "../Pages/academicCalendar";
import StatusBorrow from "../Pages/ListBooks/statusBorrow";
import ListBooks from "../Pages/ListBooks";
import LoadingUjian from "../Components/loading/LoadingUjian";

// Lazy-loaded student components
const LessonTimetablePage = lazy(
  () => import("../Pages/student/lessonTimetablePage")
);
const GoingClass = lazy(
  () => import("../Pages/student/lessonTimetablePage/goingClass")
);
const MaterialSpace = lazy(
  () => import("../Pages/student/lessonTimetablePage/materialSpace")
);
const AssignmentRoom = lazy(
  () => import("../Pages/student/lessonTimetablePage/assignmentRoom")
);
const MarkUts = lazy(() => import("../Pages/student/MarkUts"));
const MarkFinalScore = lazy(() => import("../Pages/student/finalScore"));
const SemesterGrades = lazy(() => import("../Pages/student/semesterGrades"));
const Consolidation = lazy(() => import("../Pages/student/consolidation"));
const DetailArtikel = lazy(
  () =>
    import("../Pages/student/consolidation/ConsolidationArtikel/detailArtikel")
);
const ExamList = lazy(() => import("../Pages/student/examList"));
const Essay = lazy(() => import("../Pages/student/examList/essay"));
const Teaching = lazy(() => import("../Pages/Teaching"));

// Student routes
const StudentRoutes = [
  {
    path: "/siswa",
    errorElement: (
      <Suspense fallback={<LoadingUjian />}>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        path: "jadwal-pelajaran",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <LessonTimetablePage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "jadwal-pelajaran/absen",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <GoingClass />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "jadwal-pelajaran/ruang-materi",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <MaterialSpace />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "jadwal-pelajaran/tugas",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AssignmentRoom />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "mengajar",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <Teaching />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "nilai-uts",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <MarkUts />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "nilai-uas",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <MarkFinalScore />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "nilai-semester",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <SemesterGrades />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "konsolidasi",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <Consolidation />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "konsolidasi/artikel",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailArtikel />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "jadwal-ujian",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <ExamList />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "ujian",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <Essay />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "konseling",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <Consolidation />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "konseling/detail-artikel",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailArtikel />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "kalender-akademik",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AcademicCalendar />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "buku",
        children: [
          {
            path: "list",
            element: (
              <Suspense fallback={<LoadingUjian />}>
                <ListBooks />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<LoadingUjian />}>
                <ErrorPage />
              </Suspense>
            ),
          },
          {
            path: "status",
            element: (
              <Suspense fallback={<LoadingUjian />}>
                <StatusBorrow />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<LoadingUjian />}>
                <ErrorPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

export default StudentRoutes;
