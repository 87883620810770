import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Data mockup ulangan harian siswa
const dataNilaiUlanganHarian = {
  kelasA: [
    [80, 85, 90, 92],
    [75, 80, 85, 87],
    [90, 92, 95, 94],
    [85, 88, 90, 92],
  ],
  kelasB: [
    [60, 65, 70, 75],
    [70, 72, 75, 77],
    [80, 82, 83, 85],
    [65, 70, 73, 76],
  ],
  kelasC: [
    [55, 60, 65, 68],
    [60, 62, 65, 67],
    [70, 75, 78, 80],
    [50, 55, 58, 60],
  ],
  kelasD: [
    [55, 60, 65, 68],
    [60, 62, 30, 67],
    [70, 75, 78, 80],
    [50, 55, 58, 60],
  ],
};

// Fungsi untuk menghitung rata-rata dari array nilai siswa
const hitungRataRataPerUjian = (nilaiUlangan: number[][]) => {
  const jumlahUjian = nilaiUlangan[0].length;
  const rataRataUjian: number[] = [];

  for (let ujian = 0; ujian < jumlahUjian; ujian++) {
    let totalNilai = 0;
    let jumlahSiswa = nilaiUlangan.length;

    nilaiUlangan.forEach((nilaiSiswa) => {
      totalNilai += nilaiSiswa[ujian];
    });

    rataRataUjian.push(totalNilai / jumlahSiswa);
  }

  return rataRataUjian;
};

// Fungsi untuk menghasilkan warna acak
const generateRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return `#${randomColor}`;
};

const GrafictStudent: React.FC = () => {
  // Label untuk ujian
  const labels = ["Ujian 1", "Ujian 2", "Ujian 3", "Ujian 4", "Ujian 5"];

  // Hitung rata-rata nilai ulangan harian per kelas
  const datasets = Object.keys(dataNilaiUlanganHarian).map((kelas) => {
    const rataRataKelas = hitungRataRataPerUjian(
      dataNilaiUlanganHarian[kelas as keyof typeof dataNilaiUlanganHarian]
    );
    const borderColor = generateRandomColor();
    const backgroundColor = `${borderColor}33`; // Transparansi 20%

    return {
      label: kelas,
      data: rataRataKelas,
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      fill: true,
      tension: 0.4,
      pointStyle: "circle",
      pointRadius: 5,
      pointBackgroundColor: borderColor,
    };
  });

  // Konfigurasi data untuk grafik Line
  const data = {
    labels: labels,
    datasets: datasets,
  };

  // Opsi untuk grafik Line
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: "Grafik Rata-rata Perkembangan Nilai Ulangan Harian Siswa",
        font: {
          size: 18,
        },
        padding: {
          top: 20,
          bottom: 20,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        min: 50,
        max: 100,
        ticks: {
          stepSize: 5,
        },
        title: {
          display: true,
          text: "Nilai Rata-rata ",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return (
    <div className="overflow-x-auto">
      <div className="w-[650px] p-4  border border-slate-300 mx-auto rounded-lg shadow-md">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default GrafictStudent;
