// CalendarPicker.tsx
import React from "react";
import DatePicker from "react-datepicker";
import { id } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

interface CalendarPickerProps {
  dateStart: Date | undefined;
  dateEnd: Date | undefined;
  onDateChange: (dates: [Date | null, Date | null]) => void;
  onSave: () => void;
  onCancel: () => void;
}

const CalendarPicker: React.FC<CalendarPickerProps> = ({
  dateStart,
  dateEnd,
  onDateChange,
  onSave,
  onCancel,
}) => {
  return (
    <div className="absolute z-10 w-[280px] mt-2">
      <div className="border rounded-lg shadow-lg p-4 bg-white">
        <DatePicker
          selected={dateStart}
          onChange={onDateChange}
          selectsRange
          startDate={dateStart}
          endDate={dateEnd}
          className="w-full px-4 py-2 rounded-md text-[14px] focus:outline-none focus:border-orange-500"
          placeholderText="Pilih rentang tanggal"
          inline
          locale={id}
          dateFormat="dd/MM/yyyy"
        />
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 text-sm font-medium outline-none text-orange-500 border border-orange-500 rounded-lg hover:bg-orange-500 hover:text-white transition duration-200"
            onClick={onCancel}
          >
            Batal
          </button>
          <button
            className={`px-4 py-2 text-sm font-medium text-white rounded-lg transition duration-200 ${
              !dateStart
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-orange-500 hover:bg-orange-600"
            }`}
            onClick={onSave}
            disabled={!dateStart}
          >
            Simpan
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarPicker;
