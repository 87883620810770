import React from "react";
import TruncateText from "../../../Components/truncateText";
import { Link } from "react-router-dom";

// Data Event
const events = [
  {
    date_start: "2024-10-20",
    date_end: "2024-10-22",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-28",
    date_end: "2024-11-04",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
];

// Komponen untuk menampilkan event
const CalendarSchool: React.FC = () => {
  return (
    <div className="w-full font-inter  max-w-[500px] mx-auto p-4 bg-gray-100 rounded-lg shadow-md">
      <div className="flex justify-between">
        <h1 className="text-[20px] font-bold text-center mb-6 text-blue-700">
          Kalender Sekolah
        </h1>
        <Link to={"detail"}>
          <button className="bg-blue-700 text-white px-4 py-1 rounded-full">
            Detail
          </button>
        </Link>
      </div>

      <div className="space-y-2 overflow-y-auto lg:h-[400px]">
        {events.map((event, index) => (
          <div
            key={index}
            className="border rounded-lg p-4 shadow-md bg-white  "
          >
            <div className="flex items-center mb-2">
              <span className="text-blue-500 text-lg mr-2">🗓️</span>
              <h2 className="text-xl lg:text-[14px] font-semibold text-blue-600">
                {event.title}
              </h2>
            </div>
            <p className="text-sm text-gray-500 lg:text-[12px]">
              {event.date_end
                ? `${event.date_start} - ${event.date_end}`
                : event.date_start}
            </p>
            <p className="text-gray-700 mt-2 lg:text-[12px]">
              {TruncateText(event.desc, 50)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarSchool;
