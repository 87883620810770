import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import "tailwindcss/tailwind.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Type for teacher data
type Teacher = {
  name: string;
  subject: string;
  score: number;
};

// Static data for 20 teachers
const teacherData: Teacher[] = [
  { name: "Mr. Ahmad", subject: "Mathematics", score: 85 },
  { name: "Ms. Bella", subject: "English", score: 90 },
  { name: "Mr. Charlie", subject: "Physics", score: 78 },
  { name: "Ms. Diana", subject: "Chemistry", score: 88 },
  { name: "Mr. Edward", subject: "Biology", score: 82 },
  { name: "Ms. Fiona", subject: "History", score: 80 },
  { name: "Mr. George", subject: "Geography", score: 75 },
  { name: "Ms. Helen", subject: "Physical Education", score: 95 },
  { name: "Mr. Ivan", subject: "Music", score: 85 },
  { name: "Ms. Jane", subject: "Art", score: 92 },
  { name: "Mr. Kevin", subject: "Economics", score: 79 },
  { name: "Ms. Lisa", subject: "Sociology", score: 87 },
  { name: "Mr. Mike", subject: "Philosophy", score: 77 },
  { name: "Ms. Nancy", subject: "Psychology", score: 91 },
  { name: "Mr. Oliver", subject: "Computer Science", score: 89 },
  { name: "Ms. Paula", subject: "Business Studies", score: 81 },
  { name: "Mr. Quentin", subject: "Law", score: 86 },
  { name: "Ms. Rachel", subject: "Spanish", score: 84 },
  { name: "Mr. Sam", subject: "French", score: 83 },
  { name: "Ms. Tina", subject: "German", score: 50 },
];

// Fungsi untuk menentukan warna batang berdasarkan score
const getBarColor = (score: number) => {
  if (score < 60) {
    return "rgba(255, 99, 132, 0.8)"; // Merah untuk nilai < 60
  } else if (score <= 80) {
    return "rgba(255, 205, 86, 0.8)"; // Kuning untuk nilai 60 - 80
  } else {
    return "rgba(75, 192, 192, 0.8)"; // Hijau untuk nilai > 80
  }
};

// Chart data for the Bar chart
const chartData = {
  labels: teacherData.map((teacher) => teacher.name),
  datasets: [
    {
      label: "Teacher Score",
      data: teacherData.map((teacher) => teacher.score),
      backgroundColor: teacherData.map((teacher) => getBarColor(teacher.score)), // Warna dinamis berdasarkan score
      borderColor: teacherData.map((teacher) => getBarColor(teacher.score)),
      borderWidth: 1,
      hoverBackgroundColor: teacherData.map((teacher) =>
        getBarColor(teacher.score)
      ),
      hoverBorderColor: teacherData.map((teacher) =>
        getBarColor(teacher.score)
      ),
      borderRadius: 5,
    },
  ],
};

// Chart options typed with ChartOptions<"bar">
const chartOptions: ChartOptions<"bar"> = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        font: {
          size: 12, // Set font size for legend labels
        },
      },
    },
    title: {
      display: true,
      text: "Kinerja Guru Berdasarkan Mata Pelajaran",
      font: {
        size: 18, // Customize title font size
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      titleFont: { size: 16 },
      bodyFont: { size: 14 },
      bodySpacing: 5,
      padding: 10,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100, // Limit maximum score to 100
      ticks: {
        stepSize: 10, // Add step size for better readability
      },
      title: {
        display: true,
        text: "Score",
        font: {
          size: 14, // Customize font size for y-axis title
        },
      },
    },
    x: {
      title: {
        display: true,
        text: "Teachers",
        font: {
          size: 14, // Customize font size for x-axis title
        },
      },
    },
  },
  animation: {
    duration: 1000,
    easing: "easeOutBounce",
  },
};

// Main component that renders the bar chart
const GrafikTeacher: React.FC = () => {
  return (
    <div className="overflow-y-auto">
      <div className="bg-white shadow-lg w-[700px] px-2 h-[350px] rounded-lg">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default GrafikTeacher;
