import React, { useState } from "react";
import { Link } from "react-router-dom";
import CalendarPicker from "../../../../../Components/calendarPicker";
import {
  IconDelete,
  IconImportData,
  IconInput,
} from "../../../../../Assets/Icons";
import AddCalenderExcel from "./AddCalenderExcel";

interface EventData {
  date_start: Date | undefined;
  date_end: Date | undefined;
  title: string;
  desc: string;
}

const initialData: EventData = {
  date_start: undefined,
  date_end: undefined,
  title: "",
  desc: "",
};

const AddDataCalendar: React.FC = () => {
  // Ubah menjadi array untuk menampung beberapa event
  const [eventDataList, setEventDataList] = useState<EventData[]>([
    initialData,
  ]);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean[]>([false]); // Untuk mengatur kalender setiap event
  const [isManualInput, setIsManualInput] = useState(true);
  // Fungsi untuk menambah data baru (menggandakan input)
  const handleAddNewInput = () => {
    setEventDataList([...eventDataList, { ...initialData }]);
    setIsCalendarOpen([...isCalendarOpen, false]);
  };

  // Fungsi untuk menyimpan data
  const handleSave = (index: number) => {
    console.log(`Data disimpan:`, eventDataList[index]);
    const newCalendarState = [...isCalendarOpen];
    newCalendarState[index] = false;
    setIsCalendarOpen(newCalendarState);
  };

  // Fungsi untuk membatalkan input
  const handleCancel = (index: number) => {
    const newEventDataList = [...eventDataList];
    newEventDataList[index] = initialData;
    setEventDataList(newEventDataList);

    const newCalendarState = [...isCalendarOpen];
    newCalendarState[index] = false;
    setIsCalendarOpen(newCalendarState);
  };

  // Fungsi untuk mengubah input title atau description
  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const newEventDataList = [...eventDataList];
    newEventDataList[index] = {
      ...newEventDataList[index],
      [name]: value,
    };
    setEventDataList(newEventDataList);
  };

  // Fungsi untuk mengubah tanggal
  const handleDateChange = (
    index: number,
    dates: [Date | null, Date | null]
  ) => {
    const [start, end] = dates;
    const newEventDataList = [...eventDataList];
    newEventDataList[index] = {
      ...newEventDataList[index],
      date_start: start || undefined,
      date_end: end || undefined,
    };
    setEventDataList(newEventDataList);
  };

  // Toggle kalender
  const toggleCalendar = (index: number) => {
    const newCalendarState = [...isCalendarOpen];
    newCalendarState[index] = !newCalendarState[index];
    setIsCalendarOpen(newCalendarState);
  };

  // Fungsi untuk menghapus input
  const handleRemoveInput = (index: number) => {
    if (eventDataList.length > 1) {
      const updatedEventDataList = eventDataList.filter((_, i) => i !== index);
      setEventDataList(updatedEventDataList);
    }
  };

  // Fungsi untuk memformat range tanggal
  const formatDateRange = (start: Date, end?: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const startDate = new Intl.DateTimeFormat("id-ID", options).format(start);

    if (!end) {
      return startDate;
    }

    const startMonth = start.toLocaleString("id-ID", { month: "long" });
    const endMonth = end.toLocaleString("id-ID", { month: "long" });

    if (startMonth === endMonth) {
      return `${start.getDate()} - ${end.getDate()} ${startMonth} ${start.getFullYear()}`;
    } else {
      const endDate = new Intl.DateTimeFormat("id-ID", options).format(end);
      return `${start.getDate()} ${startMonth} - ${endDate}`;
    }
  };

  const toggleInputMethod = (method: "manual" | "excel") => {
    setIsManualInput(method === "manual");
  };
  return (
    <div className="w-full p-6 lg:p-8 mb-[100px] bg-white rounded-lg font-roboto shadow-lg mx-auto">
      <div className="flex lg:flex-row flex-col  bg-white border border-slate-300 rounded-lg py-4 shadow-md px-6 justify-between max-w-full">
        <h2 className="text-[24px] lg:text-3xl font-extrabold text-center text-blue-600">
          Tambah Data Kalender
        </h2>
        <div className="flex gap-4 mt-3 lg:mt-0">
          <button
            onClick={() => toggleInputMethod("manual")}
            className={`${
              isManualInput
                ? "bg-blue-500 text-white"
                : "border border-blue-500 text-gray-500"
            } flex gap-2 rounded-lg font-roboto py-2 px-4`}
          >
            {IconInput("24", "24", isManualInput ? "white" : "gray")}{" "}
            <p>Manual</p>
          </button>
          <button
            onClick={() => toggleInputMethod("excel")}
            className={`${
              !isManualInput
                ? "bg-blue-500 text-white"
                : "border border-blue-500 text-gray-500"
            } flex gap-2 rounded-lg font-roboto py-2 px-4`}
          >
            {IconImportData("24", "24", !isManualInput ? "white" : "gray")}{" "}
            <p>Import Excel</p>
          </button>
        </div>
      </div>

      <div className="overflow-y-auto h-[400px] mt-2 p-6">
        {isManualInput ? (
          <div className="relative">
            {eventDataList.map((eventData, index) => (
              <div
                key={index}
                className="space-y-6 border-b pb-6 relative mb-6"
              >
                <div className="flex  justify-between">
                  <div className="text-[24px] font-bold ">
                    Data Kalender {index + 1}
                  </div>
                  {/* Tombol Hapus Input */}
                  {eventDataList.length > 1 && (
                    <button
                      className=" flex gap-1 items-center justify-center  text-[16px] font-bold  w-[150px] bg-red-500 mt-[-20px] text-white rounded-full  hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      onClick={() => handleRemoveInput(index)}
                    >
                      {IconDelete("24", "24", "white")} Hapus
                    </button>
                  )}
                </div>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  {/* Input untuk judul acara */}
                  <div>
                    <label className="block text-gray-700 text-[16px] font-bold mb-2">
                      Judul Acara:
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={eventData.title}
                      onChange={(e) => handleInputChange(index, e)}
                      className={`w-full outline-none px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 transition`}
                      placeholder="Masukkan judul acara..."
                    />
                  </div>

                  {/* Pilihan tanggal */}
                  <div>
                    <label className="block text-gray-700 text-[16px] font-bold mb-2">
                      Tanggal Mulai dan Akhir:
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        readOnly
                        onClick={() => toggleCalendar(index)}
                        value={
                          eventData.date_start
                            ? formatDateRange(
                                eventData.date_start,
                                eventData.date_end
                              )
                            : "Pilih tanggal"
                        }
                        className={`w-full outline-none px-4 py-3 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 transition cursor-pointer`}
                        placeholder="Tanggal Mulai - Tanggal Akhir"
                      />
                      {isCalendarOpen[index] && (
                        <CalendarPicker
                          dateStart={eventData.date_start}
                          dateEnd={eventData.date_end}
                          onDateChange={(dates) =>
                            handleDateChange(index, dates)
                          }
                          onSave={() => handleSave(index)}
                          onCancel={() => handleCancel(index)}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Input untuk deskripsi acara */}
                <div>
                  <label className="block text-gray-700 text-[16px] font-bold mb-2">
                    Deskripsi Acara:
                  </label>
                  <textarea
                    name="desc"
                    value={eventData.desc}
                    onChange={(e) => handleInputChange(index, e)}
                    className={`w-full px-4 py-3 border outline-none rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 transition`}
                    rows={4}
                    placeholder="Masukkan deskripsi acara..."
                  />
                </div>
              </div>
            ))}

            {/* Tombol Tambah Data dan Simpan */}
            <div className="flex justify-between mt-6">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-green-600 transition-colors focus:outline-none focus:ring-4 focus:ring-green-500 focus:ring-opacity-50"
                onClick={handleAddNewInput}
              >
                Tambah Data
              </button>
              <Link to={"/sekolah/akademik/kalender/detail"}>
                <button className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50">
                  Simpan Semua
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <AddCalenderExcel />
        )}
      </div>
    </div>
  );
};

export default AddDataCalendar;
