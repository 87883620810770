import React from "react";
import { Link } from "react-router-dom";

interface JadwalKelas {
  kelas: string;
  mapel: string;
  jam: string;
}

const jadwalHariIni: JadwalKelas[] = [
  { kelas: "Kelas 10A", mapel: "Matematika", jam: "08:00 - 09:30" },
  { kelas: "Kelas 10B", mapel: "Fisika", jam: "10:00 - 11:30" },
  { kelas: "Kelas 11A", mapel: "Kimia", jam: "13:00 - 14:30" },
  { kelas: "Kelas 11B", mapel: "Biologi", jam: "15:00 - 16:30" },
];

const JadwalKelas: React.FC = () => {
  return (
    <div className="w-[350px]  font-inter mx-auto p-4 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-center mb-6 text-blue-600">
        Jadwal Kelas Hari Ini
      </h1>

      <ul className="space-y-4 overflow-y-auto h-[250px]">
        {jadwalHariIni.map((item, index) => (
          <Link
            to={"/guru/absensi"}
            key={index}
            className="bg-blue-50 p-2 rounded-lg shadow hover:bg-blue-100 transition duration-300"
          >
            <div>
              <div className="flex justify-between">
                <p className="font-semibold text-[18px]">{item.kelas}</p>
                <p className="text-gray-700 text-[14px] font-medium">
                  {item.jam}
                </p>
              </div>
              <p className="font-medium text-[16px] text-blue-500">
                {item.mapel}
              </p>
            </div>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default JadwalKelas;
