import React from "react";
import { IconBook } from "../../../../Assets/Icons";
import GrafictPerpustkaan from "./grafictPerpustkaan";

const DataPerpus: React.FC = () => {
  return (
    <section className="bg-gray-50 p-8 container mx-auto">
      <h1 className="text-[20px] font-inter font-bold mb-6">Perpustakaan</h1>
      <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 font-roboto">
        {/* Total Buku */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-4xl font-bold text-indigo-600 mb-2">24</h1>
          <div className="flex justify-center items-center gap-2 text-gray-700">
            {IconBook("30", "30", "indigo")}
            <p className="text-[16px] font-bold">Total Buku</p>
          </div>
        </div>

        {/* Total Dipinjam */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-4xl font-bold text-green-500 mb-2">24</h1>
          <div className="flex justify-center items-center gap-2 text-gray-700">
            {IconBook("30", "30", "green")}
            <p className="text-[16px] font-bold">Total Dipinjam</p>
          </div>
        </div>

        {/* Hilang */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-4xl font-bold text-red-500 mb-2">24</h1>
          <div className="flex justify-center items-center gap-2 text-gray-700">
            {IconBook("30", "30", "red")}
            <p className="text-[16px] font-bold">Hilang</p>
          </div>
        </div>

        {/* Belum Dikembalikan */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center">
          <h1 className="text-4xl font-bold text-yellow-500 mb-2">24</h1>
          <div className="flex justify-center items-center gap-2 text-gray-700">
            {IconBook("30", "30", "yellow")}
            <p className="text-[16px] font-bold">Belum Dikembalikan</p>
          </div>
        </div>
      </div>
      <div>
        <GrafictPerpustkaan />
      </div>
    </section>
  );
};

export default DataPerpus;
