import React from "react";

interface EventData {
  date_start: string;
  date_end: string;
  title: string;
  desc: string;
}

const initialData: EventData = {
  date_start: "2024-10-20",
  date_end: "2024-10-22",
  title: "Kuliah Praktikum",
  desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
};

interface FileDetailDataProps {
  onClose: () => void;
}

const DetailData: React.FC<FileDetailDataProps> = ({ onClose }) => {
  return (
    <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Detail Data Kalender</h2>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Judul Acara:
        </label>
        <p className="border p-2 rounded-md text-sm text-gray-800">
          {initialData.title}
        </p>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Deskripsi Acara:
        </label>
        <p className="border p-2 rounded-md text-sm text-gray-800">
          {initialData.desc}
        </p>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Tanggal Mulai dan Akhir:
        </label>
        <p className="border p-2 rounded-md text-sm text-gray-800">
          {`${initialData.date_start} - ${initialData.date_end}`}
        </p>
      </div>

      <div className="flex justify-between mt-6">
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded-md"
          onClick={onClose}
        >
          Tutup
        </button>
      </div>
    </div>
  );
};

export default DetailData;
