import React from "react";
import { Link } from "react-router-dom";

interface Lesson {
  subject: string;
  time: string;
  teacher: string;
  roomNumber: string;
  className: string;
}

const lessons: Lesson[] = [
  {
    subject: "Matematika",
    time: "08:00 - 09:00",
    teacher: "Budi Santoso",
    roomNumber: "101",
    className: "Kelas 10 A",
  },
  {
    subject: "Bahasa Inggris",
    time: "09:00 - 10:00",
    teacher: "Budi Santoso",
    roomNumber: "102",
    className: "Kelas 10 B",
  },
  {
    subject: "Fisika",
    time: "10:00 - 11:00",
    teacher: "Budi Santoso",
    roomNumber: "103",
    className: "Kelas 11 A",
  },
  {
    subject: "Kimia",
    time: "11:00 - 12:00",
    teacher: "Budi Santoso",
    roomNumber: "104",
    className: "Kelas 11 B",
  },
  {
    subject: "Biologi",
    time: "13:00 - 14:00",
    teacher: "Budi Santoso",
    roomNumber: "105",
    className: "Kelas 12 A",
  },
  // Tambahkan data jadwal lainnya sesuai kebutuhan
];

const SchedulTeacherPage: React.FC = () => {
  return (
    <div className="min-h-screen lg:absolute lg:w-[1000px] font-inter bg-gradient-to-r from-teal-50 to-blue-50 p-4">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-xl p-[15px]">
        <h1 className="text-4xl font-bold text-gray-800 mb-6">
          Jadwal Mengajar Guru
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px]">
          {lessons.map((lesson, index) => (
            <div
              key={index}
              className="mb-6 p-[15px] border border-transparent rounded-2xl bg-white shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl"
            >
              <h2 className="text-3xl font-semibold text-blue-600 mb-3">
                {lesson.subject}
              </h2>
              <p className="text-gray-700 mb-2">
                <strong>Waktu:</strong> {lesson.time}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Guru:</strong> {lesson.teacher}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>Nomor Ruangan:</strong> {lesson.roomNumber}
              </p>
              <p className="text-gray-700 mb-4">
                <strong>Kelas:</strong> {lesson.className}
              </p>
              <div className="w-full flex flex-col gap-[10px]">
                <Link to={"/guru/absensi"}>
                  <button className="px-6 w-full py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-transform duration-300">
                    Masuk Kelas
                  </button>
                </Link>
                <Link to={"/guru/ruang-materi"}>
                  <button className="px-6 w-full py-3 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 transition-transform duration-300">
                    Ruang Materi
                  </button>
                </Link>
                <Link to={"/guru/ruang-tugas"}>
                  <button className="px-6 w-full py-3 bg-yellow-500 text-white rounded-lg shadow-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 transition-transform duration-300">
                    Ruang Tugas
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SchedulTeacherPage;
