import React, { useState } from "react";

// Updated TypeScript interface for books and borrow status
interface Book {
  id: number;
  title: string;
  isAvailable: boolean;
}

interface BorrowDetail {
  studentName: string;
  studentNIS: string;
  borrowedBooks: Book[];
}

const DetailPinjam: React.FC = () => {
  // Dummy data for books borrowed by a student
  const borrowDetail: BorrowDetail = {
    studentName: "Siswa A",
    studentNIS: "123456789",
    borrowedBooks: [
      { id: 1, title: "Matematika Lanjutan", isAvailable: true },
      { id: 2, title: "Fisika Quantum", isAvailable: true },
      { id: 3, title: "Sejarah Dunia", isAvailable: false },
      { id: 4, title: "Biologi Molekuler", isAvailable: true },
    ],
  };

  // State to handle the status of each book
  const [bookStatus, setBookStatus] = useState<{ [key: number]: boolean }>(
    borrowDetail.borrowedBooks.reduce((acc, book) => {
      acc[book.id] = book.isAvailable;
      return acc;
    }, {} as { [key: number]: boolean })
  );

  // Handle checkbox change for each book
  const handleCheckboxChange = (bookId: number) => {
    setBookStatus((prevStatus) => ({
      ...prevStatus,
      [bookId]: !prevStatus[bookId],
    }));
  };

  // Handle "Setujui" button click
  const handleApprove = () => {
    const unavailableBooks = Object.entries(bookStatus)
      .filter(([_, isAvailable]) => !isAvailable)
      .map(([id]) => id);

    if (unavailableBooks.length > 0) {
      alert(
        `Buku dengan ID berikut tidak tersedia: ${unavailableBooks.join(", ")}`
      );
    } else {
      alert("Semua buku tersedia dan disetujui.");
    }

    // Here, you can send the data to the server or handle the approval logic
  };

  return (
    <div className="p-6 font-inter min-h-screen bg-gray-50">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Detail Peminjaman Buku
      </h1>

      <div className="mb-8 bg-white shadow-md  flex gap-4 rounded-lg p-6">
        <div className="w-[100px] h-[80px]">
          <img
            src="https://picsum.photos/200/300?random=5"
            alt=""
            className="w-full h-full object-cover rounded-md"
          />
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">
            {borrowDetail.studentName}
          </h2>
          <p className="text-md text-gray-600">
            NIS: {borrowDetail.studentNIS}
          </p>
          <p className="text-md text-gray-600">Kelas : 12 Mipa 5</p>{" "}
        </div>
      </div>

      <div className="space-y-6">
        <div className="flex justify-between">
          <h3 className="text-xl font-semibold">Daftar Buku</h3>
          <div className="">
            <button
              onClick={handleApprove}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-lg transition-colors"
            >
              Setujui
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {borrowDetail.borrowedBooks.map((book) => (
            <div
              key={book.id}
              className="flex items-center justify-between border p-4 rounded-lg shadow-sm bg-white"
            >
              <div className="flex items-center gap-4">
                <input
                  type="checkbox"
                  checked={bookStatus[book.id]}
                  onChange={() => handleCheckboxChange(book.id)}
                  className="h-[20px] w-[20px] rounded cursor-pointer"
                />
                <div className="flex-1">
                  <h4 className="text-md font-semibold">{book.title}</h4>
                  <p className="text-sm text-gray-500">
                    Status:{" "}
                    {bookStatus[book.id] ? "Tersedia" : "Tidak Tersedia"}
                  </p>
                </div>
              </div>
              <div className="text-right">
                <p
                  className={`text-md ${
                    bookStatus[book.id] ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {bookStatus[book.id] ? "Tersedia" : "Tidak Tersedia"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailPinjam;
