import React, { useState } from "react";
import { IconClose, IconMinus, IconPlus } from "../../../Assets/Icons";

interface BorrowBookProps {
  onClose: () => void; // Ganti ke 'onClose' sesuai kebutuhan komponen utama
}

const BorrowBooks: React.FC<BorrowBookProps> = ({ onClose }) => {
  const [durationIndex, setDurationIndex] = useState(0);

  // Pilihan durasi
  const durations = ["3 hari", "1 minggu", "1 bulan", "1 semester"];

  // Fungsi untuk menambah durasi
  const increaseDuration = () => {
    if (durationIndex < durations.length - 1) {
      setDurationIndex(durationIndex + 1);
    }
  };

  // Fungsi untuk mengurangi durasi
  const decreaseDuration = () => {
    if (durationIndex > 0) {
      setDurationIndex(durationIndex - 1);
    }
  };

  return (
    <div className="bg-white rounded-xl lg:w-[400px] w-full p-6 h-auto shadow-lg">
      {/* Tombol Tutup */}
      <div className="w-full flex justify-end mb-4">
        <button
          onClick={onClose}
          className="hover:bg-gray-100 p-2 rounded-full"
        >
          {IconClose("30", "30", "black")}
        </button>
      </div>

      {/* Konten Buku */}
      <div className="flex flex-col items-center">
        <div className="w-full h-[200px] mb-4">
          <img
            src="https://picsum.photos/200/300?random=4"
            alt="Book cover"
            className="w-full h-full rounded-lg object-cover"
          />
        </div>
        <div className="w-full text-left">
          <p className="text-lg font-semibold mb-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </p>
          <p className="text-sm text-gray-500">
            Stok: <span className="font-medium text-gray-800">30</span>
          </p>
        </div>
      </div>

      {/* Kontrol Durasi */}
      <div className="flex items-center justify-between mt-6 mb-4">
        <p className="text-lg font-medium">Durasi</p>
        <div className="flex items-center">
          <button
            className={`p-2 ${
              durationIndex === 0 ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={decreaseDuration}
            disabled={durationIndex === 0}
          >
            {IconMinus("24", "24", "black")}
          </button>
          <p className="mx-4 text-lg w-[120px] text-center">
            {durations[durationIndex]}
          </p>
          <button
            className={`p-2 ${
              durationIndex === durations.length - 1
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onClick={increaseDuration}
            disabled={durationIndex === durations.length - 1}
          >
            {IconPlus("24", "24", "black")}
          </button>
        </div>
      </div>

      {/* Tombol Aksi */}
      <div className="grid grid-cols-2 gap-4 mt-4">
        <button
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-full transition-colors"
        >
          Dipesan
        </button>
        <button
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 rounded-full transition-colors"
        >
          Ajukan
        </button>
      </div>
    </div>
  );
};

export default BorrowBooks;
