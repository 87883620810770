import React, { useState } from "react";
import {
  IconAdd,
  IconDelete,
  IconEdit,
  IconExport,
  IconEye,
} from "../../../Assets/Icons";
import { IconSearch2 } from "../../ListBooks/iconBook";
import ExportData from "../../../Components/exportData";
import { Link } from "react-router-dom";

interface Student {
  id: number;
  nis: string;
  fullName: string;
  class: string;
  teacher: string;
  parent: string;
  address: string;
}

const students: Student[] = [
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "1234567896",
    fullName: "Ahmad Santoso",
    class: "10 Mipa 6",
    teacher: "Pak Budi",
    parent: "Ibu Sari",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "9876543216",
    fullName: "Siti Nurhaliza",
    class: "10 Mipa 6",
    teacher: "Bu Indah",
    parent: "Bapak Rahman",
    address: "Karawang",
  },
  // Tambahkan data siswa lainnya di sini
];

const DataStudent: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false);
  const [showExport, setShowExport] = useState(false);

  const handlShowExport = () => {
    setShowExport(true);
  };
  const handleExport = () => {
    setShowExport(false);
  };

  // Fungsi untuk menangani perubahan pada input pencarian
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // Fungsi untuk menangani klik tombol pencarian
  const handleSearchClick = () => {
    setIsSearchClicked(true);
  };

  // Jika tombol search sudah diklik, data siswa akan difilter
  const filteredStudents = isSearchClicked
    ? students.filter((student) => {
        const lowerQuery = searchQuery.toLowerCase();
        return (
          student.nis.toLowerCase().includes(lowerQuery) ||
          student.fullName.toLowerCase().includes(lowerQuery) ||
          student.class.toLowerCase().includes(lowerQuery) ||
          student.teacher.toLowerCase().includes(lowerQuery) ||
          student.parent.toLowerCase().includes(lowerQuery) ||
          student.address.toLowerCase().includes(lowerQuery)
        );
      })
    : students; // Jika belum klik tombol, tampilkan semua data

  return (
    <div className="container mx-auto p-4 relative">
      <div className="flex lg:flex-row flex-col lg:justify-between">
        <h1 className="text-2xl font-bold mb-6 ">Daftar Siswa</h1>
        <div className="flex gap-2 items-center">
          <input
            type="text"
            className="border border-slate-300 rounded-full py-2 px-4 outline-none w-[300px]"
            placeholder="Cari data siswa..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button onClick={handleSearchClick}>
            {IconSearch2("30", "30", "gray")}
          </button>
          <Link to={"/sekolah/demografi/tambah-siswa"}>
            <button className="bg-blue-500 lg:ml-[30px] rounded-lg font-roboto text-white lg:py-2 lg:px-4 p-1">
              {IconAdd("24", "24", "white")}
              <p className="hidden lg:block">Tambah Siswa</p>
            </button>
          </Link>
          <button
            onClick={handlShowExport}
            className="bg-red-500 flex gap-2 rounded-lg font-roboto text-white lg:py-2 lg:px-4 p-1"
          >
            {IconExport("24", "24", "white")}{" "}
            <p className="hidden lg:block">Export</p>
          </button>
        </div>
      </div>
      <div className="absolute right-4">
        {showExport && <ExportData onClose={handleExport} />}
      </div>
      <div className="min-w-full overflow-x-auto bg-white p-4">
        <div className="lg:w-full w-[900px] text-center grid grid-cols-6  bg-gray-100 border-b pr-[19px]">
          <div className="flex">
            <p className="px-4 py-2 w-[50px]">ID</p>
            <p className="px-4 py-2 w-[120px]">NIS</p>
          </div>
          <p className="px-4 py-2 ">Nama Lengkap</p>
          <p className="px-4 py-2 ">Kelas</p>
          <p className="px-4 py-2 ">Wali Kelas</p>
          <p className="px-4 py-2 ">Wali Murid</p>

          <p className="px-4 py-2">Aksi</p>
        </div>

        <div className="overflow-y-auto h-[400px] lg:w-full w-[900px]">
          {filteredStudents.length > 0 ? (
            filteredStudents.map((student, index) => (
              <div
                key={index}
                className="text-center  hover:bg-gray-100 grid grid-cols-6 "
              >
                <div className="flex ">
                  <p className="px-4 py-2 text-[14px] border-b w-[50px]">
                    {index + 1}
                  </p>
                  <p className="px-4 py-2 border-b w-[120px]">{student.nis}</p>
                </div>
                <p className="px-4 py-2 ">{student.fullName}</p>
                <p className="px-4 py-2 b ">{student.class}</p>
                <p className="px-4 py-2 ">{student.teacher}</p>
                <p className="px-4 py-2 ">{student.parent}</p>

                <p className="px-4 py-2 flex gap-2 border-b w-[110px]">
                  <button>{IconEdit("24", "24", "blue")}</button>
                  <button>{IconDelete("24", "24", "red")}</button>{" "}
                  <button>
                    <IconEye />
                  </button>
                </p>
              </div>
            ))
          ) : (
            <p className="text-center py-4">Tidak ada data yang ditemukan.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataStudent;
