import React, { useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isToday,
  getMonth,
  getYear,
  parseISO,
  isSameDay,
  startOfWeek,
  differenceInDays,
} from "date-fns";
import { IconChevron } from "../../Assets/Icons";
import CalendarSchool from "./CalendarSchool";

// Contoh data acara
const events = [
  {
    date_start: "2024-10-20",
    date_end: "2024-10-22",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-28",
    date_end: "2024-11-04",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
];

// Mendapatkan nama bulan dari angka bulan
const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

// Mendapatkan nama hari dalam bahasa Indonesia
const weekDays = ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"];

const getMonthDays = (date: Date) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  return eachDayOfInterval({ start, end });
};

const getEventsForDay = (date: Date) => {
  return events.filter((event) => {
    const startDate = parseISO(event.date_start);
    const endDate = event.date_end ? parseISO(event.date_end) : startDate;
    return date >= startDate && date <= endDate;
  });
};

const formatEventDateRange = (startDate: Date, endDate?: Date) => {
  const startDay = format(startDate, "d");
  const startMonth = monthNames[getMonth(startDate)];
  const endDay = endDate ? format(endDate, "d") : "";
  const endMonth = endDate ? monthNames[getMonth(endDate)] : "";
  const year = getYear(startDate);

  if (endDate) {
    if (getYear(startDate) === getYear(endDate)) {
      if (getMonth(startDate) === getMonth(endDate)) {
        return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
      } else {
        return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
      }
    } else {
      return `${startDay} ${startMonth} ${year} - ${endDay} ${endMonth} ${getYear(
        endDate
      )}`;
    }
  } else {
    return `${startDay} ${startMonth} ${year}`;
  }
};

const AcademicCalendar: React.FC = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  const handleMonthClick = (index: number) => {
    const newDate = new Date(getYear(selectedMonth), index, 1);
    setSelectedMonth(newDate);
    setSelectedDay(null); // Clear selected day when changing month
  };

  const handleYearChange = (direction: "prev" | "next") => {
    setSelectedMonth((prev) => {
      const newYear =
        direction === "prev" ? prev.getFullYear() - 1 : prev.getFullYear() + 1;

      return new Date(newYear, prev.getMonth(), 1);
    });
  };

  const handleDateClick = (date: Date) => {
    setSelectedDay(date);
  };

  const monthDays = getMonthDays(selectedMonth);
  const eventsForSelectedDay = selectedDay ? getEventsForDay(selectedDay) : [];

  const currentYear = getYear(selectedMonth);
  const currentMonthIndex = getMonth(selectedMonth);

  const startWeekDay = startOfWeek(startOfMonth(selectedMonth), {
    weekStartsOn: 0,
  });

  return (
    <section className="lg:flex full flex-col ">
      <div className="p-6 font-inter bg-gray-100 lg:w-[680px] rounded-lg shadow-lg">
        <div className="mb-[20px]">
          <h1 className="text-[25px] font-inter font-bold">
            Kalender Pendidikan
          </h1>
        </div>
        <div className="flex flex-col">
          <div>
            {/* buatkan bagian ini untuk mengganti tahun saja tanpa bulan */}
            <div className="flex justify-center items-center mb-6">
              <button
                onClick={() => handleYearChange("prev")}
                className="rotate-90 outline-none px-4 py-2"
              >
                {IconChevron("24", "24", "black")}
              </button>

              <h2 className="text-2xl font-semibold text-gray-800">
                {currentYear}
              </h2>
              <button
                onClick={() => handleYearChange("next")}
                className=" -rotate-90 outline-none px-4 py-2 "
              >
                {IconChevron("24", "24", "black")}
              </button>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-[20px]">
              {monthNames.map((month, index) => (
                <div
                  key={index}
                  className={`p-4 cursor-pointer border rounded-lg shadow-md transition-colors duration-200 ease-in-out  ${
                    index === currentMonthIndex
                      ? "bg-blue-500 text-white"
                      : "bg-white"
                  }`}
                  onClick={() => handleMonthClick(index)}
                >
                  <h3 className="text-[14px] font-semibold text-center">
                    {month}
                  </h3>
                </div>
              ))}
            </div>
          </div>

          {/* Kalender Harian */}
          <div className="mt-[35px]">
            <div>
              {/* Display selected month and year */}
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                {monthNames[currentMonthIndex]} {currentYear}
              </h2>

              <div className="grid grid-cols-7 gap-2 mb-2 text-center text-gray-600">
                {weekDays.map((day) => (
                  <div key={day} className="font-semibold">
                    {day}
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-7 gap-2">
                {/* Empty divs for the days before the start of the month */}
                {Array(
                  differenceInDays(startOfMonth(selectedMonth), startWeekDay)
                )
                  .fill(null)
                  .map((_, index) => (
                    <div key={index} className="p-2"></div>
                  ))}
                {monthDays.map((day) => {
                  const eventsForDay = getEventsForDay(day);
                  return (
                    <div
                      key={day.toString()}
                      className={`p-2 cursor-pointer border rounded-lg relative transition-colors duration-200 ease-in-out ${
                        isToday(day) ? "bg-yellow-600 text-white" : ""
                      } ${
                        selectedDay && isSameDay(selectedDay, day)
                          ? "bg-gray-500 text-white"
                          : eventsForDay.length > 0
                          ? "bg-blue-500 text-white border-blue-800"
                          : "bg-white"
                      } `}
                      onClick={() => handleDateClick(day)}
                    >
                      <div className="text-center text-[14px] ">
                        {format(day, "d")}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Events Display */}
        <div className="mt-6">
          {selectedDay && (
            <div>
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Events for {format(selectedDay, "d MMMM yyyy")}
              </h3>
              {eventsForSelectedDay.length > 0 ? (
                eventsForSelectedDay.map((event, index) => (
                  <div
                    key={index}
                    className={`p-4 mb-2 rounded-md shadow-md border-l-4 bg-white lg:border-slate-400`}
                  >
                    <h4 className="font-semibold text-[14px]">{event.title}</h4>
                    <p className="text-[13px]">
                      {formatEventDateRange(
                        parseISO(event.date_start),
                        event.date_end ? parseISO(event.date_end) : undefined
                      )}
                    </p>
                    <p className="mt-2 text-[12px]">{event.desc}</p>
                  </div>
                ))
              ) : (
                <p>No events on this day.</p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="lg:fixed lg:right-0">
        <CalendarSchool />
      </div>
    </section>
  );
};

export default AcademicCalendar;
