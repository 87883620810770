import React from "react";

interface MataPelajaran {
  nama: string;
  nilai: number;
}

interface DiagramMapelProps {
  dataMapel: MataPelajaran[];
}

const dataMapel = [
  { nama: "Matematika", nilai: 80 },
  { nama: "Bahasa Indonesia", nilai: 90 },
  { nama: "Fisika", nilai: 75 },
  { nama: "Kimia", nilai: 85 },
  { nama: "Biologi", nilai: 95 },
  { nama: "Matematika Perminatan", nilai: 95 },
  { nama: "Seni Budaya", nilai: 50 },
];

// Fungsi untuk mendapatkan warna berdasarkan nilai
const getBarColor = (nilai: number): string => {
  if (nilai < 60) {
    return "bg-gradient-to-r from-red-500 to-red-700"; // Merah
  } else if (nilai < 80) {
    return "bg-gradient-to-r from-green-400 to-green-600"; // Hijau
  } else {
    return "bg-gradient-to-r from-blue-400 to-blue-600"; // Biru
  }
};

const DiagramMapel: React.FC = () => {
  // Mendapatkan nilai maksimum untuk skala diagram
  const nilaiMaksimum = Math.max(...dataMapel.map((mapel) => mapel.nilai));

  // Membagi data ke dalam dua bagian
  const leftColumnData = dataMapel.filter((_, index) => index % 2 === 0); // Index genap
  const rightColumnData = dataMapel.filter((_, index) => index % 2 !== 0); // Index ganjil

  return (
    <div className="p-6 w-full mx-auto">
      <h2 className="text-2xl font-bold text-center mb-4">
        Diagram Nilai Siswa
      </h2>

      <div className="flex justify-between space-x-4">
        {/* Kolom kiri */}
        <div className="w-1/2 space-y-4">
          {leftColumnData.map((mapel, index) => (
            <div key={index}>
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-gray-700">
                  {mapel.nama}
                </span>
                <span className="text-sm font-medium text-gray-700">
                  {mapel.nilai}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-4">
                <div
                  className={`h-4 rounded-full ${getBarColor(mapel.nilai)}`}
                  style={{
                    width: `${(mapel.nilai / nilaiMaksimum) * 100}%`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>

        {/* Kolom kanan */}
        <div className="w-1/2 space-y-4">
          {rightColumnData.map((mapel, index) => (
            <div key={index}>
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-gray-700">
                  {mapel.nama}
                </span>
                <span className="text-sm font-medium text-gray-700">
                  {mapel.nilai}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-4">
                <div
                  className={`h-4 rounded-full ${getBarColor(mapel.nilai)}`}
                  style={{
                    width: `${(mapel.nilai / nilaiMaksimum) * 100}%`,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiagramMapel;
