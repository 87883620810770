import React, { Suspense } from "react";
import AbsensiTeacher from "../Pages/teacher/SchedulTeacherPage/absensiTeacher";
import SchedulTeacherPage from "../Pages/teacher/SchedulTeacherPage";
import TeacherMaterialRoom from "../Pages/teacher/SchedulTeacherPage/teacherMaterialRoom";
import TeacherWorkRoom from "../Pages/teacher/SchedulTeacherPage/teacherWorkRoom";
import EssayTeacher from "../Pages/teacher/essayTeacher";
import DailyValue from "../Pages/teacher/ValueStudent/dailyValue";
import ErrorPage from "../Components/pageError";
import DashboardTeacher from "../Pages/teacher/dashboardTeacher";
import Consolidation from "../Pages/student/consolidation";
import LoadingUjian from "../Components/loading/LoadingUjian";
import DetailArtikel from "../Pages/student/consolidation/ConsolidationArtikel/detailArtikel";
import AcademicCalendar from "../Pages/academicCalendar";
import DetailDataCalender from "../Pages/academicCalendar/CalendarSchool/detailDataCalender";
import AddEssay from "../Pages/teacher/essayTeacher/AddEssay";
import WaliKelas from "../Pages/teacher/waliKelas";
import DetailKinerjaStudent from "../Pages/School/dataKinerja/kinerjaStudent/detailKinerjaStudent";
import PerpustakaanTeacher from "../Pages/teacher/PerpustakaanTeacher";
import AddBuku from "../Pages/teacher/PerpustakaanTeacher/daftarBuku/addBuku";
import ListBooks from "../Pages/ListBooks";
import StatusBorrow from "../Pages/ListBooks/statusBorrow";
import DetailPinjam from "../Pages/teacher/PerpustakaanTeacher/peminjaman/detailPinjam";

const teacherRoutes = [
  {
    path: "/guru",
    errorElement: (
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <DashboardTeacher />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "absensi",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AbsensiTeacher />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "jadwal-mengajar",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SchedulTeacherPage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "ruang-materi",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TeacherMaterialRoom />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "ruang-tugas",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TeacherWorkRoom />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "materi-ujian",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <EssayTeacher />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "materi-ujian/tambah",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AddEssay />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "nilai-harian",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <DailyValue />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "konseling",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <Consolidation />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "konseling/detail-artikel",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailArtikel />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "akademik/kalender",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AcademicCalendar />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "akademik/kalender/detail",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailDataCalender />
          </Suspense>
        ),
      },
      {
        path: "wali-kelas",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <WaliKelas />
          </Suspense>
        ),
      },
      {
        path: "wali-kelas/detail",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailKinerjaStudent />
          </Suspense>
        ),
      },
      {
        path: "staff/perpustakaan",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <PerpustakaanTeacher />
          </Suspense>
        ),
      },
      {
        path: "staff/perpustakaan/tambah",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <AddBuku />
          </Suspense>
        ),
      },
      {
        path: "staff/perpustakaan/detail-pinjam",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <DetailPinjam />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<LoadingUjian />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "buku",
        children: [
          {
            path: "list",
            element: (
              <Suspense fallback={<LoadingUjian />}>
                <ListBooks />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<LoadingUjian />}>
                <ErrorPage />
              </Suspense>
            ),
          },
          {
            path: "status",
            element: (
              <Suspense fallback={<LoadingUjian />}>
                <StatusBorrow />
              </Suspense>
            ),
            errorElement: (
              <Suspense fallback={<LoadingUjian />}>
                <ErrorPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

export default teacherRoutes;
