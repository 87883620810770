export const IconUser = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 18L14 18M17 15V21M7.68213 14C8.63244 14.6318 9.77319 15 10.9999 15C11.7012 15 12.3744 14.8797 13 14.6586M10.5 21H5.6C5.03995 21 4.75992 21 4.54601 20.891C4.35785 20.7951 4.20487 20.6422 4.10899 20.454C4 20.2401 4 19.9601 4 19.4V17C4 15.3431 5.34315 14 7 14H7.5M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconSearch = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    fill="none"
    stroke="gray"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  >
    <path d="m11.25 11.25 3 3" />
    <circle cx="7.5" cy="7.5" r="4.75" />
  </svg>
);
export const IconLike = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            d="M160,224H53.333C23.936,224,0,247.936,0,277.333V448c0,29.397,23.936,53.333,53.333,53.333h64
				c29.397,0,53.333-23.936,53.333-53.333V234.667C170.667,228.779,165.888,224,160,224z"
          />
          <path
            d="M512,304c0-12.821-5.099-24.768-13.867-33.6c9.963-10.901,15.04-25.536,13.632-40.725
				c-2.496-27.115-26.944-48.341-55.637-48.341H324.373c6.507-19.819,16.96-56.149,16.96-85.333c0-46.272-39.317-85.333-64-85.333
				c-22.165,0-37.995,12.459-38.677,12.992c-2.539,2.027-3.989,5.099-3.989,8.341v72.32l-47.957,103.893
				c-1.472,3.221-1.28,6.955,0.555,9.984c3.136,5.184,4.736,10.731,4.736,16.448v213.333c0,5.141-0.597,10.368-1.835,16.021
				c-1.259,5.739,2.347,11.413,8.064,12.693c9.365,2.112,18.539,3.285,25.771,3.285h195.819c23.232,0,43.563-15.659,48.32-37.269
				c2.453-11.115,1.003-22.336-3.84-32.043c15.765-7.936,26.368-24.192,26.368-42.688c0-7.531-1.728-14.784-5.013-21.333
				C501.397,338.731,512,322.496,512,304z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const IconChat = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6288 20.4718L13.0867 21.3877C12.6035 22.204 11.3965 22.204 10.9133 21.3877L10.3712 20.4718C9.95073 19.7614 9.74049 19.4063 9.40279 19.2098C9.06509 19.0134 8.63992 19.0061 7.78958 18.9915C6.53422 18.9698 5.74689 18.8929 5.08658 18.6194C3.86144 18.1119 2.88807 17.1386 2.3806 15.9134C2 14.9946 2 13.8297 2 11.5V10.5C2 7.22657 2 5.58985 2.7368 4.38751C3.14908 3.71473 3.71473 3.14908 4.38751 2.7368C5.58985 2 7.22657 2 10.5 2H13.5C16.7734 2 18.4101 2 19.6125 2.7368C20.2853 3.14908 20.8509 3.71473 21.2632 4.38751C22 5.58985 22 7.22657 22 10.5V11.5C22 13.8297 22 14.9946 21.6194 15.9134C21.1119 17.1386 20.1386 18.1119 18.9134 18.6194C18.2531 18.8929 17.4658 18.9698 16.2104 18.9915C15.36 19.0061 14.9349 19.0134 14.5972 19.2098C14.2595 19.4062 14.0492 19.7614 13.6288 20.4718ZM8 11.75C7.58579 11.75 7.25 12.0858 7.25 12.5C7.25 12.9142 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 12.9142 14.25 12.5C14.25 12.0858 13.9142 11.75 13.5 11.75H8ZM7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"
      fill={fill}
    />
  </svg>
);
export const IconRefress = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="-7 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>loading</title>
    <path d="M2.080 14.040l4-1.040c0.44-0.12 0.72-0.56 0.6-1.040-0.12-0.44-0.56-0.72-1.040-0.6l-2.080 0.56c0.68-0.88 1.56-1.6 2.64-2.080 1.64-0.72 3.44-0.76 5.12-0.12 1.64 0.64 2.96 1.92 3.68 3.52 0.2 0.44 0.68 0.6 1.12 0.44 0.44-0.2 0.6-0.68 0.44-1.12-0.88-2.040-2.52-3.6-4.6-4.44-2.080-0.8-4.36-0.76-6.4 0.12-1.36 0.6-2.48 1.52-3.36 2.68l-0.52-1.96c-0.12-0.44-0.56-0.72-1.040-0.6-0.44 0.12-0.72 0.56-0.6 1.040l1.040 4c0.12 0.56 0.4 0.8 1 0.64zM17.72 22.52l-1.040-3.96c0 0-0.16-0.8-0.96-0.6v0l-4 1.040c-0.44 0.12-0.72 0.56-0.6 1.040 0.12 0.44 0.56 0.72 1.040 0.6l2.080-0.56c-1.76 2.32-4.88 3.28-7.72 2.16-1.64-0.64-2.96-1.92-3.68-3.52-0.2-0.44-0.68-0.6-1.12-0.44-0.44 0.2-0.6 0.68-0.44 1.12 0.88 2.040 2.52 3.6 4.6 4.44 1 0.4 2 0.56 3.040 0.56 2.64 0 5.12-1.24 6.72-3.4l0.52 1.96c0.080 0.36 0.44 0.64 0.8 0.64 0.080 0 0.16 0 0.2-0.040 0.4-0.16 0.68-0.6 0.56-1.040z"></path>
  </svg>
);
export const IconAdd = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 14H12M12 14H14M12 14V16M12 14V12"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2 6.94975C2 6.06722 2 5.62595 2.06935 5.25839C2.37464 3.64031 3.64031 2.37464 5.25839 2.06935C5.62595 2 6.06722 2 6.94975 2C7.33642 2 7.52976 2 7.71557 2.01738C8.51665 2.09229 9.27652 2.40704 9.89594 2.92051C10.0396 3.03961 10.1763 3.17633 10.4497 3.44975L11 4C11.8158 4.81578 12.2237 5.22367 12.7121 5.49543C12.9804 5.64471 13.2651 5.7626 13.5604 5.84678C14.0979 6 14.6747 6 15.8284 6H16.2021C18.8345 6 20.1506 6 21.0062 6.76946C21.0849 6.84024 21.1598 6.91514 21.2305 6.99383C22 7.84935 22 9.16554 22 11.7979V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V6.94975Z"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
);

export const IconEmoji = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>emoji_happy_circle [#550]</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Dribbble-Light-Preview"
        transform="translate(-380.000000, -5759.000000)"
        fill={fill}
      >
        <g id="icons" transform="translate(56.000000, 160.000000)">
          <path
            d="M340,5607 C340,5608.105 339.105,5609 338,5609 C336.895,5609 336,5608.105 336,5607 C336,5605.895 336.895,5605 338,5605 C339.105,5605 340,5605.895 340,5607 M330,5609 C328.895,5609 328,5608.105 328,5607 C328,5605.895 328.895,5605 330,5605 C331.105,5605 332,5605.895 332,5607 C332,5608.105 331.105,5609 330,5609 M338.904,5611.453 C337.942,5616.931 330.058,5616.827 329.096,5611.349 C328.991,5610.751 329.482,5610.415 330.09,5610.415 L337.91,5610.415 C338.518,5610.415 339.009,5610.855 338.904,5611.453 M334,5617 C329.589,5617 326,5613.411 326,5609 C326,5604.589 329.589,5601 334,5601 C338.411,5601 342,5604.589 342,5609 C342,5613.411 338.411,5617 334,5617 M334,5599 C328.477,5599 324,5603.477 324,5609 C324,5614.523 328.477,5619 334,5619 C339.523,5619 344,5614.523 344,5609 C344,5603.477 339.523,5599 334,5599"
            id="emoji_happy_circle-[#550]"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const IconStudent = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <style type="text/css">{`.st0 { fill: fill={fill}; }`}</style>
    <g>
      <path
        className="st0"
        d="M65.84,98.563l80.867,37.22v48.028h218.582v-48.028l33.424-15.38v85.07c-5.423,0.776-9.606,5.458-9.606,11.087
        c0,4.899,3.166,9.146,7.717,10.621l-8.216,54.239c-0.252,1.646,0.225,3.315,1.318,4.581c1.089,1.258,2.665,1.98,4.319,1.98h29.963
        c1.654,0,3.23-0.722,4.328-1.988c1.081-1.266,1.557-2.927,1.308-4.573l-8.216-54.239c4.552-1.475,7.715-5.722,7.715-10.621
        c0-5.629-4.183-10.31-9.606-11.087v-94.751l26.426-12.158c2.982-1.382,4.909-4.394,4.909-7.671c0-3.284-1.927-6.297-4.91-7.663
        L273.855,3.913C268.213,1.32,262.205,0,255.998,0c-6.207,0-12.213,1.32-17.851,3.913L65.842,83.229
        c-2.985,1.374-4.915,4.379-4.915,7.663C60.927,94.169,62.855,97.181,65.84,98.563z"
      />
      <path
        className="st0"
        d="M410.518,413.569l-77.193-31.537c-12.284-5.644-20.221-18.028-20.221-31.553v-11.366
        c0-5.225,0.862-10.365,2.331-14.852c0.423-0.567,42.372-57.127,48.202-112.88l0.182-1.755H148.175l0.184,1.755
        c5.842,55.753,47.777,112.313,47.972,112.445c1.702,4.93,2.566,10.07,2.566,15.287v11.366c0,13.517-7.941,25.9-20.165,31.522
        L101.43,413.6c-18.408,8.455-31.562,25.396-35.21,45.481L62.127,512h387.748l-4.109-53.082
        C442.119,438.988,428.965,422.047,410.518,413.569z"
      />
    </g>
  </svg>
);

export const IconClassroom = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M467.309,16.768H221.454c-6.128,0-11.095,4.967-11.095,11.095v86.451l12.305-7.64c3.131-1.945,6.475-3.257,9.884-3.978
			V38.958h223.665v160.016H232.549v-25.89l-22.19,13.778v23.208c0,6.128,4.967,11.095,11.095,11.095h245.855
			c6.127,0,11.095-4.967,11.095-11.095V27.863C478.404,21.735,473.436,16.768,467.309,16.768z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M306.001,78.356c-2.919-3.702-8.285-4.335-11.986-1.418l-38.217,30.133c3.649,2.385,6.85,5.58,9.301,9.527
			c0.695,1.117,1.298,2.266,1.834,3.431l37.651-29.687C308.286,87.424,308.92,82.057,306.001,78.356z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="121.535" cy="31.935" r="31.935" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M252.01,124.728c-4.489-7.229-13.987-9.451-21.218-4.963l-31.206,19.375c-0.13-25.879-0.061-12.145-0.144-28.811
			c-0.101-20.005-16.458-36.281-36.464-36.281h-15.159c-12.951,33.588-8.779,21.12-19.772,49.63l4.623-20.131
			c0.32-1.508,0.088-3.08-0.655-4.43l-6.264-11.393l5.559-10.109c0.829-1.508-0.264-3.356-1.985-3.356h-15.271
			c-1.72,0-2.815,1.848-1.985,3.356l5.57,10.13l-6.276,11.414c-0.728,1.325-0.966,2.865-0.672,4.347l4.005,20.172
			c-2.159-5.599-17.084-44.306-19.137-49.63H80.093c-20.005,0-36.363,16.275-36.464,36.281l-0.569,113.2
			c-0.042,8.51,6.821,15.443,15.331,15.486c0.027,0,0.052,0,0.079,0c8.473,0,15.364-6.848,15.406-15.331l0.569-113.2
			c0-0.018,0-0.036,0-0.053c0.024-1.68,1.399-3.026,3.079-3.013c1.68,0.012,3.034,1.378,3.034,3.058l0.007,160.381
			c14.106-0.6,27.176,4.488,36.981,13.423v-62.568h7.983v71.773c5.623,8.268,8.914,18.243,8.914,28.974
			c0,9.777-2.732,18.928-7.469,26.731c4.866,0.023,9.592,0.669,14.099,1.861c6.076-5.271,13.385-9.151,21.437-11.136
			c0-279.342-0.335-106.627-0.335-229.418c0-1.779,1.439-3.221,3.218-3.224c1.779-0.004,3.224,1.432,3.232,3.211
			c0.054,10.807,0.224,44.59,0.283,56.351c0.028,5.579,3.07,10.708,7.953,13.407c4.874,2.694,10.835,2.554,15.583-0.394
			l54.604-33.903C254.276,141.458,256.499,131.957,252.01,124.728z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="429.221" cy="322.831" r="33.803" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M511.459,405.811c-0.107-21.176-17.421-38.404-38.598-38.404c-9.137,0-76.583,0-84.781,0
			c3.637,7.068,5.704,15.069,5.704,23.55c0,9.005-2.405,18.413-7.5,26.782c18.904,0.764,35.468,10.91,45.149,25.897h40.579v-37.43
			c0-1.842,1.46-3.352,3.301-3.415s3.402,1.345,3.526,3.182c0,0,0,0.001,0,0.002l0.19,37.661h32.621L511.459,405.811z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M290.469,390.956c0-8.629,2.138-16.763,5.894-23.92c-22.009,0-47.852,0-75.267,0c3.472,6.939,5.437,14.756,5.437,23.029
			c0,9.721-2.73,18.926-7.469,26.731c15.558,0.074,29.912,6.538,40.283,17.267c10.054-9.822,23.759-15.914,38.836-15.995
			C292.948,409.616,290.469,400.126,290.469,390.956z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M264.819,288.655c-18.668,0-33.804,15.132-33.804,33.803c0,18.628,15.107,33.803,33.804,33.803
			c18.518,0,33.803-14.965,33.803-33.803C298.622,303.808,283.517,288.655,264.819,288.655z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M123.217,390.065c0-8.252,1.956-16.053,5.411-22.98c-1.457-0.072,4.672-0.049-89.485-0.049
			c-21.068,0-38.491,17.138-38.598,38.404l-0.192,38.196c14.907,0,17.906,0,32.621,0l0.191-38.031
			c0.01-1.884,1.541-3.402,3.423-3.397c1.882,0.006,3.404,1.532,3.404,3.414v38.014h45.727c9.855-15.754,26.8-25.646,45.243-26.406
			C125.956,409.168,123.217,399.865,123.217,390.065z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M82.786,288.655c-18.668,0-33.803,15.134-33.803,33.803c0,18.584,15.046,33.803,33.803,33.803
			c18.536,0,33.804-15.015,33.804-33.803C116.59,303.788,101.455,288.655,82.786,288.655z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M422.533,473.807c-0.105-21.178-17.42-38.406-38.597-38.406c-2.246,0-82.969,0-85.507,0
			c-21.176,0-39.601,17.227-39.708,38.404l-0.275-0.891c-0.105-21.092-17.341-38.404-38.597-38.404c-24.544,0-59.795,0-85.507,0
			c-21.176,0-39.601,17.227-39.708,38.404L94.442,512h32.621l0.191-38.922c0.008-1.622,1.327-2.93,2.948-2.926
			c1.621,0.004,2.932,1.32,2.932,2.941v38.908c19.121,0,68.483,0,86.392,0v-38.908c0-1.736,1.405-3.144,3.141-3.149
			c1.735-0.004,3.149,1.397,3.158,3.133l0.191,38.923c6.669,0,58.238,0,65.134,0l0.191-38.031c0,0,0-0.001,0-0.002
			c0.009-1.621,1.328-2.928,2.949-2.924c1.621,0.004,2.931,1.32,2.931,2.941v38.016c19.121,0,68.483,0,86.392,0v-38.016
			c0-1.736,1.405-3.144,3.141-3.149c1.735-0.004,3.149,1.397,3.158,3.133l0.191,38.031h32.621L422.533,473.807z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="175.934" cy="389.933" r="34.198" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="342.07" cy="390.821" r="34.198" />
      </g>
    </g>
  </svg>
);
export const IconDelete = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 11V17"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 11V17"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 7H20"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const IconEdit = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
      fill={fill}
    />
  </svg>
);
export const IconBack = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 472.615 472.615"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M167.158,117.315l-0.001-77.375L0,193.619l167.157,153.679v-68.555c200.338,0.004,299.435,153.932,299.435,153.932
			c3.951-19.967,6.023-40.609,6.023-61.736C472.615,196.295,341.8,117.315,167.158,117.315z"
        />
      </g>
    </g>
  </svg>
);

export const IconSend = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="send-alt"
    className="icon glyph"
  >
    <path
      d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z"
      className="fill:{fill}"
    ></path>
  </svg>
);

export const IconChevron = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 7l6 6 6-6"
    />
  </svg>
);

export const IconExit = (width: string, height: string, fill: string) => (
  <svg
    viewBox="0 0 600 600"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
  >
    <path
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      d="M 130 0 C 58.672 0 0 58.672 0 130 L 0 470 C 0 541.328 58.672 600 130 600 L 301.578 600 C 367.833 600 423.136 549.367 430.672 485 L 349.436 485 C 343.322 505.66 324.704 520 301.578 520 L 130 520 C 101.608 520 80 498.392 80 470 L 80 130 C 80 101.608 101.608 80 130 80 L 301.578 80 C 324.704 80 343.322 94.34 349.436 115 L 430.672 115 C 423.136 50.633 367.833 0 301.578 0 L 130 0 z"
    />
    <path
      style={{ strokeLinecap: "round" }}
      d="M 476.863 180 a 40 40 0 0 0 -28.285 11.715 a 40 40 0 0 0 0 56.57 l 11.715 11.715 H 163.727 a 40 40 0 0 0 -40 40 a 40 40 0 0 0 40 40 h 296.566 l -11.715 11.715 a 40 40 0 0 0 0 56.57 a 40 40 0 0 0 56.57 0 l 72.791 -72.791 a 40 40 0 0 0 0 -56.57 a 40 40 0 0 0 -56.57 0 l -72.38 -72.38 a 40 40 0 0 0 -28.285 -11.715 z"
    />
  </svg>
);

export const IconClose = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Menu / Close_SM">
      <path
        id="Vector"
        d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const IconPlus = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12H18M12 6V18"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconMinus = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12L18 12"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconCam = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {`
          .cls-1 {
            fill: none;
            stroke: ${fill};
            strokeLinecap: round;
            strokeLinejoin: round;
            strokeWidth: 2px;
          }
        `}
      </style>
    </defs>
    <title>camera, cam, device, photography</title>
    <path
      className="cls-1"
      d="M30,27.51a8.86,8.86,0,0,1,14.64,2c3.17,6.32-2.09,13-8.24,12.81a9.91,9.91,0,0,1-2-.22c-5.27-1.25-7.8-5.42-8.1-10.36a30.13,30.13,0,0,1,1.41-9.87c.58-2.07,1.67-5.3,4.31-5.3h9.65a2.87,2.87,0,0,1,2.65,1.78l1.64,4a8.63,8.63,0,0,1,8.63,8.63V39A8.63,8.63,0,0,1,46,47.64H20.61A8.63,8.63,0,0,1,12,39V31.12a8.63,8.63,0,0,1,8.63-8.63h0a3.33,3.33,0,0,0,3.33-3.33V19H17V20.3"
    />
    <path className="cls-1" d="M36.4,29.3a4.09,4.09,0,1,0,4.09,4.09" />
    <circle cx="39.49" cy="30.55" r="1" />
  </svg>
);

export const IconNotif = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>{`.cls-1{fill:${fill};}`}</style>
    </defs>
    <title>Notification Icon</title>
    <g data-name="Layer 10" id="Layer_10">
      <path
        className="cls-1"
        d="M8.5,5V28.24L16,21.51l7.5,6.73V5Zm13,18.76L16,18.82l-5.5,4.94V7h11Z"
      />
    </g>
  </svg>
);

export const IconBook = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 209.811 209.811"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M209.766,181.682L191.382,26.599c-0.388-3.271-3.161-5.735-6.455-5.735h-43.671c-0.062-0.001-0.124-0.001-0.184,0H6.5
		c-3.59,0-6.5,2.91-6.5,6.5v155.083c0,3.59,2.91,6.5,6.5,6.5h133.542c3.59,0,6.5-2.91,6.5-6.5V128.02l6.542,55.192
		c0.388,3.271,3.161,5.735,6.455,5.735h43.771c1.857,0,3.625-0.794,4.859-2.183C209.404,185.376,209.984,183.526,209.766,181.682z
		 M193.056,151.153h-30.681l-10.964-92.495h30.014c0.226,0,0.443-0.031,0.659-0.066L193.056,151.153z M133.542,151.153h-30.771
		V58.658h30.771V151.153z M57.885,151.153V58.658h31.886v92.495H57.885z M89.771,159.153v16.794H57.885v-16.794H89.771z M13,58.658
		h31.885v92.495H13V58.658z M57.885,50.658V33.864h31.886v16.794H57.885z M179.153,33.864l1.991,16.794h-30.68l-1.991-16.794
		H179.153z M133.542,50.658h-30.771V33.864h30.771V50.658z M44.885,33.864v16.794H13V33.864H44.885z M13,159.153h31.885v16.794H13
		V159.153z M102.771,175.947v-16.794h30.771v16.794H102.771z M165.314,175.947l-1.991-16.794h30.681l1.991,16.794H165.314z"
      />
    </g>
  </svg>
);
export const IconUpload = (width: string, height: string, fill: string) => (
  <svg
    width={width}
    height={height}
    viewBox="0 -2 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>upload</title>
    <g
      id="Icon-Set-Filled"
      transform="translate(-571.000000, -676.000000)"
      fill={fill}
    >
      <path
        d="M599,692 C597.896,692 597,692.896 597,694 L597,698 L575,698 L575,694 C575,692.896 574.104,692 573,692 C571.896,692 571,692.896 571,694 L571,701 C571,701.479 571.521,702 572,702 L600,702 C600.604,702 601,701.542 601,701 L601,694 C601,692.896 600.104,692 599,692 Z M582,684 L584,684 L584,693 C584,694.104 584.896,695 586,695 C587.104,695 588,694.104 588,693 L588,684 L590,684 C590.704,684 591.326,684.095 591.719,683.7 C592.11,683.307 592.11,682.668 591.719,682.274 L586.776,676.283 C586.566,676.073 586.289,675.983 586.016,675.998 C585.742,675.983 585.465,676.073 585.256,676.283 L580.313,682.274 C579.921,682.668 579.921,683.307 580.313,683.7 C580.705,684.095 581.608,684 582,684 Z"
        id="upload"
      />
    </g>
  </svg>
);

export const IconInput = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
  >
    <g>
      <path d="M20,5h9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9c-0.6,0-1,0.4-1,1S19.4,5,20,5z" />
      <path d="M29,9h-9c-0.6,0-1,0.4-1,1s0.4,1,1,1h9c0.6,0,1-0.4,1-1S29.6,9,29,9z" />
      <path d="M29,15h-9c-0.6,0-1,0.4-1,1s0.4,1,1,1h9c0.6,0,1-0.4,1-1S29.6,15,29,15z" />
      <path d="M29,21H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h26c0.6,0,1-0.4,1-1S29.6,21,29,21z" />
      <path d="M29,27H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h26c0.6,0,1-0.4,1-1S29.6,27,29,27z" />
      <path
        d="M3,11h9.6l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l4-4c0.1-0.1,0.2-0.2,0.2-0.3
		c0.1-0.2,0.1-0.5,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.3l-4-4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L12.6,9H3c-0.6,0-1,0.4-1,1S2.4,11,3,11z"
      />
    </g>
  </svg>
);
export const IconCustomer = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="19 costumer service" id="_19_costumer_service">
      <path d="M37.71,34.54a13.428,13.428,0,0,0,5.51-4.86H46.7a2.915,2.915,0,0,0,2.74-3.06V19.43a3.124,3.124,0,0,0-.2-1.11V18.3a25.246,25.246,0,0,0-5.2-9.54l-.13-.15a15.275,15.275,0,0,0-5.72-3.97,17.135,17.135,0,0,0-14.11.8,15.539,15.539,0,0,0-3.99,3.17l-.13.15a25.246,25.246,0,0,0-5.2,9.54v.02a3.124,3.124,0,0,0-.2,1.11v7.19a2.915,2.915,0,0,0,2.74,3.06h3.48a13.428,13.428,0,0,0,5.51,4.86,20.226,20.226,0,0,0-15.02,19.5V59.5a1,1,0,0,0,1,1H51.73a1,1,0,0,0,1-1V54.04A20.226,20.226,0,0,0,37.71,34.54Zm9.73-15.11v7.19c0,.56-.35,1.06-.74,1.06H44.29c.17-.42.33-.85.46-1.29,0-.01,0-.01.01-.02a13.235,13.235,0,0,0-.1-8H46.7C47.09,18.37,47.44,18.87,47.44,19.43ZM17.3,27.68c-.39,0-.74-.5-.74-1.06V19.43c0-.56.35-1.06.74-1.06h2.04a13.261,13.261,0,0,0,.37,9.31Zm.26-11.31a22.694,22.694,0,0,1,3.89-6.28l.12-.13A13.124,13.124,0,0,1,25.05,7.2a15.268,15.268,0,0,1,12.41-.7,13.159,13.159,0,0,1,4.97,3.46l.12.13a22.694,22.694,0,0,1,3.89,6.28H43.82a13.311,13.311,0,0,0-23.64,0Zm3.11,6.14A11.33,11.33,0,1,1,43.05,25H39.71a2.991,2.991,0,0,0-2.82-2h-3a3,3,0,0,0,0,6h3a2.991,2.991,0,0,0,2.82-2H42.4a11.329,11.329,0,0,1-21.73-4.49Zm17.22,3.48v.02a.994.994,0,0,1-1,.99h-3a1,1,0,0,1,0-2h3A.994.994,0,0,1,37.89,25.99ZM50.73,58.5H13.27V54.04a18.228,18.228,0,0,1,18.2-18.2h1.06a18.228,18.228,0,0,1,18.2,18.2Z" />
    </g>
  </svg>
);
export const IconImportData = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
  >
    <path d="M11,20H6a1,1,0,0,1-1-1V5A1,1,0,0,1,6,4h5V7a3,3,0,0,0,3,3h3v2a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.32,1.32,0,0,0-.19-.29h0l-6-6h0a1.32,1.32,0,0,0-.29-.19.32.32,0,0,0-.09,0L12.06,2H6A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3h5a1,1,0,0,0,0-2ZM13,5.41,15.59,8H14a1,1,0,0,1-1-1ZM19,15H13.41l1.3-1.29a1,1,0,0,0-1.42-1.42l-3,3a1.15,1.15,0,0,0-.21.33,1,1,0,0,0,0,.76.93.93,0,0,0,.21.33l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,17H19a1,1,0,0,0,0-2Z" />
  </svg>
);

export const IconEye = () => (
  <svg
    fill="gray"
    width="24px"
    height="24px"
    viewBox="0 -2.96 15.929 15.929"
    id="eye-16px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_112"
      data-name="Path 112"
      d="M-3.768,6.232l-.416-.416A9.609,9.609,0,0,0-11,2.993a9.609,9.609,0,0,0-6.816,2.823l-.416.416a2.5,2.5,0,0,0,0,3.536l.416.416A9.609,9.609,0,0,0-11,13.007a9.609,9.609,0,0,0,6.816-2.823l.416-.416A2.5,2.5,0,0,0-3.768,6.232ZM-11,4a.5.5,0,0,1,.5.5A.5.5,0,0,1-11,5a2,2,0,0,0-2,2,.5.5,0,0,1-.5.5A.5.5,0,0,1-14,7,3,3,0,0,1-11,4Zm6.525,5.061-.416.416A8.581,8.581,0,0,1-11,12.007a8.581,8.581,0,0,1-6.109-2.53l-.416-.416A1.493,1.493,0,0,1-17.964,8a1.493,1.493,0,0,1,.439-1.061l.416-.416A8.624,8.624,0,0,1-14.183,4.6,3.964,3.964,0,0,0-15,7a4,4,0,0,0,4,4A4,4,0,0,0-7,7a3.964,3.964,0,0,0-.817-2.4A8.624,8.624,0,0,1-4.891,6.523l.416.416A1.493,1.493,0,0,1-4.036,8,1.493,1.493,0,0,1-4.475,9.061Z"
      transform="translate(18.965 -2.993)"
    />
  </svg>
);

export const IconEyesClose = () => (
  <svg
    fill="gray"
    width="24px"
    height="24px"
    viewBox="0 0 16 16"
    id="eye-off-16px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path_110"
      data-name="Path 110"
      d="M32.716,11.837l.823.823a9.6,9.6,0,0,1-9.355-2.476l-.416-.416a2.5,2.5,0,0,1,0-3.536l.416-.416A9.653,9.653,0,0,1,25.56,4.681l.717.717a8.76,8.76,0,0,0-1.386,1.125l-.416.416a1.5,1.5,0,0,0,0,2.122l.416.416A8.581,8.581,0,0,0,31,12.007,8.729,8.729,0,0,0,32.716,11.837ZM31,11a4.037,4.037,0,0,0,.8-.08L27.08,6.2A4,4,0,0,0,31,11ZM29.525,4.4A2.958,2.958,0,0,1,31,4a.5.5,0,0,1,0,1,1.976,1.976,0,0,0-.735.144L34.331,9.21A3.956,3.956,0,0,0,34.183,4.6a8.624,8.624,0,0,1,2.926,1.922l.416.416a1.5,1.5,0,0,1,0,2.122l-.416.416A8.7,8.7,0,0,1,35.723,10.6l.717.718a9.653,9.653,0,0,0,1.376-1.135l.416-.416a2.5,2.5,0,0,0,0-3.536l-.416-.416A9.6,9.6,0,0,0,28.461,3.34ZM23.854.146a.5.5,0,0,0-.708.708l15,15a.5.5,0,0,0,.708-.708Z"
      transform="translate(-23 0)"
    />
  </svg>
);

export const IconExport = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.42 12.06L14.3467 13.1333C14.2133 13.2667 14.0467 13.3267 13.8733 13.3267C13.7 13.3267 13.5333 13.26 13.4 13.1333C13.14 12.8733 13.14 12.4533 13.4 12.1933L14.26 11.3333H9.32667C8.96 11.3333 8.66 11.0333 8.66 10.6667C8.66 10.3 8.96 10 9.32667 10H14.26L13.4 9.14C13.14 8.88 13.14 8.46 13.4 8.2C13.66 7.94 14.08 7.94 14.34 8.2L15.4133 9.27333C16.18 10.04 16.18 11.2933 15.4133 12.0667L15.42 12.06ZM8.66667 5.33333H13.0267C12.7933 4.72667 12.44 4.16667 11.9667 3.69333L9.64667 1.36667C9.17333 0.893333 8.61333 0.54 8.00667 0.306667V4.66667C8.00667 5.03333 8.30667 5.33333 8.67333 5.33333H8.66667ZM11.8733 12.6667H9.33333C8.23333 12.6667 7.33333 11.7667 7.33333 10.6667C7.33333 9.56667 8.23333 8.66667 9.33333 8.66667H11.8733C11.8733 8.15333 12.0667 7.64 12.46 7.25333C12.7067 7.00667 13.0067 6.83333 13.32 6.74667V6.66667H8.66667C7.56667 6.66667 6.66667 5.76667 6.66667 4.66667V0.0133333C6.56 0.00666667 6.45333 0 6.34 0H3.33333C1.49333 0 0 1.49333 0 3.33333V12.6667C0 14.5067 1.49333 16 3.33333 16H10C11.2067 16 12.2667 15.3533 12.8533 14.3867C12.7133 14.3 12.58 14.2 12.46 14.08C12.0733 13.6933 11.8733 13.18 11.8733 12.6667Z" />
  </svg>
);

export const IconTeacher = (width: string, height: string, fill: string) => (
  <svg
    fill={fill}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M61.726,152.513l-3.566-0.268c-10.791-0.815-20.195,7.281-21.004,18.07c-0.811,10.79,7.28,20.194,18.07,21.005
			l23.849,1.79h4.485C70.536,184.248,61.923,169.375,61.726,152.513z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M428.462,158.961l-3.284-0.116c-1.583,12.636-8.211,23.721-17.804,31.172c7.218,0.337,15.402,2.349,23.11,7.906
			c9.246-1.287,16.519-9.038,16.862-18.687C447.729,168.422,439.275,159.344,428.462,158.961z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="111.498" cy="151.928" r="39.289" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="247.022" cy="40.608" r="40.608" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M331.799,152.985c0-9.46,2.826-18.268,7.665-25.642l-8.746-13.764c-7.73-12.166-20.951-19.429-35.365-19.429h-15.986
			c-2.963,7.742-21.654,56.571-24.287,63.45l5.726-26.974c0.402-1.894,0.111-3.87-0.822-5.567l-7.863-14.301l6.991-12.714
			c1.041-1.893-0.331-4.217-2.494-4.217h-19.188c-2.16,0-3.537,2.322-2.494,4.217l6.991,12.714l-7.893,14.355
			c-0.915,1.665-1.215,3.599-0.845,5.462l5.006,27.025c-17.648-45.261-13.101-35.409-24.035-63.45h-19.287
			c-15.445,0-29.584,8.449-36.9,22.051l-4.689,8.717c5.049,7.785,7.994,17.056,7.994,27.005c0,16.69-8.265,31.477-20.907,40.514
			c12.192,0,14.367-0.172,19.403,0.675c1.655-1.486,3.074-3.265,4.166-5.294c14.193-26.487,23.041-41.749,27.41-49.054
			c0.41-0.686,1.229-1.015,2-0.801s1.304,0.915,1.304,1.715v79.18c3.254,6.424,5.116,13.666,5.155,21.33l0.21,41.715l0.348,68.992
			c0.032,6.347-2.103,12.196-5.702,16.86v102.067c0,12.985,10.525,23.51,23.51,23.51c12.985,0,23.51-10.526,23.51-23.51V281.905
			h10.151v187.92c0,12.985,10.525,23.51,23.51,23.51c12.985,0,23.51-10.526,23.51-23.51V365.096
			c-4.499,0.606-8.779,0.085-12.794-1.385c-13.956-5.102-21.129-20.529-16.025-34.487l0.04-0.109
			c7.877-21.559,19.796-54.443,28.353-78.569V141.938c0-0.791,0.522-1.486,1.282-1.707c0.759-0.221,1.573,0.086,1.997,0.753
			l28.715,45.191c1.688,2.656,3.964,4.82,6.597,6.371c4.459-1.611,8.866-2.338,12.964-2.53
			C338.931,181.432,331.799,168.029,331.799,152.985z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M348.346,216.85c-1.14-6.93-2.858-12.26-3.604-14.388c-14.545,3.294-22.831,16.041-27.679,30.149
			c-10.282,29.929-34.731,96.979-36.076,100.61c-2.89,7.903,1.174,16.65,9.076,19.539c7.894,2.887,16.647-1.17,19.537-9.077
			c0.437-1.163,18.516-50.696,30.274-83.984l0.044,0.14C350.548,246.656,350.381,229.228,348.346,216.85z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M476.368,333.223c-9.35-25.621-27.205-74.802-36.076-100.611c-5.897-17.156-15.132-27.23-27.494-30.108
			c-0.753,2.156-2.457,7.461-3.589,14.348c-2.467,15.003-1.178,31.04,8.427,42.988c0.399,2.221,27.977,78.013,30.118,83.846
			c2.889,7.9,11.631,11.966,19.537,9.077C475.193,349.872,479.257,341.125,476.368,333.223z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M439.114,373.136l-18.524-95.649c-20.587-13.87-26.9-36.3-23.607-60.637c0.955-7.056,2.543-12.552,3.418-15.234
			c-17.774,0-25.915,0-43.248,0c0.875,2.681,2.463,8.179,3.417,15.234c3.298,24.353-3.015,46.91-23.838,60.801v-0.001
			l-10.597,54.709l-7.898,40.777c-1.015,5.24,3.024,10.082,8.308,10.082c3.276,0,7.223,0,11.624,0v110.501
			c0,10.096,8.184,18.28,18.28,18.28s18.28-8.184,18.28-18.28V383.218c2.628,0,5.265,0,7.893,0v110.501
			c0,10.096,8.184,18.28,18.28,18.28s18.28-8.184,18.28-18.28V383.218c4.4,0,8.347,0,11.624,0
			C436.117,383.218,440.128,378.37,439.114,373.136z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle cx="378.679" cy="152.989" r="36.385" />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M75.755,204.77h-4.836c-19.565,0-35.563,15.917-35.66,35.482c-0.066,13.081-0.466,92.665-0.557,110.706
			c-0.042,8.322,6.67,15.102,14.992,15.145c0.026,0,0.053,0,0.077,0c8.287,0,15.026-6.697,15.068-14.993
			c0.125-24.869,0.444-88.18,0.556-110.706c0.009-1.606,1.314-2.9,2.918-2.895c1.604,0.005,2.901,1.306,2.901,2.911v19.874
			c5.88-7.71,8.234-16.762,8.76-25.385C80.779,221.67,77.309,209.466,75.755,204.77z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M151.032,276.04c-12.883-10.198-19.373-24.218-20.478-41.132c-0.678-10.374,0.659-20.75,3.435-30.139h-22.558h-0.128
			H88.397c2.769,9.369,4.114,19.745,3.435,30.139c-1.108,16.976-7.64,31.039-20.618,41.246c0,0,0.007,216.107,0.007,217.763
			c0,9.987,8.096,18.083,18.083,18.083c9.987,0,18.082-8.097,18.082-18.084V349.378c0-0.067-0.009-0.131-0.01-0.197h7.829
			c-0.001,0.066-0.01,0.131-0.01,0.197v144.538c0,9.987,8.096,18.083,18.083,18.083c9.987,0,18.083-8.096,18.083-18.083
			C151.359,344.93,151.032,276.04,151.032,276.04z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M187.476,240.252c-0.098-19.565-16.095-35.482-35.659-35.482h-5.186c-1.554,4.696-5.024,16.9-4.218,30.139
			c0.521,8.554,2.844,17.529,8.62,25.2v-19.687c0-1.739,1.407-3.149,3.145-3.153c1.739-0.004,3.153,1.398,3.162,3.137
			c0.114,22.699,0.435,86.402,0.556,110.706c0.041,8.297,6.78,14.993,15.068,14.993c0.025,0,0.051,0,0.077,0
			c8.322-0.042,15.034-6.823,14.992-15.145C187.943,333.135,187.543,253.477,187.476,240.252z"
        />
      </g>
    </g>
  </svg>
);
