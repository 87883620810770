import React, { useEffect, useState } from "react";
import GrafikSiswa from "./grafikSiswa";
import StudentSmart from "./studentSmart";
import GrafikTeacher from "./grafikTeacher.tsx";
import TeacherSmart from "./teacherSmart";

interface SchoolData {
  teachers: number;
  students: number;
  parents: number;
  homeroomTeachers: number;
  extracurricularCoaches: number;
  libraryStaff: number;
}

const DashboardSchool: React.FC = () => {
  // Inisialisasi state dengan TypeScript menggunakan tipe SchoolData
  const [totalData, setTotalData] = useState<SchoolData>({
    teachers: 0,
    students: 0,
    parents: 0,
    homeroomTeachers: 0,
    extracurricularCoaches: 0,
    libraryStaff: 0,
  });

  // Nilai final untuk setiap kategori
  const finalData: SchoolData = {
    teachers: 30,
    students: 500,
    parents: 350,
    homeroomTeachers: 15,
    extracurricularCoaches: 10,
    libraryStaff: 5,
  };

  const duration = 3000; // 3 detik

  useEffect(() => {
    const intervals = 80;
    const incrementPerInterval = (key: keyof SchoolData) => {
      return Math.ceil(finalData[key] / (duration / intervals));
    };

    const interval = setInterval(() => {
      setTotalData((prevData) => {
        let newData: SchoolData = { ...prevData };
        let isCompleted = true;

        (Object.keys(finalData) as Array<keyof SchoolData>).forEach((key) => {
          if (prevData[key] < finalData[key]) {
            newData[key] += incrementPerInterval(key);
            if (newData[key] > finalData[key]) {
              newData[key] = finalData[key];
            }
            isCompleted = false;
          }
        });

        if (isCompleted) clearInterval(interval);
        return newData;
      });
    }, duration / intervals);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full pb-[200px]">
      <div className="lg:mt-[-12px] fixed w-full bg-white rounded-lg border-b shadow-b-lg p-[10px]">
        <h1 className="lg:text-4xl text-[20px] font-bold text-gray-800">
          Dashboard Sekolah
        </h1>
      </div>

      <div className="grid pt-[60px] px-6  grid-cols-2 lg:grid-cols-4 gap-2">
        {/* Card Total Teachers */}
        <div className="bg-white p-2 rounded-lg shadow-lg ">
          <div className="text-center">
            <p className="text-[40px] font-extrabold text-gray-800  ">
              {totalData.teachers}
            </p>
            <h2 className="text-[20px] font-bold text-blue-600">Total Guru</h2>
          </div>
        </div>

        {/* Card Total Students */}
        <div className="bg-white p-2 rounded-lg shadow-lg ">
          <div className="text-center">
            <p className="text-[40px] font-extrabold text-gray-800 ">
              {totalData.students}
            </p>
            <h2 className="text-[20px] font-bold text-green-600">
              Total Siswa
            </h2>
          </div>
        </div>

        {/* Card Total Parents */}
        <div className="bg-white p-2 rounded-lg shadow-lg ">
          <div className="text-center">
            <p className="text-[40px]  font-extrabold text-gray-800">
              {totalData.parents}
            </p>
            <h2 className="text-[20px] font-bold text-pink-600">
              Total Wali Murid
            </h2>
          </div>
        </div>

        {/* Card Total Homeroom Teachers */}
        <div className="bg-white p-2 rounded-lg shadow-lg ">
          <div className="text-center">
            <p className="text-[40px]  font-extrabold text-gray-800 ">
              {totalData.homeroomTeachers}
            </p>
            <h2 className="text-[20px] font-bold text-purple-600">
              Total Wali Kelas
            </h2>
          </div>
        </div>

        {/* Card Total Extracurricular Coaches */}
        <div className="bg-white p-2 rounded-lg shadow-lg ">
          <div className="text-center">
            <p className="text-[40px]  font-extrabold text-gray-800  ">
              {totalData.extracurricularCoaches}
            </p>
            <h2 className="text-[20px] font-bold text-yellow-600">
              Total Pembina Ekstrakulikuler
            </h2>
          </div>
        </div>

        {/* Card Total Library Staff */}
        <div className="bg-white p-2 rounded-lg shadow-lg ">
          <div className="text-center">
            <p className="text-[40px]  font-extrabold text-gray-800  ">
              {totalData.libraryStaff}
            </p>
            <h2 className="text-[20px] font-bold text-red-600">
              Total Staff Perpustakaan
            </h2>
          </div>
        </div>
      </div>
      <div className="px-6 lg:flex gap-2 lg:mt-4 mt-8">
        <div className="w-full">
          <GrafikSiswa />
        </div>

        <div className="w-full mt-8 lg:mt-0">
          <StudentSmart />
        </div>
      </div>
      <div className="px-6 lg:flex gap-2 lg:mt-4 mt-8">
        <div className="w-full">
          <GrafikTeacher />
        </div>

        <div className="w-full mt-8 lg:mt-0">
          <TeacherSmart />
        </div>
      </div>
    </div>
  );
};

export default DashboardSchool;
