import React, { useState } from "react";
import BorrowBooks from "./borrowBooks.tsx";
import { IconSearch } from "../../Assets/Icons/index.js";
import { IconSearch2 } from "./iconBook/index.js";

// Interface Book
export interface Book {
  id: number;
  title: string;
  author: string;
  year: number;
  isBestSeller: boolean;
  isNewRelease: boolean;
  image: string; // URL gambar buku
}

// Data Dummy Buku
const books: Book[] = [
  {
    id: 1,
    title: "Matematika Perminatan",
    author: "Author One",
    year: 2021,
    isBestSeller: true,
    isNewRelease: false,
    image: "https://picsum.photos/200/300?random=1",
  },
  {
    id: 2,
    title: "Book Two",
    author: "Author Two",
    year: 2023,
    isBestSeller: false,
    isNewRelease: true,
    image: "https://picsum.photos/200/300?random=2",
  },
  {
    id: 3,
    title: "Book Three",
    author: "Author Three",
    year: 2022,
    isBestSeller: true,
    isNewRelease: false,
    image: "https://picsum.photos/200/300?random=3",
  },
  {
    id: 4,
    title: "Book Four",
    author: "Author Four",
    year: 2023,
    isBestSeller: false,
    isNewRelease: true,
    image: "https://picsum.photos/200/300?random=4",
  },
  {
    id: 5,
    title: "Book Five",
    author: "Author Five",
    year: 2020,
    isBestSeller: true,
    isNewRelease: false,
    image: "https://picsum.photos/200/300?random=5",
  },
];

const ListBooks: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<
    "all" | "bestSeller" | "newRelease"
  >("all");

  const [searchQuery, setSearchQuery] = useState(""); // State untuk pencarian
  const [showBorrow, setShowBorrow] = useState(false);

  const HandleOpenBorrow = () => {
    setShowBorrow(true);
  };

  const handleCloseBorrow = () => {
    setShowBorrow(false);
  };

  // Fungsi untuk memfilter buku berdasarkan kategori dan pencarian
  const filterBooks = (
    filter: "all" | "bestSeller" | "newRelease",
    searchQuery: string
  ) => {
    let filteredBooks = books;

    // Filter berdasarkan kategori
    if (filter === "bestSeller") {
      filteredBooks = books.filter((book) => book.isBestSeller);
    } else if (filter === "newRelease") {
      filteredBooks = books.filter((book) => book.isNewRelease);
    }

    // Filter berdasarkan pencarian (judul atau penulis)
    if (searchQuery) {
      filteredBooks = filteredBooks.filter(
        (book) =>
          book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          book.author.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return filteredBooks;
  };

  // Memfilter buku berdasarkan kategori dan pencarian
  const displayedBooks = filterBooks(selectedCategory, searchQuery);

  return (
    <div className="mx-auto relative font-inter lg:w-full px-4">
      {/* Filter and Search Bar */}
      <div className="sticky top-0 w-full bg-white z-[30] border-b lg:mt-[-12px] shadow-sm">
        <div className="p-4">
          <h1 className="text-3xl font-bold">Daftar Buku</h1>
        </div>
        {/* Filter Options */}
        <div className="max-w-[1200px] mx-auto flex flex-col lg:flex-row justify-center lg:justify-start lg:gap-4 pb-[10px] space-y-4 lg:space-y-0 lg:space-x-4 px-4 lg:px-0">
          <div className="flex gap-2 mb-2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Handle input pencarian
              placeholder="Cari buku..."
              className="border border-slate-300 py-2 rounded-lg outline-none px-2 w-full sm:w-[400px]"
            />
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => setSelectedCategory("all")}
              className={`px-6 py-2 rounded-full border transition-colors duration-300 
                ${
                  selectedCategory === "all"
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-blue-500 border-blue-500"
                }`}
            >
              Semua
            </button>
            <button
              onClick={() => setSelectedCategory("bestSeller")}
              className={`px-6 py-2 rounded-full border transition-colors duration-300 
                ${
                  selectedCategory === "bestSeller"
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-blue-500 border-blue-500"
                }`}
            >
              Terlaris
            </button>
            <button
              onClick={() => setSelectedCategory("newRelease")}
              className={`px-6 py-2 rounded-full border transition-colors duration-300 
                ${
                  selectedCategory === "newRelease"
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-blue-500 border-blue-500"
                }`}
            >
              Terbaru
            </button>
          </div>
        </div>
      </div>

      {/* Book List */}
      <div className="max-w-[1200px] mx-auto grid grid-cols-1 gap-4 lg:pt-[140px] sm:grid-cols-2 lg:grid-cols-4">
        {displayedBooks.map((book) => (
          <div
            key={book.id}
            className="bg-white rounded-lg shadow-md overflow-hidden "
          >
            <div className="h-[150px] lg:h-[200px]">
              <img
                src={book.image}
                alt={book.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              <h2 className="text-sm font-semibold mb-2 h-[40px] text-gray-800">
                {book.title}
              </h2>
              <p className="text-sm text-gray-600">Penulis: {book.author}</p>
              <p className="text-sm text-gray-600">Tahun: {book.year}</p>
              <div className="mt-2 flex justify-between">
                {book.isBestSeller && (
                  <span className="px-2 py-1 bg-green-200 text-green-800 text-xs rounded-full">
                    Terlaris
                  </span>
                )}
                {book.isNewRelease && (
                  <span className="px-2 py-1 bg-red-200 text-red-800 text-xs rounded-full">
                    Terbaru
                  </span>
                )}
              </div>
              <button
                onClick={HandleOpenBorrow}
                className="w-full mt-4 text-white bg-blue-600 py-2 rounded-full text-sm font-semibold"
              >
                Pinjam
              </button>
            </div>
          </div>
        ))}
      </div>

      {showBorrow && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[50] flex items-center justify-center">
          {/* Perbaiki properti dari onClose ke onclose */}
          <BorrowBooks onClose={handleCloseBorrow} />
        </div>
      )}

      {/* No Books Found */}
      {displayedBooks.length === 0 && (
        <div className="text-center mt-8 text-gray-500">
          Tidak ada buku yang ditemukan dalam kategori ini.
        </div>
      )}
    </div>
  );
};

export default ListBooks;
