// EssayTeacher.tsx

import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import { IconAdd, IconDelete, IconEdit } from "../../../Assets/Icons";
import { Link } from "react-router-dom";

interface Exam {
  code: string;
  subject: string;
  className: string;
  date: string;
  startTime: string;
  endTime: string;
}

const initialExams: Exam[] = [
  {
    code: "E001",
    subject: "Matematika",
    className: "10A",
    date: "2024-09-20",
    startTime: "09:00",
    endTime: "11:00",
  },
  // Tambah data ujian lainnya di sini jika diperlukan
];

const EssayTeacher: React.FC = () => {
  const [exams, setExams] = useState<Exam[]>(initialExams);
  const [newExam, setNewExam] = useState<Exam>({
    code: "",
    subject: "",
    className: "",
    date: "",
    startTime: "",
    endTime: "",
  });
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewExam((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddExam = () => {
    setExams([...exams, newExam]);
    setNewExam({
      code: "",
      subject: "",
      className: "",
      date: "",
      startTime: "",
      endTime: "",
    });
    setShowForm(false);
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Daftar Ujian</h1>

      <div className="">
        <div className="flex justify-end mb-2">
          <Link to={"tambah"}>
            <button
              className="mt-6 bg-blue-500 flex gap-2 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => setShowForm(!showForm)}
            >
              {IconAdd("20", "20", "white")}
              <p className="hidden lg:block">Tambah Ujian</p>
            </button>
          </Link>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full lg:w-full w-[700px] bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="bg-gray-100 text-center text-gray-600 border-b border-gray-200">
              <tr>
                <th className="py-3 px-4 ">Kode Ujian</th>
                <th className="py-3 px-4 ">Nama Mapel</th>
                <th className="py-3 px-4 ">Kelas</th>
                <th className="py-3 px-4 ">Tanggal</th>
                <th className="py-3 px-4 ">Jam Mulai</th>

                <th className="py-3 px-4 ">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {exams.map((exam, index) => (
                <tr
                  key={index}
                  className="border-b text-center border-gray-200"
                >
                  <td className="py-3 px-4">{exam.code}</td>
                  <td className="py-3 px-4">{exam.subject}</td>
                  <td className="py-3 px-4">{exam.className}</td>
                  <td className="py-3 px-4">{exam.date}</td>
                  <td className="py-3 px-4">
                    {exam.startTime} s/d {exam.endTime}
                  </td>

                  <td className="py-3 px-4 flex justify-center flex-row gap-4">
                    <button>{IconEdit("24", "24", "blue")}</button>
                    <button>{IconDelete("24", "24", "blue")}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EssayTeacher;
