import React, { useState } from "react";
import { IconChat, IconLike, IconNotif } from "../../Assets/Icons";
import SlidePost from "./slidePost";
import DetailPost from "./detailPost";
import GoogleAdsense from "../googleAdsense";
const posts = [
  {
    id: 1,
    schoolName: "SMAN 1 Pedes Karawang",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
    timeAgo: "2 jam lalu",
    comment: 20,
    likes: 30,
    image: "https://picsum.photos/200/300?random=1",
  },
  {
    id: 2,
    schoolName: "SMAN 1 Pedes Karawang",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
    timeAgo: "2 jam lalu",
    image: "https://picsum.photos/200/300?random=2",
    comment: 20,
    likes: 30,
  },
  {
    id: 3,
    schoolName: "SMAN 1 Pedes Karawang",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
    timeAgo: "2 jam lalu",
    image: "https://picsum.photos/200/300?random=3",
    comment: 20,
    likes: 30,
  },
  {
    id: 4,
    schoolName: "SMAN 1 Pedes Karawang",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
    timeAgo: "2 jam lalu",
    image: "https://picsum.photos/200/300?random=8",
    comment: 20,
    likes: 30,
  },
  {
    id: 5,
    schoolName: "SMAN 1 Pedes Karawang",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos ex provident qui, alias laborum rerum voluptas nihil.",
    timeAgo: "2 jam lalu",
    image: "https://picsum.photos/200/300?random=4",
    comment: 20,
    likes: 30,
  },
  // Additional posts can be added here
];
const Post: React.FC = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [likedPosts, setLikedPosts] = useState<number[]>([]);
  const [showFullText, setShowFullText] = useState<number[]>([]);

  const handleLikeClick = (id: number) => {
    if (likedPosts.includes(id)) {
      setLikedPosts(likedPosts.filter((postId) => postId !== id));
    } else {
      setLikedPosts([...likedPosts, id]);
    }
  };

  const toggleFullText = (id: number) => {
    if (showFullText.includes(id)) {
      setShowFullText(showFullText.filter((postId) => postId !== id));
    } else {
      setShowFullText([...showFullText, id]);
    }
  };

  const handleShowDetail = () => {
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setShowDetail(false);
  };
  return (
    <section className="flex  font-roboto flex-col gap-[10px]">
      {posts.map((post, index) => (
        <React.Fragment key={post.id}>
          <div className="px-[15px] py-[10px] bg-white shadow-lg border rounded-lg">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="lg:w-[60px] lg:h-[60px] w-[40px] h-[40px]">
                  <img
                    src={post.image}
                    alt=""
                    className="w-full h-full rounded-full "
                  />
                </div>

                <div className="flex flex-col ">
                  <p className="text-[12px] lg:text-[20px] font-bold font-inter">
                    {post.schoolName}
                  </p>{" "}
                  <div className="flex gap-5 items-center">
                    <p className="text-[12px] lg:text-[16px] font-semibold text-gray-800 font-roboto">
                      Abdul Majid
                    </p>
                    <p className="text-[10px]  text-slate-500">Publik</p>
                  </div>
                </div>
              </div>

              <div className="flex lg:gap-4  items-center">
                <p className="lg:text-[14px] text-[10px] text-slate-500 ">
                  {post.timeAgo}
                </p>
                <button>{IconNotif("30", "30", "blue")}</button>
              </div>
            </div>
            <div className="h-auto">
              <div className="flex justify-center my-2 relative">
                <SlidePost />
              </div>
              <div>
                <p className="text-[12px] font-inter lg:px-[40px]  leading-[17px]">
                  <span className="font-roboto font-semibold ">
                    @Abdul Majid ~
                  </span>
                  {showFullText.includes(post.id) || post.content.length <= 120
                    ? post.content
                    : `${post.content.slice(0, 120)}... `}
                  {post.content.length > 120 && (
                    <span
                      className="text-blue-600 pl-2 cursor-pointer"
                      onClick={() => toggleFullText(post.id)}
                    >
                      {showFullText.includes(post.id)
                        ? "Sembunyikan"
                        : "Selengkapnya"}
                    </span>
                  )}
                </p>
              </div>
              <div className="flex lg:justify-end mt-[10px]">
                <div className="grid grid-cols-2 w-full gap-5 lg:w-auto">
                  <div
                    onClick={handleShowDetail}
                    className="flex gap-1 border border-slate-300 shadow-md py-2 lg:w-[120px] w-full justify-center rounded-full"
                  >
                    {IconChat("20", "20", "gray")}{" "}
                    <p className="mt-[-2px]">{post.comment}</p>
                  </div>
                  <div
                    className="flex gap-1 border border-slate-300 shadow-md py-2 lg:w-[120px] w-full justify-center rounded-full"
                    onClick={() => handleLikeClick(post.id)}
                  >
                    {IconLike(
                      "20",
                      "20",
                      likedPosts.includes(post.id) ? "blue" : "gray"
                    )}
                    <p className="mt-[-2px]">{post.likes}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Tampilkan iklan setelah setiap 5 postingan */}
          {(index + 1) % 2 === 0 && <GoogleAdsense />}
        </React.Fragment>
      ))}

      {showDetail && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-40">
          <DetailPost onClose={handleCloseDetail} />
        </div>
      )}
    </section>
  );
};

export default Post;
