import React, { useState, useRef } from "react";
import { IconCam, IconEmoji, IconLike, IconSend } from "../../../Assets/Icons";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
interface Comment {
  id: number;
  content: string;
  userName: string;
  timeAgo: string;
  replies: Comment[];
}

const CommentPost: React.FC = () => {
  const [replyingTo, setReplyingTo] = useState<number | null>(null);
  const [likedComments, setLikedComments] = useState<number[]>([]);
  const [newComment, setNewComment] = useState("");
  const [newReply, setNewReply] = useState("");

  // Refs for auto-resizing textarea
  const commentTextareaRef = useRef<HTMLTextAreaElement>(null);
  const replyTextareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref for file input

  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false); // State to show/hide emoji picker

  const staticComments: Comment[] = [
    {
      id: 1,
      content: "Great post! Really enjoyed reading it.",
      userName: "JohnDoe",
      timeAgo: "2 hours ago",
      replies: [
        {
          id: 2,
          content: "Thanks, John! Glad you liked it.",
          userName: "PostAuthor",
          timeAgo: "1 hour ago",
          replies: [],
        },
      ],
    },
    {
      id: 3,
      content: "I found this very helpful, thanks for sharing!",
      userName: "JaneSmith",
      timeAgo: "3 hours ago",
      replies: [],
    },
  ];

  const handleReplyClick = (id: number) => {
    setReplyingTo(replyingTo === id ? null : id);
  };

  const handleLikeClick = (id: number) => {
    if (likedComments.includes(id)) {
      setLikedComments(likedComments.filter((commentId) => commentId !== id));
    } else {
      setLikedComments([...likedComments, id]);
    }
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(e.target.value);
    autoResizeTextarea(commentTextareaRef);
  };

  const handleReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewReply(e.target.value);
    autoResizeTextarea(replyTextareaRef);
  };

  const handlePostComment = (e: React.FormEvent) => {
    e.preventDefault();
    setNewComment("");
    autoResizeTextarea(commentTextareaRef); // Reset the height after clearing
  };

  const handlePostReply = (e: React.FormEvent, commentId: number) => {
    e.preventDefault();
    setNewReply("");
    setReplyingTo(null);
    autoResizeTextarea(replyTextareaRef); // Reset the height after clearing
  };

  const handleIconCamClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click to open file picker
    }
  };

  const autoResizeTextarea = (
    textareaRef: React.RefObject<HTMLTextAreaElement>
  ) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px"; // Set height to scrollHeight
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    if (replyingTo !== null) {
      setNewReply((prev) => prev + emojiObject.emoji);
    } else {
      setNewComment((prev) => prev + emojiObject.emoji);
    }
    setShowEmojiPicker(false);
  };
  return (
    <div className="rounded-lg w-full h-full relative">
      <div className="overflow-y-auto h-full lg:p-4 pt-2 lg:pb-[100px] pb-[30px]">
        {staticComments.length > 0 ? (
          <ul>
            {staticComments.map((comment) => (
              <li key={comment.id} className="mb-4 border-b pb-2">
                <div className="flex gap-2 items-center">
                  <div>
                    <div className="lg:w-[50px] lg:h-[50px] w-[40px] h-[40px]">
                      <img
                        src="https://picsum.photos/200/300?random=8"
                        alt=""
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between w-full">
                    <div>
                      <p className="font-semibold text-[12px]">SMPN 1 Pedes</p>
                      <p className="text-[12px]">{comment.userName}</p>
                    </div>

                    <p className="lg:text-xs text-[10px] text-gray-400">
                      {comment.timeAgo}
                    </p>
                  </div>
                </div>
                <p className="lg:text-sm text-[12px] text-gray-600 lg:pl-[60px]">
                  {comment.content}
                </p>
                <div className="flex lg:justify-end mt-[10px]">
                  <div className="grid grid-cols-2 w-full gap-5 lg:w-auto">
                    <button
                      className="text-gray-500 flex gap-2"
                      onClick={() => handleReplyClick(comment.id)}
                    >
                      <p>Balas</p> <p>{comment.replies.length}</p>
                    </button>
                    <button
                      className="text-gray-500 ml-2 flex gap-2"
                      onClick={() => handleLikeClick(comment.id)}
                    >
                      {IconLike(
                        "20",
                        "20",
                        likedComments.includes(comment.id) ? "blue" : "gray"
                      )}
                      <p>
                        {likedComments.includes(comment.id) ? "Liked" : "Like"}
                      </p>
                    </button>
                  </div>
                </div>

                {replyingTo === comment.id && comment.replies.length > 0 && (
                  <ul className="lg:pl-8 pl-4 mt-4">
                    {comment.replies.map((reply) => (
                      <li key={reply.id} className="mb-2 border-b pb-2">
                        <div className="flex gap-2 items-center">
                          <div>
                            <div className="lg:w-[50px] lg:h-[50px] w-[40px] h-[40px] object-contain">
                              <img
                                src="https://picsum.photos/200/300?random=8"
                                alt=""
                                className="w-full h-full object-cover rounded-full"
                              />
                            </div>
                          </div>
                          <div className="flex justify-between w-full">
                            <div>
                              <p className="text-[12px] font-semibold">
                                SMPN 1 Pedes
                              </p>
                              <p className="text-[12px]">{reply.userName}</p>
                            </div>
                            <p className="text-xs text-gray-400 ">
                              {reply.timeAgo}
                            </p>
                          </div>
                        </div>
                        <p className="lg:text-sm text-[12px] text-gray-600 lg:pl-[60px]">
                          {reply.content}
                        </p>
                      </li>
                    ))}
                  </ul>
                )}

                {replyingTo === comment.id && (
                  <form
                    className="flex lg:gap-4 gap-2 mt-4 lg:pl-[37px] pl-[20px]"
                    onSubmit={(e) => handlePostReply(e, comment.id)}
                  >
                    <div>
                      <div className="lg:w-[50px] lg:h-[50px] w-[40px] h-[40px]">
                        <img
                          src="https://picsum.photos/200/300?random=8"
                          alt=""
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="flex gap-4">
                        <textarea
                          ref={replyTextareaRef}
                          value={newReply}
                          onChange={handleReplyChange}
                          className="w-full text-[12px] h-[20px] px-3 py-2 rounded-md outline-none border border-slate-100 resize-none overflow-hidden"
                          placeholder="Balas Komentar"
                          style={{ minHeight: "40px" }} // Set a minimum height like input
                        />
                        <button className="text-white lg:px-3  rounded-md">
                          {IconSend("24", "24", "gray")}
                        </button>
                      </div>
                      <div className="mt-1 flex gap-2">
                        <button> {IconCam("30", "30", "gray")}</button>
                        <button> {IconEmoji("20", "20", "gray")}</button>
                      </div>
                    </div>
                  </form>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No comments available</p>
        )}
      </div>

      {/* {showEmojiPicker && (
        <div className="absolute ">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )} */}
      <div className="fixed bottom-0 left-0 w-full lg:p-4">
        <form
          className="flex gap-4 mx-auto w-[700px] bg-white rounded-lg shadow border-t border-slate-300 p-4"
          onSubmit={handlePostComment}
        >
          <div className="w-[50px] h-[50px]">
            <img
              src="https://picsum.photos/200/300?random=8"
              alt="User avatar"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          <div>
            <div className="flex gap-4">
              <textarea
                ref={commentTextareaRef}
                value={newComment}
                onChange={handleCommentChange}
                className="w-[500px] h-[40px] px-3 text-[12px] py-2 rounded-md outline-none border border-slate-100 resize-none overflow-hidden"
                placeholder="Buat Komentar..."
              />
              <button
                type="submit"
                aria-label="Send comment"
                className="mt-auto border border-slate-30 rounded-lg p-1"
              >
                {IconSend("24", "24", "gray")}
              </button>
            </div>
            <div className="mt-1 flex gap-2">
              <button
                type="button"
                aria-label="Attach photo"
                className="border border-slate-30 rounded-lg p-[2px]"
                onClick={handleIconCamClick}
              >
                {IconCam("30", "30", "gray")}
              </button>
              <button type="button" onClick={toggleEmojiPicker}>
                {IconEmoji("20", "20", "gray")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentPost;
