import React, { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import teacherRoutes from "./teacherRoutes";
import studentRoutes from "./studentRoutes";
import Loading from "../Components/loading";
import LoadingUjian from "../Components/loading/LoadingUjian";
import StudentGuardianRoute from "./StudentGuardianRoute";
import SchoolRoute from "./SchoolRoute";

// Use lazy loading for components
const NotFoundPage = lazy(() => import("../Components/notFound"));
const ErrorPage = lazy(() => import("../Components/pageError"));
const Dashboard = lazy(() => import("./Dashboard"));
const HomePage = lazy(() => import("../Pages/HomePage"));
const ProfilePage = lazy(() => import("../Pages/ProfilePage"));
const MessagesPage = lazy(() => import("../Pages/MessagesPage"));
const WorkPage = lazy(() => import("../Pages/WorkPage"));
const DetailWork = lazy(() => import("../Pages/WorkPage/DetailWork"));
const NotificationPage = lazy(() => import("../Pages/NotificationPage"));
const SearchPage = lazy(() => import("../Pages/SearchPage"));
const InternshipForm = lazy(() => import("../Pages/WorkPage/InternshipForm"));
const FormLogin = lazy(() => import("../Pages/formLogin"));
const DetailEvent = lazy(
  () => import("../Pages/SearchPage/eventResult/detailEvent")
);
const FormRegistrasiEvent = lazy(
  () => import("../Pages/SearchPage/eventResult/FormRegisterEvent")
);
const DetailSchool = lazy(
  () => import("../Pages/SearchPage/SchoolResult/detailSchool")
);
const DetailExtrakulikuler = lazy(
  () => import("../Pages/Exrakulikuler/detailExtrakulikuler")
);
const DetailProgramSchool = lazy(
  () => import("../Pages/programSchool/detailProgramSchool")
);
const DetailCompany = lazy(
  () => import("../Pages/SearchPage/companyResult/detailCompany")
);
const Messages = lazy(() => import("../Pages/MessagesPage/Messages"));

const handleClose = () => {
  console.log("Pesan ditutup");
};

export const Routers = createBrowserRouter([
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loading />}>
        <FormLogin />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<Loading />}>
        <ErrorPage />
      </Suspense>
    ),
  },

  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <Dashboard />
      </Suspense>
    ),
    errorElement: (
      <Suspense fallback={<Loading />}>
        <NotFoundPage />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading />}>
            <HomePage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/homepage",
        element: (
          <Suspense fallback={<Loading />}>
            <HomePage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/profile",
        element: (
          <Suspense fallback={<Loading />}>
            <ProfilePage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/messages",
        element: (
          <Suspense fallback={<Loading />}>
            <MessagesPage onClose={handleClose} />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/pesan",
        element: (
          <Suspense fallback={<Loading />}>
            <Messages />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/detail-event",
        element: (
          <Suspense fallback={<Loading />}>
            <DetailEvent />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/register-event",
        element: (
          <Suspense fallback={<Loading />}>
            <FormRegistrasiEvent />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/detail-sekolah",
        element: (
          <Suspense fallback={<Loading />}>
            <DetailSchool />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/detail-perusahaan",
        element: (
          <Suspense fallback={<Loading />}>
            <DetailCompany />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/detail-program-sekolah",
        element: (
          <Suspense fallback={<Loading />}>
            <DetailProgramSchool />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/detail-ekstrakulikuler",
        element: (
          <Suspense fallback={<Loading />}>
            <DetailExtrakulikuler />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/internship",
        element: (
          <Suspense fallback={<Loading />}>
            <WorkPage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/loading",
        element: (
          <Suspense fallback={<Loading />}>
            <LoadingUjian />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/internship/detail",
        element: (
          <Suspense fallback={<Loading />}>
            <DetailWork />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/internship/form",
        element: (
          <Suspense fallback={<Loading />}>
            <InternshipForm />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/notification",
        element: (
          <Suspense fallback={<Loading />}>
            <NotificationPage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },
      {
        path: "/search",
        element: (
          <Suspense fallback={<Loading />}>
            <SearchPage />
          </Suspense>
        ),
        errorElement: (
          <Suspense fallback={<Loading />}>
            <ErrorPage />
          </Suspense>
        ),
      },

      // rute guru
      ...teacherRoutes,
      //  rute siswa
      ...studentRoutes,
      // route wali kelas
      ...StudentGuardianRoute,
      // Route Sekolah
      ...SchoolRoute,
    ],
  },
]);
