import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconDelete, IconEdit, IconEye } from "../../../../Assets/Icons";
import EditDataCalendar from "./editDataCalender";
import DetailData from "./detailData";

const events = [
  {
    date_start: "2024-10-20",
    date_end: "2024-10-22",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-28",
    date_end: "2024-11-04",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
  {
    date_start: "2024-10-01",
    date_end: "",
    title: "Kuliah Praktikum",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vero dolorem vel repellat corrupti incidunt reiciendis necessitatibus ducimus. Ex, nobis!",
  },
];

const DetailDataCalendar: React.FC = () => {
  const [showDetail, setShowDetail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const toggleDetail = () => {
    setShowDetail(true);
  };

  const toggleEdit = () => {
    setShowEdit(true);
  };

  const toggleCloseEdit = () => {
    setShowEdit(false);
  };

  const toggleCloseDetail = () => {
    setShowDetail(false);
  };

  return (
    <div className="p-6 bg-gray-50 font-inter">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-indigo-700">Data Event</h2>
        <Link to={"/sekolah/akademik/kalender/tambah"}>
          <button className="bg-indigo-600 text-white px-4 py-2 rounded-lg shadow hover:bg-indigo-700 transition">
            Tambah
          </button>
        </Link>
      </div>
      <div className="overflow-x-auto ">
        <div className="w-[700px] lg:w-full bg-white rounded-lg shadow-lg">
          <div className="bg-indigo-100 flex p-4 items-center text-gray-800 uppercase text-sm border-b border-indigo-300">
            <p className="text-center lg:w-[50px] w-[120px] font-semibold">
              No
            </p>
            <p className="w-full lg:w-1/4 font-semibold">Tanggal</p>
            <p className=" w-full lg:w-1/4 font-semibold">Judul</p>
            <p className="hidden md:block w-1/2 font-semibold">Deskripsi</p>
            <p className="px-4 py-2 w-[200px] lg:w-[110px]">Aksi</p>
          </div>
          <div>
            {events.map((event, index) => (
              <div
                key={index}
                className="bg-white hover:shadow-md hover:border-indigo-400 transition duration-200 border-b border-gray-300 flex items-center p-4"
              >
                <div className="lg:w-[50px] w-[120px] text-sm text-gray-700 text-center">
                  {index + 1}
                </div>
                <div className="w-full md:w-1/4 text-sm text-gray-600">
                  {event.date_start} s/d {event.date_end || "-"}
                </div>
                <div className="w-full md:w-1/4  text-sm font-medium text-gray-800">
                  {event.title}
                </div>
                <div className="hidden md:block w-full md:w-1/2 text-left text-sm text-gray-600">
                  {event.desc}
                </div>
                <div className="px-4 py-2 flex gap-2 border-b w-[110px] md:w-auto justify-center">
                  <button onClick={toggleDetail}>
                    <IconEye />
                  </button>
                  <button onClick={toggleEdit}>
                    {IconEdit("24", "24", "blue")}
                  </button>
                  <button>{IconDelete("24", "24", "red")}</button>
                </div>
              </div>
            ))}
          </div>
          {showEdit && (
            <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
              <EditDataCalendar onClose={toggleCloseEdit} />
            </div>
          )}
          {showDetail && (
            <div className="fixed inset-0 flex items-center z-40 justify-center bg-black bg-opacity-50">
              <DetailData onClose={toggleCloseDetail} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailDataCalendar;
