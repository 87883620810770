import { Suspense, lazy } from "react"; // Pastikan lazy digunakan
import LoadingUjian from "../Components/loading/LoadingUjian";

// Lazy load untuk ProfilePage dan ErrorPage
const ErrorPage = lazy(() => import("../Components/pageError"));
const ProfilePage = lazy(() => import("../Pages/ProfilePage"));

const StudentGuardianRoute = [
  {
    path: "/wali-murid",
    errorElement: (
      <Suspense fallback={<LoadingUjian />}>
        <ErrorPage />
      </Suspense>
    ),
    children: [
      {
        path: "profil",
        element: (
          <Suspense fallback={<LoadingUjian />}>
            <ProfilePage />
          </Suspense>
        ),
      },
    ],
  },
];

export default StudentGuardianRoute;
