import React, { useState } from "react";
import AddDailyValue from "../addDailyValue";
import { IconExport } from "../../../../Assets/Icons";
import ExportData from "../../../../Components/exportData";

// Interface for value data
interface ValueEntry {
  id: number;
  week: number;
  value: number;
}

// Interface for student data
interface DailyValue {
  id: number;
  studentName: string;
  value: ValueEntry[];
}

// Static data for demonstration
const initialValues: DailyValue[] = [
  {
    id: 1,
    studentName: "Alice Johnson",
    value: [
      { id: 1, week: 1, value: 85 },
      { id: 2, week: 2, value: 90 },
      { id: 3, week: 3, value: 88 },
      { id: 4, week: 4, value: 92 },
    ],
  },
  {
    id: 2,
    studentName: "Bob Smith",
    value: [
      { id: 1, week: 1, value: 78 },
      { id: 2, week: 2, value: 83 },
      { id: 3, week: 3, value: 80 },
      { id: 4, week: 4, value: 85 },
    ],
  },
  {
    id: 3,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 4,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 5,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 6,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 7,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 8,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 9,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 10,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
  {
    id: 11,
    studentName: "Charlie Brown Alexander",
    value: [
      { id: 1, week: 1, value: 92 },
      { id: 2, week: 2, value: 88 },
      { id: 3, week: 3, value: 91 },
      { id: 4, week: 4, value: 95 },
    ],
  },
];

const calculateAverages = (values: DailyValue[]) => {
  const weekSums: { [key: number]: number } = {};
  const weekCounts: { [key: number]: number } = {};
  let totalMonthlySum = 0;
  let totalStudentCount = 0;

  // Calculate week-wise and student-wise totals
  const studentAverages: { [key: number]: number } = {};

  values.forEach((student) => {
    let studentMonthlySum = 0;
    student.value.forEach((entry) => {
      const week = entry.week;
      const value = entry.value;
      weekSums[week] = (weekSums[week] || 0) + value;
      weekCounts[week] = (weekCounts[week] || 0) + 1;
      studentMonthlySum += value;
    });
    studentAverages[student.id] = studentMonthlySum / student.value.length;
    totalMonthlySum += studentMonthlySum;
    totalStudentCount++;
  });

  const weekAverages: { [key: number]: number } = {};
  Object.keys(weekSums).forEach((week) => {
    weekAverages[Number(week)] =
      weekSums[Number(week)] / (weekCounts[Number(week)] || 1);
  });

  const monthlyAverage =
    totalStudentCount > 0 ? totalMonthlySum / (totalStudentCount * 4) : 0; // 4 weeks per student

  return { weekAverages, monthlyAverage, studentAverages };
};

const DailyValue: React.FC = () => {
  const { weekAverages, monthlyAverage, studentAverages } =
    calculateAverages(initialValues);
  const [showAdd, setShowAdd] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const handleOpenAdd = () => {
    setShowAdd(true);
  };

  const handleDropdownToggle = (dropdownName: string) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  return (
    <div className="p-4  font-inter bg-gray-50 min-h-screen">
      <div className="mb-4 text-gray-800">
        <h1 className="text-2xl mb-4 font-roboto font-bold">
          Penilaian Harian
        </h1>
        <div className="flex">
          <div className="flex-shrink-0 pr-6">
            <p className="font-medium">Jurusan</p>
            <p className="font-medium">Kelas</p>
            <p className="font-medium">Guru Mapel</p>
          </div>
          <div>
            <p className="ml-2">: MIPA</p>
            <p className="ml-2">: 10.Mipa 6</p>
            <p className="ml-2">
              : <span className="font-semibold">H. Nasar</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="relative">
          <div className="flex justify-end mb-2">
            <div
              onClick={() => handleDropdownToggle("export")}
              className="flex border border-slate-100 shadow-sm rounded-lg gap-[10px] px-[15px] py-[5px] cursor-pointer bg-gradient-to-r from-[#EF2725] to-[#F26023] "
            >
              <div className="py-[3px]">{IconExport("16", "16", "white")}</div>
              <p className="text-[14px] text-white font-inter font-semibold ">
                Export
              </p>
            </div>
            {openDropdown === "export" && (
              <div className="absolute right-[14px] mt-[53px]">
                <ExportData onClose={() => setOpenDropdown(null)} />
              </div>
            )}
          </div>

          <div className="mb-6 overflow-x-auto ">
            <div className="w-[700px] bg-white border border-gray-300 rounded-lg shadow-md">
              <div className="bg-gray-200 flex text-center p-3 border-b text-gray-600 pr-[20px]">
                <div className="w-[50px]">ID</div>
                <div className="w-[300px] text-left">Nama Lengkap</div>
                <div className="w-[80px]">1</div>
                <div className="w-[80px]">2</div>
                <div className="w-[80px]">3</div>
                <div className="w-[80px]">4</div>
                <div className="w-[100px]">Rata-rata</div>
              </div>

              <div className="h-[400px] overflow-y-auto">
                {initialValues.map((student) => {
                  const weekValues = student.value.reduce((acc, curr) => {
                    acc[`week${curr.week}`] = curr.value;
                    return acc;
                  }, {} as { [key: string]: number });

                  return (
                    <div
                      key={student.id}
                      className="flex text-center p-3 border-b text-gray-700 hover:bg-gray-100"
                    >
                      <div className="w-[50px]">{student.id}</div>
                      <p className="w-[300px] text-left">
                        {student.studentName}
                      </p>
                      <div className="w-[80px]">{weekValues.week1 || "-"}</div>
                      <div className="w-[80px]">{weekValues.week2 || "-"}</div>
                      <div className="w-[80px]">{weekValues.week3 || "-"}</div>
                      <div className="w-[80px]">{weekValues.week4 || "-"}</div>
                      <div className="w-[100px]">
                        {studentAverages[student.id].toFixed(2)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <h2 className="text-xl font-semibold mb-4 text-gray-700">
            Rata-rata Ulangan Harian
          </h2>
          <table className="min-w-full text-center bg-white border border-gray-300 rounded-lg shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="p-3 border-b  text-gray-600">Ujian</th>
                <th className="p-3 border-b  text-gray-600">Rata-rata</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(weekAverages).map((week) => (
                <tr key={week} className="hover:bg-gray-100 text-center">
                  <td className="p-3 border-b text-gray-700">{`Ujian ${week}`}</td>
                  <td className="p-3 border-b text-gray-700">
                    {weekAverages[Number(week)].toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-200 font-bold">
                <td className="p-3 border-b text-gray-700">Rata-rata</td>
                <td className="p-3 border-b text-gray-700">
                  {monthlyAverage.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DailyValue;
