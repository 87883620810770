import React, { useState } from "react";

interface Material {
  id: number;
  className: string;
  title: string;
  description: string;
  date: string;
  fileUrl?: string; // URL file PDF (opsional)
}

const initialMaterials: Material[] = [
  {
    id: 1,
    className: "Kelas 10",
    title: "Matematika - Aljabar",
    description: "Mempelajari dasar-dasar aljabar dan penerapannya.",
    date: "2024-09-01",
  },
  {
    id: 2,
    className: "Kelas 11",
    title: "Fisika - Hukum Newton",
    description: "Penjelasan mengenai tiga hukum gerak Newton.",
    date: "2024-09-02",
  },
  // Tambahkan materi lainnya sesuai kebutuhan
];

const TeacherMaterialRoom: React.FC = () => {
  const [materials, setMaterials] = useState<Material[]>(initialMaterials);
  const [className, setClassName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);

  // Fitur untuk hapus materi
  const handleDelete = (id: number) => {
    const updatedMaterials = materials.filter((material) => material.id !== id);
    setMaterials(updatedMaterials);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!file) {
      alert("Harap unggah file PDF");
      return;
    }

    const newMaterial: Material = {
      id: materials.length + 1,
      className,
      title,
      description,
      date: new Date().toISOString().split("T")[0],
      fileUrl: URL.createObjectURL(file), // Menyimpan URL sementara untuk file PDF
    };

    setMaterials([...materials, newMaterial]);
    setClassName("");
    setTitle("");
    setDescription("");
    setFile(null);
    setShowForm(false); // Menyembunyikan form setelah submit
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center p-4 bg-gray-100">
      <div className="max-w-3xl w-full bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6 text-center">
          Materi Kelas
        </h1>

        {/* Daftar Materi */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Daftar Materi
            </h2>
            {/* Tombol Tambah Materi */}
            <button
              onClick={() => setShowForm(!showForm)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
            >
              {showForm ? "Batal" : "Tambah Materi"}
            </button>
          </div>
          {materials.length > 0 ? (
            <ul className="space-y-6">
              {materials.map((material) => (
                <li
                  key={material.id}
                  className="p-4 bg-blue-50 border border-blue-200 rounded-lg shadow-md flex flex-col sm:flex-row sm:justify-between items-start sm:items-center"
                >
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold text-blue-600">
                      {material.title}
                    </h3>
                    <p className="text-gray-700 mt-1">{material.description}</p>
                    <p className="text-sm text-gray-500 mt-1">
                      {material.date}
                    </p>
                    {material.fileUrl && (
                      <a
                        href={material.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline mt-2 block"
                      >
                        Lihat File PDF
                      </a>
                    )}
                  </div>
                  <button
                    onClick={() => handleDelete(material.id)}
                    className="mt-4 sm:mt-0 sm:ml-4 px-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition-colors duration-300"
                  >
                    Hapus
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-700">Belum ada materi yang ditambahkan.</p>
          )}
        </div>

        {/* Form Input Materi Baru */}
        {showForm && (
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Tambah Materi Baru
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Judul Materi
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Masukkan judul materi"
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Deskripsi Materi
                </label>
                <textarea
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Masukkan deskripsi materi"
                  className="w-full p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Unggah File PDF
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
              >
                Tambah Materi
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherMaterialRoom;
