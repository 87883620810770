// src/redux/authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthResponse, AuthState } from "../types/authTypes";

const initialState: AuthState = {
  user: null,
  token: null,
  error: null,
  loading: false,
};

// Async thunk for login
export const login = createAsyncThunk<
  AuthResponse,
  { username: string; password: string }
>("auth/login", async (credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      new URLSearchParams(credentials),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data; // return the data on success
  } catch (error) {
    return rejectWithValue("Login failed. Please check your credentials.");
  }
});

// Create a slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user; // Save user data
        state.token = action.payload.Token; // Save token
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string; // Set error message
      });
  },
});

// Export actions and reducer
export const { logout } = authSlice.actions;
export default authSlice.reducer;
