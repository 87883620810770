import React from "react";
import { IconDelete, IconEdit, IconEye } from "../../../../Assets/Icons";
import { Link } from "react-router-dom";

// Definisikan tipe untuk data siswa
interface StudentPerformance {
  id: number;
  nis: string;
  namaLengkap: string;
  kelas: string;
  semester1: number;
  semester2: number;
  semester3: number;
  semester4: number;
  semester5: number;
  semester6: number;
}

// Data statis siswa
const studentData: StudentPerformance[] = [
  {
    id: 1,
    nis: "12345678",
    kelas: "12 Mipa 6",
    namaLengkap: "Budi Santoso",
    semester1: 85,
    semester2: 88,
    semester3: 90,
    semester4: 92,
    semester5: 87,
    semester6: 89,
  },
  {
    id: 2,
    nis: "87654321",
    namaLengkap: "Siti Nurhaliza",
    kelas: "12 Mipa 6",
    semester1: 78,
    semester2: 80,
    semester3: 82,
    semester4: 85,
    semester5: 88,
    semester6: 90,
  },
  {
    id: 3,
    nis: "11223344",
    namaLengkap: "Agus Pratama",
    kelas: "12 Mipa 6",
    semester1: 92,
    semester2: 94,
    semester3: 90,
    semester4: 89,
    semester5: 93,
    semester6: 95,
  },
];

// Komponen untuk menampilkan kinerja siswa dengan Tailwind CSS
const KinerjaStudent: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-[20px] lg:text-3xl font-bold  text-gray-800 mb-8">
        Kinerja Siswa
      </h1>
      <div className="overflow-x-auto">
        <div className="lg:w-full w-[900px] ">
          {/* Header */}
          <div className="flex items-center bg-gray-200 font-bold p-2">
            <p className="w-10">Id</p>
            <p className="w-24">NIS</p>
            <p className="w-40">Nama Lengkap</p>
            <p className="w-24">Kelas</p>
            <div className="flex flex-col items-center">
              <span>Semester</span>
              <div className="flex text-center">
                <p className="w-20">1</p>
                <p className="w-20">2</p>
                <p className="w-20">3</p>
                <p className="w-20">4</p>
                <p className="w-20">5</p>
                <p className="w-20">6</p>
              </div>
            </div>
            <p className="w-24 text-center">Aksi</p>
          </div>

          {/* Data siswa */}
          <div>
            {studentData.map((item, index) => (
              <div
                key={index}
                className={`text-center flex p-2 ${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                }`}
              >
                <p className="w-10">{item.id}</p>
                <p className="w-24 text-left">{item.nis}</p>
                <p className="w-40 text-left">{item.namaLengkap}</p>
                <p className="w-24 text-left">{item.kelas}</p>
                <p className="w-20">{item.semester1}</p>
                <p className="w-20">{item.semester2}</p>
                <p className="w-20">{item.semester3}</p>
                <p className="w-20">{item.semester4}</p>
                <p className="w-20">{item.semester5}</p>
                <p className="w-20">{item.semester6}</p>
                <div className="w-24 flex items-center gap-2 px-2 ">
                  <button>{IconEdit("24", "24", "blue")}</button>
                  <button>{IconDelete("24", "24", "red")}</button>{" "}
                  <Link to={"detail"}>
                    <button>
                      <IconEye />
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KinerjaStudent;
