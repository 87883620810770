import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartOptions,
  ChartData,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const GrafikSiswa: React.FC = () => {
  // Data nilai rata-rata per kelas
  const kelas: string[] = [
    "10 MIPA 1",
    "10 MIPA 2",
    "10 MIPA 3",
    "10 MIPA 4",
    "10 MIPA 5",
    "10 MIPA 6",
    "11 MIPA 1",
    "11 MIPA 2",
    "11 MIPA 3",
    "11 MIPA 4",
    "11 MIPA 5",
    "11 MIPA 6",
    "12 MIPA 1",
    "12 MIPA 2",
    "12 MIPA 3",
    "12 MIPA 4",
    "12 MIPA 5",
    "12 MIPA 6",
    "10 IPS 1",
    "10 IPS 2",
    "10 IPS 3",
    "10 IPS 4",
    "10 IPS 5",
    "10 IPS 6",
    "11 IPS 1",
    "11 IPS 2",
    "11 IPS 3",
    "11 IPS 4",
    "11 IPS 5",
    "11 IPS 6",
    "12 IPS 1",
    "12 IPS 2",
    "12 IPS 3",
    "12 IPS 4",
    "12 IPS 5",
    "12 IPS 6",
  ];

  const nilaiRataRata: number[] = [
    50,
    65,
    78,
    85,
    82,
    90, // 10 MIPA
    88,
    91,
    86,
    84,
    87,
    92, // 11 MIPA
    90,
    92,
    95,
    94,
    88,
    91, // 12 MIPA
    76,
    79,
    75,
    81,
    77,
    80, // 10 IPS
    82,
    85,
    88,
    86,
    84,
    89, // 11 IPS
    93,
    95,
    47,
    90,
    92,
    94, // 12 IPS
  ];

  // Fungsi untuk menentukan warna berdasarkan nilai rata-rata
  const getBarColor = (nilai: number) => {
    if (nilai <= 60) {
      return "rgba(255, 99, 132, 0.8)"; // Merah untuk nilai <= 60
    } else if (nilai <= 70) {
      return "rgba(255, 205, 86, 0.8)"; // Kuning untuk nilai <= 70
    } else {
      return "rgba(75, 192, 192, 0.8)"; // Hijau untuk nilai > 70
    }
  };

  const data: ChartData<"bar"> = {
    labels: kelas,
    datasets: [
      {
        label: "Nilai Rata-Rata",
        data: nilaiRataRata,
        backgroundColor: nilaiRataRata.map((nilai) => getBarColor(nilai)), // Warna dinamis
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          font: {
            size: 14,
          },
        },
      },
      title: {
        display: true,
        text: "Analisis Nilai Rata-Rata per Kelas",
        font: {
          size: 20,
          weight: "bold",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Nilai",
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <div className="overflow-y-auto">
      <div className="bg-white  shadow-lg w-[700px] px-2 h-[350px] rounded-lg">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default GrafikSiswa;
