import React, { useState } from "react";

interface StudentAssignment {
  id: number;
  nis: string;
  fullName: string;
  url: string | null;
  score: number | null;
  completed: boolean;
}

const initialAssignments: StudentAssignment[] = [
  {
    id: 1,
    nis: "123456",
    fullName: "John Doe",
    url: "https://example.com/assignment1",
    score: 85,
    completed: true,
  },
  {
    id: 2,
    nis: "789012",
    fullName: "Jane Smith",
    url: "https://example.com/assignment1",
    score: null,
    completed: true,
  },
  {
    id: 3,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 4,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 5,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 6,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 7,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 8,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 9,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 10,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 11,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 12,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
  {
    id: 13,
    nis: "789012",
    fullName: "Jane Smith",
    url: null,
    score: null,
    completed: false,
  },
];

const TeacherWorkRoom: React.FC = () => {
  const [assignments, setAssignments] =
    useState<StudentAssignment[]>(initialAssignments);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [newScore, setNewScore] = useState<number | "">("");

  const handleEdit = (id: number, currentScore: number | null) => {
    setEditingId(id);
    setNewScore(currentScore ?? ""); // Handle null score
  };

  const handleUpdateScore = (id: number) => {
    if (newScore !== "" && !isNaN(newScore)) {
      const updatedAssignments = assignments.map((assignment) =>
        assignment.id === id ? { ...assignment, score: newScore } : assignment
      );
      setAssignments(updatedAssignments);
      setEditingId(null);
      setNewScore("");
    } else {
      alert("Please enter a valid score.");
    }
  };

  return (
    <div className="min-h-screen absolute flex flex-col items-center p-6 ">
      <div className="max-w-6xl w-full bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 ">
          Daftar Tugas Siswa
        </h1>

        <div className="">
          <table className="w-full min-w-max bg-white border border-gray-200 rounded-lg shadow-md">
            <thead className="bg-gray-100 border-b">
              <tr>
                <th className="p-4 text-left text-gray-600">No</th>
                <th className="p-4 text-left text-gray-600">NIS</th>
                <th className="p-4 text-left text-gray-600">Nama Lengkap</th>
                <th className="p-4 text-left text-gray-600">Tugas</th>
                <th className="p-4 text-left text-gray-600">Status</th>
                <th className="p-4 text-left text-gray-600">Nilai</th>
                <th className="p-4 text-left text-gray-600">Aksi</th>
              </tr>
            </thead>
            <tbody className="">
              {assignments.length > 0 ? (
                assignments.map((assignment, index) => (
                  <tr
                    key={assignment.id}
                    className={`border-b text-center overflow-y-auto h-[200px]${
                      assignment.completed
                        ? assignment.url
                          ? "bg-green-50"
                          : "bg-yellow-50"
                        : "bg-red-50"
                    }`}
                  >
                    <td className="p-4 text-gray-700">{index + 1}</td>
                    <td className="p-4 text-gray-700">{assignment.nis}</td>
                    <td className="p-4 text-gray-700">{assignment.fullName}</td>
                    <td className="p-4 text-gray-700">
                      {assignment.url ? (
                        <a
                          href={assignment.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          Lihat Tugas
                        </a>
                      ) : (
                        <span className="text-gray-500">Belum Dikirim</span>
                      )}
                    </td>
                    <td
                      className={`p-4 ${
                        assignment.completed ? "text-green-700" : "text-red-700"
                      }`}
                    >
                      {assignment.completed
                        ? assignment.url
                          ? "Selesai"
                          : "Belum Dikirim"
                        : "Belum Selesai"}
                    </td>
                    <td className="p-4">
                      {editingId === assignment.id ? (
                        <div className="flex items-center space-x-2">
                          <input
                            type="number"
                            value={newScore}
                            onChange={(e) =>
                              setNewScore(Number(e.target.value))
                            }
                            className="p-2 border border-gray-300 w-[100px] rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            min="0"
                          />
                        </div>
                      ) : (
                        <span className="font-semibold">
                          {assignment.score !== null
                            ? assignment.score
                            : "Belum Dinilai"}
                        </span>
                      )}
                    </td>
                    <td className="p-4">
                      {assignment.completed && (
                        <button
                          onClick={() =>
                            editingId === assignment.id
                              ? handleUpdateScore(assignment.id)
                              : handleEdit(assignment.id, assignment.score)
                          }
                          className={`px-3 py-1 ${
                            editingId === assignment.id
                              ? "bg-blue-600 hover:bg-blue-700"
                              : "bg-yellow-500 hover:bg-yellow-600"
                          } text-white rounded-lg shadow-md focus:outline-none focus:ring-2 ${
                            editingId === assignment.id
                              ? "focus:ring-blue-500"
                              : "focus:ring-yellow-500"
                          } transition-colors duration-300`}
                        >
                          {editingId === assignment.id
                            ? "Simpan"
                            : "Edit Nilai"}
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="p-4 text-gray-700 text-center">
                    Belum ada tugas yang dikerjakan.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TeacherWorkRoom;
