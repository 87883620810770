import React from "react";

const GoogleAdsense: React.FC = () => {
  return (
    <div className="px-4 py-6 bg-gray-100 text-center border border-gray-300 rounded-lg shadow-md">
      <p className="text-gray-700 text-lg font-semibold mb-2">Iklan</p>
      <div className="bg-gray-200 p-4 rounded-lg">
        <p className="text-gray-600 text-sm">
          Ini adalah tempat untuk iklan Anda. Konten iklan bisa ditempatkan di
          sini.
        </p>
      </div>
    </div>
  );
};

export default GoogleAdsense;
