import React, { useState } from "react";
import {
  IconAdd,
  IconDelete,
  IconEdit,
  IconExport,
  IconEye,
} from "../../../Assets/Icons";
import { IconSearch2 } from "../../ListBooks/iconBook";
import ExportData from "../../../Components/exportData";
import { Link } from "react-router-dom";
import TruncateText from "../../../Components/truncateText";

interface Student {
  id: number;
  nis: string;
  fullName: string;
  class: string;
  teacher: string;
  parent: string;
  address: string;
}

const students: Student[] = [
  {
    id: 1,
    nis: "8888888888888888",
    fullName: "Ahmad Santoso",
    class: "085267636363",
    teacher: "Seni Budaya",
    parent: "12 Mipa 8",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "8888888888888888",
    fullName: "Siti Nurhaliza",
    class: "085267636363",
    teacher: "Matematika",
    parent: "10 Mipa 6",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "8888888888888888",
    fullName: "Ahmad Santoso",
    class: "085267636363",
    teacher: "Seni Budaya",
    parent: "12 Mipa 8",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "8888888888888888",
    fullName: "Siti Nurhaliza",
    class: "085267636363",
    teacher: "Matematika",
    parent: "10 Mipa 6",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "8888888888888888",
    fullName: "Ahmad Santoso",
    class: "085267636363",
    teacher: "Seni Budaya",
    parent: "12 Mipa 8",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "8888888888888888",
    fullName: "Siti Nurhaliza",
    class: "085267636363",
    teacher: "Matematika",
    parent: "10 Mipa 6",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "8888888888888888",
    fullName: "Ahmad Santoso",
    class: "085267636363",
    teacher: "Seni Budaya",
    parent: "12 Mipa 8",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "8888888888888888",
    fullName: "Siti Nurhaliza",
    class: "085267636363",
    teacher: "Matematika",
    parent: "10 Mipa 6",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "8888888888888888",
    fullName: "Ahmad Santoso",
    class: "085267636363",
    teacher: "Seni Budaya",
    parent: "12 Mipa 8",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "8888888888888888",
    fullName: "Siti Nurhaliza",
    class: "085267636363",
    teacher: "Matematika",
    parent: "10 Mipa 6",
    address: "Karawang",
  },
  {
    id: 1,
    nis: "8888888888888888",
    fullName: "Ahmad Santoso",
    class: "085267636363",
    teacher: "Seni Budaya",
    parent: "12 Mipa 8",
    address: "Karawang Barat",
  },
  {
    id: 2,
    nis: "8888888888888888",
    fullName: "Siti Nurhaliza",
    class: "085267636363",
    teacher: "Matematika",
    parent: "10 Mipa 6",
    address: "Karawang",
  },

  // Tambahkan data siswa lainnya di sini
];

const DataWAliMurid: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false);
  const [showExport, setShowExport] = useState(false);

  const handlShowExport = () => {
    setShowExport(true);
  };
  const handleExport = () => {
    setShowExport(false);
  };

  // Fungsi untuk menangani perubahan pada input pencarian
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // Fungsi untuk menangani klik tombol pencarian
  const handleSearchClick = () => {
    setIsSearchClicked(true);
  };

  // Jika tombol search sudah diklik, data siswa akan difilter
  const filteredStudents = isSearchClicked
    ? students.filter((student) => {
        const lowerQuery = searchQuery.toLowerCase();
        return (
          student.nis.toLowerCase().includes(lowerQuery) ||
          student.fullName.toLowerCase().includes(lowerQuery) ||
          student.class.toLowerCase().includes(lowerQuery) ||
          student.teacher.toLowerCase().includes(lowerQuery) ||
          student.parent.toLowerCase().includes(lowerQuery) ||
          student.address.toLowerCase().includes(lowerQuery)
        );
      })
    : students;

  return (
    <div className="container mx-auto p-4 relative">
      <div className="flex lg:flex-row flex-col justify-between">
        <h1 className="text-2xl font-bold mb-6 ">Daftar Wali Murid</h1>
        <div className="flex gap-2 items-center">
          <input
            type="text"
            className="border border-slate-300 rounded-full py-2 px-4 outline-none w-[300px]"
            placeholder="Cari data guru..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <button onClick={handleSearchClick}>
            {IconSearch2("30", "30", "gray")}
          </button>
          <Link to={"/sekolah/demografi/tambah-wali-murid"}>
            <button className="bg-blue-500 flex gap-2 lg:ml-[30px] rounded-lg font-roboto text-white lg:py-2 lg:px-4 p-1 lg:p-0">
              {IconAdd("24", "24", "white")}
              <p className="hidden lg:block">Tambah Wali Murid</p>
            </button>
          </Link>
          <button
            onClick={handlShowExport}
            className="bg-red-500 flex gap-2 rounded-lg font-roboto text-white lg:py-2 lg:px-4 p-1 lg:p-0"
          >
            {IconExport("24", "24", "white")}
            <p className="hidden lg:block">Export</p>
          </button>
        </div>
      </div>
      <div className="absolute right-4">
        {showExport && <ExportData onClose={handleExport} />}
      </div>
      <div className="min-w-full overflow-x-auto bg-white p-4">
        <div className="lg:w-full w-[900px] text-center flex justify-between bg-gray-100 border-b pr-[19px]">
          <p className="px-4 py-2 w-[50px]">ID</p>
          <p className="px-4 py-2 w-[120px]">NIK</p>
          <p className="px-4 py-2 w-[160px]">Nama Lengkap</p>
          <p className="px-4 py-2 w-[110px]">No Telp</p>
          <p className="px-4 py-2 w-[160px]">Siswa</p>
          <p className="px-4 py-2 w-[160px]">Kelas</p>
          <p className="px-4 py-2 w-[110px]">Aksi</p>
        </div>

        <div className="overflow-y-auto h-[400px] lg:w-full w-[900px]">
          {filteredStudents.length > 0 ? (
            filteredStudents.map((student, index) => (
              <div
                key={index}
                className="text-center hover:bg-gray-100 flex justify-between"
              >
                <p className="px-4 py-2 border-b w-[50px]">{index + 1}</p>
                <p className="px-4 py-2 border-b w-[120px]">
                  {TruncateText(student.nis, 12)}
                </p>
                <p className="px-4 py-2 border-b w-[160px]">
                  {student.fullName}
                </p>
                <p className="px-4 py-2 border-b w-[110px]">{student.class}</p>
                <p className="px-4 py-2 border-b w-[160px]">
                  {student.teacher}
                </p>
                <p className="px-4 py-2 border-b w-[160px]">{student.parent}</p>

                <p className="px-4 py-2 flex gap-2 border-b w-[110px]">
                  <button>{IconEdit("24", "24", "blue")}</button>
                  <button>{IconDelete("24", "24", "red")}</button>{" "}
                  <button>
                    <IconEye />
                  </button>
                </p>
              </div>
            ))
          ) : (
            <p className="text-center py-4">Tidak ada data yang ditemukan.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataWAliMurid;
